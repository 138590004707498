// implements location display on google maps
//
import { poiTypes } from "./poi-types"


// label icons
// intended to display a code point from the Material Icons font
// see https://fonts.google.com/icons.
// Needs stylesheet https://fonts.googleapis.com/icon?family=Material+Icons
//
// The icon is displayed in white on a red marker background colour.
// The background colour cannot be changed afaik. Use e.g. svg icons
// to do this (see e.g. https://developers.google.com/maps/documentation/javascript/examples/marker-modern)
class LabelIcon {
    text: string;
    fontFamily: string;
    color: string;
    fontSize: string;
    constructor(label: string) {
        this.text = label
        this.fontFamily = "Material Icons"
        this.color = "#ffffff"
        this.fontSize = "18px"
    }
}

function initPoiLabelIcons() : Record<string, LabelIcon> {
    var rec : Record<string, LabelIcon> = {}
    poiTypes.map(t => {
        rec[t.key] = new LabelIcon(t.glyph)
    })
    return rec
}

// the catalog of known poi label icons
const poiLabelIcons: Record<string, LabelIcon> = initPoiLabelIcons()

const defaultLabelIcon = new LabelIcon("\ue39e")        // e39e: circle with dot

export function getMarkerLabel(poiType: string) : LabelIcon|undefined {
    const l = poiLabelIcons[poiType]
    if (l === undefined) {
        return undefined // `undefined` will fall back to the default marker of google maps
        // alternative: explicitly return defaultLabelIcon
    }
    return l
}


interface MapLocation {
    lat: number
    lng: number
}

interface MapZoom {
    center: MapLocation
    zoom: number
}

// compute center point and best-fit zoom factor
// for a list of pois
export function computeMapZoom(pois: MapLocation[]): MapZoom {
    if (pois.length <= 0) {
        return { zoom: 0, center: { lat: 0, lng: 0 } }
    }
    var minlat = 999, maxlat = -999, minlng = 999, maxlng = -999
    var avglat = 0, avglng = 0

    for (let poi of pois) {
        const lat = poi.lat
        const lng = poi.lng
        avglat += lat
        avglng += lng
        if (lng < minlng) {
            minlng = poi.lng
        }
        if (lng > maxlng) {
            maxlng = poi.lng
        }
        if (lat < minlat) {
            minlat = poi.lat
        }
        if (lat > maxlat) {
            maxlat = poi.lat
        }
    }
    // computing zoom.
    // zoom 0 is the whole world (360 x 170(?) degrees in ~ 250x250 pixels)
    let lngrat = (maxlng - minlng) / 360, latrat = (maxlat - minlat) / 170
    let lngz = Math.floor(-Math.log2(lngrat)), latz = Math.floor(-Math.log2(latrat))
    let zoom = Math.min(lngz, latz)
    let rv = { zoom: zoom, center: { lat: avglat / pois.length, lng: avglng / pois.length } }
    return rv
}


