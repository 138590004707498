import {
  FactsActions,
  GET_FACTS,
  SET_FACTS,
  UPDATE_FACTS,
} from "../ActionTypes/facts";

type initialState = {};

export const factsReducer = (
  state: initialState = {},
  action: FactsActions
) => {
  switch (action.type) {
    case GET_FACTS:
      return state;
    case SET_FACTS:
      localStorage.setItem("facts", JSON.stringify(action.payload));
      return action.payload;
    case UPDATE_FACTS: {
      const facts = {
        ...state,
        ...action.payload,
      };
      localStorage.setItem("facts", JSON.stringify(facts));
      return facts;
    }
  }
  return state;
};
