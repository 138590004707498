import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  getLoginTokenReducer,
  getRoleLoginTokenReducer,
  globalFactsReducer,
  getPackagesReducer,
  getPromotionsReducer,
  getUpdatedPackagesReducer,
  getCreateBookingReducer,
  getAIRecommendationReducer,
  getInformationDetailsReducer,
  registerPayerReducer,
  confirmBookingReducer,
  getPackagesLandingUpdateReducer,
  getBookingReducer,
} from "./reducers/index";
import { factsReducer } from "./reducers/factsReducer";

const reducers = combineReducers({
  facts: factsReducer,
  getLoginToken: getLoginTokenReducer,
  getRoleLoginToken: getRoleLoginTokenReducer,
  globalFacts: globalFactsReducer,
  getPackagesList: getPackagesReducer,
  getPackagesLandingUpdate: getPackagesLandingUpdateReducer,
  getPromotion: getPromotionsReducer,
  getUpdatedPackages: getUpdatedPackagesReducer,
  getCreateBooking: getCreateBookingReducer,
  getAIRecommendation: getAIRecommendationReducer,
  getInformationDetails: getInformationDetailsReducer,
  getRegisterPayer: registerPayerReducer,
  getConfirmBooking: confirmBookingReducer,
  getBooking: getBookingReducer,
});

// Load when redux store loads
const initialState = {};

const middlewares = [thunk];

const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middlewares)));

export type RootState = ReturnType<typeof store.getState>;
export default store;
