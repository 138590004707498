//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components holds logos which indicates details for the product like
// max age, height etc
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";

interface Props {
  idx: number;
  image: string;
  text: string;
  arrayLength: number;
}

const Iconset: React.FC<Props> = ({ image, text, idx, arrayLength }) => {
  return (
    <Wrapper className={"section-center"}>
      {" "}
      <section
        className={idx === arrayLength - 1 ? "container no_border-right-container" : "container border-right-container"}
      >
        <div className={"container"}>
          <div className="image-container">
            <img src={image} alt="iconset" />
          </div>
          <div>
            <h5>{text}</h5>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0;
  width: 25%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 15px;
    align-items: center;
  }

  .border-right-container {
    border-right: 1px solid var(--clr-secondary-gry);
  }

  .no_border-right-container {
    border-right: none;
  }

  .container-flex-center {
    align-items: center;
  }

  div {
    width: max-content;

    h5 {
      color: var(--inverse-text);
      padding: 12px 0 0 0;
    }
  }

  .image-container {
    height: 2.5rem;
    text-align: center;

    img {
      filter: var(--iconset-filter);

      height: 40px;
    }
  }
`;

export default Iconset;
