import React from "react";
import { Error } from "../components";
import { envStaticHost } from "../Utils/windowHelper";
import { RootState } from "../State/store";
import { useSelector } from "react-redux";
import styled from "styled-components";

const UnauthorizedRoute = () => {
  const { englishLanguage, facts } = useSelector((state: RootState) => ({
    englishLanguage: state.facts.language === "en",
    facts: state.facts,
  }));

  return (
    <Wrapper>
      <Error
        errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_1.png`}
        headerText={
          englishLanguage ? "We are sorry, this link has expired" : "Leider ist dieser Link nicht mehr gültig"
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  height: 80vh;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export default UnauthorizedRoute;
