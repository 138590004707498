//-----------------------------------------------------------------------------
// Copyright 2020-2024 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this component holds the landing page control, like adult, children etc, which then
// triggers the select-packages endpoint
//
// POST /v1/portal/login
// POST /v1/portal/select-packages

// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";

const OptionsControl = () => {
  const optionsStyle = {
    fontSize: "12px",
    padding: "4px 20px",
    height: "2.5rem",
    // marginLeft: "12px",
    borderRadius: "var(--radius-options-date)",
  };

  return (
    <button className="btn" style={optionsStyle}>
      OPTIONS
    </button>
  );
};

export default OptionsControl;
