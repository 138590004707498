//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is switch button, which holds different props, and being used on different
// card components, to toggle updates, terms and conditions etc
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";

interface SwitchBtnProps {
  onClick: () => void;
  toogleSwitch: boolean;
}

/**
 * A React component that renders a switch button.
 * @param  - onClick - a function that will be called when the button is clicked
 * @returns A React component that renders a button with a switch.
 */
const SwitchButton: React.FC<SwitchBtnProps> = ({ onClick, toogleSwitch = false }) => {
  return (
    <Wrapper>
      <button className={toogleSwitch ? "switch-btn switch-btn-active" : "switch-btn"} onClick={onClick}>
        <span></span>
        <span></span>
        <span className={toogleSwitch ? "switch slide" : "switch"}></span>
      </button>
    </Wrapper>
  );
};

export default SwitchButton;

const Wrapper = styled.div`
  position: relative;

  .switch-btn {
    overflow: hidden;
    position: relative;
    width: 3rem;
    height: 1.5rem;
    border-radius: var(--radius-switch);
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: var(--transition);
    background: var(--clr-secondary-gry);

    span {
      display: inline-block;
      font-size: 0.85rem;
      cursor: pointer;
      text-transform: capitalize;
      color: var(--clr-white);
    }

    .switch {
      position: absolute;
      top: 20%;
      left: 10%;
      width: 1rem;
      height: 1rem;
      transform: translate(-5%, -5%);
      background: var(--clr-white);
      border-radius: var(--radius-50);
      display: block;
      transition: var(--transition);
      border: 1px solid white;
    }

    .slide {
      left: 60%;
    }
  }
  .switch-btn-active {
    background: var(--clr-primary-1) !important;
  }
`;
