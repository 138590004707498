//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this page calls one endpoint, and also handles the agree terms and condition
// for each product. Until the terms are accepted, the end user cannot move
// forward
//
// POST /v1/portal/confirm-booking
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../State/store";
import { confirmBookingAction, updatePackagesAction, getPackagesAction } from "../State/ActionCreators/index";
import { PageTitle, SwitchButton, Button, Hero, Error, ButtonLoading } from "../components";
import { useParams } from "react-router";
import { CustomBtnWidth, DesktopWrapper, Formatted, WhiteBackground } from "../Utils/styles";
import { formatPrice, writeBackup } from "../Utils/helper";
import { MdDone } from "react-icons/md";
import { createNewResponseObjectProductGui } from "../Utils/helper";
import { Stamp } from "../components/Stamp";
import { envStaticHost } from "../Utils/windowHelper";
import { resetLocalStorage } from "../Utils/helper";

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

interface products {
  product_id: string;
  facts: any;
  cost: number;
  currency: string;
  guis: guis[];
  latest_cancel: string;
}

interface categories {
  en: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface packageProps {
  package_id?: string;
  product_groups?: product_groups[];
  categories?: categories[];
  facts?: any;
  guis?: guis[];
}

const PaymentConfirmationScreen = () => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const { packageid: packageIdParams } = useParams<{ packageid?: string }>();

  /* Using the useSelector hook to get the updated packages from the Redux store. */
  const getUpdatedPackages = useSelector((state: RootState) => {
    return state.getUpdatedPackages;
  });

  const {
    error: errorUpdatedPackages,
  }: {
    loading: boolean;
    error: boolean;
  } = getUpdatedPackages;

  /* Parsing the JSON string stored in localStorage.getItem("packages") and returning an array of
 packageProps. */
  const packages: packageProps[] = JSON.parse(localStorage.getItem("packages") || "[]");

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  /* Creating a state variable called errorChecks and setting it to false. */
  const [errorChecks, setErrorChecks] = useState<boolean>(false);

  /**
   * If the language is English and there are no errors, return the string "confirm payment". If the
   * language is not English and there are no errors, return the string "Zahlung bestätigen". If there
   * are errors, return the ButtonLoading component. If none of the above are true, return the string
   * "confirm payment"
   * @returns A string or a component.
   */
  const buttonState: () => JSX.Element | string = () => {
    if (englishLanguage && !errorChecks) return "confirm payment";

    if (!englishLanguage && !errorChecks) return "Zahlung bestätigen";

    if (errorChecks) return <ButtonLoading />;

    return "confirm payment";
  };

  /* Destructuring the getCreateBooking object and assigning the bookingId to booking_id. */
  const getCreateBooking = useSelector((state: RootState) => {
    return state.getCreateBooking;
  });

  const { bookingId: booking_id }: { bookingId: string } = getCreateBooking;

  /* Destructuring the getConfirmBooking object and assigning the error property to a new variable
  called errorConfirmingBooking. */
  const getConfirmBooking = useSelector((state: RootState) => {
    return state.getConfirmBooking;
  });

  const { error: errorConfirmingBooking }: { error: boolean; success: true } = getConfirmBooking;

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /**
   * It checks if the user has agreed to the terms and conditions of the package, and if so, navigates to
   * the final overview screen
   * @param {any} myPack - this is the package that the user has selected.
   */

  const goToFinalOverviewScreen = (myPack: any) => {
    setErrorChecks(true);
    const agree = (myPack?: any) => {
      const agreeTermsAndConditions = myPack?.map((p: any) => {
        // eslint-disable-next-line
        return p.product_groups?.map((pg: any) => {
          if (pg.products[0].facts.enabled !== "1") {
            // eslint-disable-next-line
            return;
          }
          return pg.products[0].facts.agreetc === "1";
        });
      });

      const filteredArr = agreeTermsAndConditions[0].filter((elem: boolean[]) => elem !== undefined);

      return filteredArr.every((anyBool: boolean) => anyBool);
    };

    let agreeValue = agree(myPack);

    agreeValue && dispatch(confirmBookingAction(myBookingObject));

    /* Checking if the user has agreed to the terms and conditions and if they have it will navigate to
  the final overview page. */
    setTimeout(() => {
      if (!errorConfirmingBooking && agreeValue) {
        setErrorChecks(false);
        navigate(`/experience/${facts?.referer}/packages/${packageIdParams}/final-overview`);
      }
    }, 1000);
  };

  /**
   * It takes an array of objects, and returns a boolean value based on the value of a property in the
   * objects
   * @param {any} myPackage - any - this is the package object that is passed in from the parent
   * component.
   * @returns A boolean
   */
  const makeBtnInactive = (myPackage: any) => {
    // eslint-disable-next-line
    const ps = myPackage[0].product_groups?.map((pg: product_groups) => {
      if (pg.products[0]?.facts?.enabled !== "1") {
        // eslint-disable-next-line
        return;
      }
      return pg.products[0]?.facts?.agreetc === "1";
    });

    const filteredArr = ps?.filter((elem: boolean | undefined) => elem !== undefined);

    return filteredArr?.every((anyBool: boolean | undefined) => anyBool === true);
  };

  const [packageState, setPackageState] = useState<any>(() => {
    const singlePackage = packages.find((item) => item.package_id === packageIdParams);
    if (singlePackage) {
      const product_groups = singlePackage?.product_groups?.map((pg: product_groups) => {
        const { deletable, multiple } = pg;
        const products = pg?.products?.map((product: products) => {
          const { facts } = product;
          // const { agreetc = "0" } = facts;
          return {
            ...product,
            facts: {
              ...facts,
              // agreetc,
            },
          };
        });
        return { deletable, multiple, products };
      });
      return {
        ...singlePackage,
        package_id: packageIdParams,
        product_groups,
      };
    } else {
      return {};
    }
  });

  let myBookingObject = {
    booking_id: booking_id,
  };

  const [autoUpdateLoading, setAutoUpdateLoading] = useState<boolean>();

  useEffect(() => {
    /**
     * gets the error count in the storage
     */
    const errCount = JSON.parse(localStorage.getItem("err") || "null");
    /**
     * if there is an update error, the select and update process
     * is automatically triggered
     */
    const handleErrorOnUpdatePackages = async () => {
      Object.keys(facts).length > 0 &&
        setTimeout(() => {
          dispatch(
            getPackagesAction({
              facts,
            })
          );

          const readStore = JSON.parse(localStorage.getItem("readStore") || "[]");

          setTimeout(() => {
            if (Object.keys(facts).length > 0) {
              dispatch(
                updatePackagesAction({
                  packages: [...readStore],
                  combine_packages: true,
                })
              );
            }
            setPackageState(readStore[0]);
          }, 500);
        }, 500);
    };

    /**
     * triggers the function if the
     * errorCount is less than 3 and error is true
     */
    if (errorUpdatedPackages && errCount < 3) {
      setAutoUpdateLoading(true);
      handleErrorOnUpdatePackages();
    } else if (!errorUpdatedPackages) {
      setAutoUpdateLoading(false);
    }
  }, [errorUpdatedPackages, dispatch, facts, autoUpdateLoading]);

  return (
    <DesktopWrapper>
      <WhiteBackground>
        {errorUpdatedPackages ? (
          <>
            <Hero
              showCloseBar={true}
              navigationRoute={() => navigate(-1)}
              showLanguageBar={false}
              vehicle={facts.vehicle}
              defaultBackdropAction={false}
              cityBackdropAction={true}
              business_area={facts.business_area}
              isFinalOverviewPage={false}
              isLandingPage={false}
              referer={facts.referer}
              facts={facts}
            />

            <Error
              errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_2.png`}
              headerText={
                englishLanguage
                  ? "Sorry, an error occured creating your booking"
                  : "Leider ist ein Fehler bei der Bestätigung Ihrer Buchung aufgetreten"
              }
              bodyText={
                englishLanguage
                  ? "Please refresh and try again"
                  : "Bitte aktualisieren Sie die Seite und versuchen Sie es erneut"
              }
            />
            <CustomBtnWidth>
              <Button
                title="refresh and continue"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </CustomBtnWidth>
          </>
        ) : errorConfirmingBooking ? (
          <>
            <Hero
              // backdropImage={`${envStaticHost}/static/${facts?.referer}/city-skyline/munich.jpg`}
              showCloseBar={true}
              navigationRoute={() => navigate(-1)}
              showLanguageBar={false}
              vehicle={facts.vehicle}
              defaultBackdropAction={false}
              cityBackdropAction={true}
              business_area={facts.business_area}
              isFinalOverviewPage={false}
              isLandingPage={false}
              referer={facts.referer}
              facts={facts}
            />

            <Error
              errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_2.png`}
              headerText={
                englishLanguage
                  ? "An error occurred during your booking confirmation."
                  : "Leider ist bei der Bestätigung Deiner Buchung ein Fehler aufgetreten."
              }
              bodyText={
                englishLanguage
                  ? "Please try again. We sincerly apologise"
                  : "Bitte aktualisiere die Seite und versuche es erneut. Aktualisieren und fortsetzen"
              }
            />

            <CustomBtnWidth>
              <Button
                title={englishLanguage ? "Back Home" : "Zur Startseite"}
                onClick={() => {
                  resetLocalStorage();
                  navigate("/");
                }}
              />
            </CustomBtnWidth>
          </>
        ) : (
          <React.Fragment key={Math.random().toString(36).slice(8)}>
            <BackButton>
              <img
                src={`${envStaticHost}/static/${facts?.referer}/icon/black-round-backarrow.svg`}
                alt=""
                className="closeIcon"
                // style={{ color: `${colorChange}` }}
                onClick={() => navigate(-1)}
              />
            </BackButton>
            <WhiteBackground>
              {englishLanguage ? (
                <div style={{ paddingTop: "12px" }}>
                  <PageTitle
                    activityLowerCase="PAYMENT CONFIRMATION"
                    title="You are about to pay the following items to the listed retail and service partners. Please confirm by activating the switch below."
                  />
                </div>
              ) : (
                <PageTitle
                  activityLowerCase="ZAHLUNGSBESTÄTIGUNG"
                  title="Du bist im Begriff, die folgenden Beträge an die aufgeführten Einzelhandels- und Dienstleistungspartner zu übermitteln. Bitte bestätige, indem Du den Schalter unten aktivierst."
                />
              )}

              {packageState.product_groups &&
                packageState.product_groups
                  .reduce((acc: any, product_groups: product_groups) => acc.concat(product_groups?.products || []), [])
                  .filter((singleProduct: products) => singleProduct?.facts?.enabled === "1")
                  .map((singleProduct: products, idx: number) => (
                    <SingleProduct
                      key={Math.random().toString(36).slice(8)}
                      index={idx}
                      product={singleProduct}
                      createNewResponseObjectProductGui={createNewResponseObjectProductGui}
                      setPackageState={setPackageState}
                      packageState={packageState}
                    />
                  ))}

              <CustomBtnWidth>
                <Button
                  inactive={makeBtnInactive([packageState])}
                  disable={!makeBtnInactive([packageState])}
                  title={buttonState()}
                  onClick={() => goToFinalOverviewScreen([packageState])}
                />
              </CustomBtnWidth>
            </WhiteBackground>
          </React.Fragment>
        )}
      </WhiteBackground>
    </DesktopWrapper>
  );
};

const SingleProduct = ({
  product,
  index,
  createNewResponseObjectProductGui,
  setPackageState,
  packageState,
}: {
  product: products;
  index: number;
  createNewResponseObjectProductGui: Function;
  setPackageState: Function;
  packageState: any;
}) => {
  const dispatch = useDispatch();

  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  const [collapsed, setCollapsed] = useState<boolean>(product.facts?.agreetc === "1");

  const handleCollapsed = useCallback(() => {
    if (product.facts?.agreetc === "1") {
      setCollapsed(!collapsed);
    }
  }, [product.facts, collapsed]);

  const handleClick = useCallback(() => {
    const agreetc = product.facts?.agreetc === "1" ? "0" : "1";
    const newResponseObjectBooking = createNewResponseObjectProductGui(
      {
        packages: [packageState],
        combine_packages: true,
      },
      product?.product_id,
      "agreetc",
      agreetc
    );
    dispatch(updatePackagesAction(newResponseObjectBooking));
    setPackageState({ ...newResponseObjectBooking.packages[0] });
    setCollapsed(agreetc === "1");
    writeBackup("readStore", [packageState]);

    // eslint-disable-next-line
  }, [product, packageState, setPackageState, createNewResponseObjectProductGui]);

  return (
    <PaymentCardWrapper collapsible={product.facts?.agreetc === "1"}>
      <Stamp />
      <PaymentCard>
        <article>
          <div className="name-container" onClick={handleCollapsed}>
            <h4 className="name">{product.facts?.vendor_name}</h4>{" "}
            <div
              className={
                product.facts?.agreetc === "1"
                  ? "booking-check-container"
                  : "booking-check-container booking-check-container-color"
              }
            >
              <p className={product.facts?.agreetc === "1" ? "booking-check color" : "booking-check"}>
                <Formatted noWrap>
                  {englishLanguage ? `Booking ${" "} ${index + 1}` : `Buchung ${" "} ${index + 1}`}{" "}
                </Formatted>{" "}
                {product.facts?.agreetc === "1" && <MdDone style={{ fontSize: "0.7rem" }} />}{" "}
              </p>
            </div>
          </div>
          {!collapsed && (
            <>
              <div className="order-info-container">
                {product.guis?.map(
                  (guis: guis, index: number) =>
                    guis?.widget === "label" &&
                    index === 0 && (
                      <p
                        key={Math.random().toString(36).slice(8)}
                        style={{
                          fontFamily: `var(--p-bold)`,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: englishLanguage ? guis?.title?.en : guis?.title?.de,
                        }}
                      />
                    )
                )}
                <h4>
                  <span>{formatPrice(product.cost, englishLanguage)}</span>
                </h4>
              </div>

              <div className="confirm-container">
                {" "}
                <p
                  style={{
                    fontFamily: `var(--p-bold)`,
                  }}
                >
                  {englishLanguage ? "Confirm the payment and accept the" : "Zahlung bestätigen und"}{" "}
                  {englishLanguage ? (
                    <a
                      href={product?.facts?.vendor_legal_en}
                      style={{ color: "var(--clr-secondary-1)" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  ) : (
                    <a
                      href={product?.facts?.vendor_legal_de}
                      style={{ color: "var(--clr-secondary-1)" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Allgemeine Geschäftsbedingungen akzeptieren
                    </a>
                  )}
                </p>
                <SwitchButton toogleSwitch={product.facts?.agreetc === "1"} onClick={handleClick} />
              </div>
            </>
          )}
        </article>
      </PaymentCard>
    </PaymentCardWrapper>
  );
};

const PaymentCard = styled.div`
  margin-top: -14px;
  background: var(--clr-white);
  z-index: 2;
  position: relative;

  @media screen and (min-width: 800px) {
    margin-top: -10px;
  }
`;

const PaymentCardWrapper = styled.div.attrs(({ collapsible }: { collapsible: boolean }) => ({ collapsible }))`
  width: 90vw;
  margin: 20px auto 20px;
  position: relative;
  border-radius: 16px;
  -webkit-box-shadow: 0px -7px 18px -17px #000000;
  box-shadow: 0px -7px 18px -17px #000000;

  article {
    padding: 0 1rem 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
  }

  .order-info-container {
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    p {
      span {
        font-family: "Asterisk Sans Pro";
        font-weight: 600;
      }
    }
  }

  .name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 36px;

    .name {
      color: var(--clr-primary-1);
      ${({ collapsible }) => collapsible && "cursor: pointer;"};
    }

    .booking-check-container {
      color: #5eb76c;
      transition: var(--transistion);
      border: 1px solid #5eb76c;
      border-radius: var(--radius-components);
      height: 2rem;
      /* max-width: 6rem; */
      display: flex;
      align-items: center;
      padding: 0.2rem 0.6rem;
    }

    .booking-check-container-color {
      border: 1px solid var(--clr-primary-1);
      transition: var(--transistion);
      max-width: 4.5rem;
    }

    .booking-check {
      font-size: 11px;
      display: flex;
      align-items: center;
      transition: var(--transistion);
      font-weight: bold;
      flex-wrap: nowrap;
      transition: var(--transistion);
      color: var(--clr-primary-1);
      width: 4rem;
    }

    .color {
      transition: var(--transistion);
      color: #5eb76c;
    }
  }

  .confirm-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;

    p {
      font-size: 14px;
      color: var(--clr-primary-1);
      width: 70%;
    }
  }

  @media screen and (min-width: 800px) {
    background: var(--clr-white);
    width: 50vw;

    article {
      margin: 0 auto;
      padding: 0 2rem 2rem;
    }
  }
`;

const BackButton = styled.div`
  .closeIcon {
    height: 2rem;
    width: 2rem;
    margin: 1rem 0 1rem 1rem;
  }

  @media screen and (min-width: 800px) {
    width: 50vw;
  }
`;
export default PaymentConfirmationScreen;
