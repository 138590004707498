import React from "react";
import { InputElement } from "../components/InputElement";
import PhoneInput from "react-phone-number-input";
import { Dispatch } from "redux";
import { createBookingAction } from "../State/ActionCreators/index";
import { writeBackup } from "../Utils/helper";

type Props = {
  englishLanguage: boolean;
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  streetAndNo: string;
  setStreetAndNo: React.Dispatch<React.SetStateAction<string>>;
  postalCode: string;
  setPostalCode: React.Dispatch<React.SetStateAction<string>>;
  city: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  cstEmail: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  phoneNumber: string;
  setPhoneNumber: (value?: string | undefined) => void;
  cstConfirmed: boolean;
  setCstConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  singlePackage: any;
  dispatch: Dispatch<any>;
  updatePackagesAction: Function;
  createNewResponseObject: Function;
  packageState: any;
  setPackageState: React.Dispatch<React.SetStateAction<string>>;
  errorUpdatedPackages: boolean;
  bookingObject: any;
  skipPayment: boolean;
};

const FormControl: React.FC<Props> = ({
  englishLanguage,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  streetAndNo,
  setStreetAndNo,
  postalCode,
  setPostalCode,
  city,
  setCity,
  cstEmail,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  cstConfirmed,
  setCstConfirmed,
  singlePackage,
  dispatch,
  updatePackagesAction,
  createNewResponseObject,
  packageState,
  setPackageState,
  errorUpdatedPackages,
  bookingObject,
  skipPayment,
}) => {
  // check whether user entered all detail data
  const checkDetails = () => {
    return firstName && lastName && streetAndNo && postalCode && city && cstEmail && phoneNumber;
  };

  return (
    <React.Fragment>
      <div className="information-container">
        {!skipPayment && (
          <h5 className="details-heading" style={{ color: "(var(--clr-summary-primary-1)" }}>
            {englishLanguage
              ? "Final payment details will be listed during the  payment process."
              : "Die endgültigen Zahlungsdaten werden während des Zahlungsvorgangs angegeben."}
          </h5>
        )}
        <form>
          <div className="wrapper">
            <label htmlFor="firstname" className="label">
              <h5>{englishLanguage ? "First name" : "Vorname"}</h5>
            </label>
            <InputElement
              spellCheck="false"
              type="text"
              value={firstName}
              name="firstname"
              id="firstname"
              className="input"
              required
              placeholder=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            />{" "}
          </div>

          {/* LAST NAME */}
          <div className="wrapper">
            <label htmlFor="lastname" className="label">
              <h5>{englishLanguage ? "Last name" : "Nachname"}</h5>
            </label>
            <InputElement
              type="text"
              value={lastName}
              name="lastname"
              id="lastname"
              className="input"
              required
              placeholder=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            />{" "}
          </div>

          {/* STREET */}
          <div className="wrapper">
            <label htmlFor="street" className="street label">
              <h5>{englishLanguage ? "Street and Number" : "Straße und Hausnummer"} </h5>
            </label>
            <InputElement
              type="text"
              value={streetAndNo}
              name="street"
              id="street"
              className="input"
              required
              placeholder=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setStreetAndNo(e.target.value)}
            />{" "}
          </div>

          {/* POSTAL CODE */}
          <div className="wrapper">
            <label htmlFor="postalcode" className="label">
              <h5>{englishLanguage ? " Postal code" : "Postleitzahl"} </h5>
            </label>
            <InputElement
              type="text"
              value={postalCode}
              name="postalcode"
              id="postalcode"
              className="input"
              required
              placeholder=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
            />{" "}
          </div>

          {/* CITY */}
          <div className="wrapper ">
            <label htmlFor="city" className="label">
              <h5>{englishLanguage ? "City" : "Stadt"}</h5>
            </label>
            <InputElement
              type="text"
              value={city}
              name="city"
              id="city"
              className="input"
              required
              placeholder=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
            />{" "}
          </div>

          {/* EMAIL */}
          <div className="wrapper">
            <label htmlFor="email" className="label">
              <h5>{englishLanguage ? "Email Address" : "E-Mail Adresse"}</h5>
            </label>
            <InputElement
              type="email"
              autoComplete="off"
              value={cstEmail}
              name="email"
              id="email"
              className="input"
              required
              placeholder=" "
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />{" "}
          </div>
          {/* End of Email */}

          <div className="wrapper">
            {/* NUMBER */}
            <label htmlFor="phoneNumber">
              <PhoneInput
                international
                defaultCountry="DE"
                value={phoneNumber}
                placeholder=""
                onChange={setPhoneNumber}
                inputComponent={InputElement}
              />
            </label>
          </div>
        </form>

        <div className="radioDiv">
          <input
            type="radio"
            value={singlePackage.facts?.agreetc}
            name="bbtcondition"
            className="inputRadio"
            onClick={(e) => {
              if (!checkDetails()) {
                alert("please fill in all details");
                e.preventDefault(); // this keeps the radio button unchecked
                return false;
              }
              if (cstConfirmed) {
                // we've already done this with correct details
                return;
              }
              // booking not yet created, go ahead
              setCstConfirmed(true);

              dispatch(
                updatePackagesAction(
                  createNewResponseObject(
                    {
                      packages: [packageState],
                      combine_packages: true,
                    },
                    "1"
                  )
                )
              );

              writeBackup("readStore", [packageState]);

              setPackageState({
                ...packageState,
                facts: {
                  ...packageState.facts,
                  agreetc: "1",
                },
              });

              // this dispatch re-renders the page to also
              // check for facts?.agreetc === "1"
              // if we ever take this function to the button, dispatch
              // a function to re-render

              if (!errorUpdatedPackages)
                setTimeout(() => {
                  dispatch(createBookingAction(bookingObject));
                }, 1000);
            }}
          />
          <h5 style={{ color: "var(--input-element-border-bottom)" }}>
            {englishLanguage
              ? "I confirm that the above information is correct"
              : "Ich bestätige, dass die oben genannten Informationen korrekt sind."}
          </h5>
        </div>
      </div>

      {/* ************************** */}
    </React.Fragment>
  );
};

export default FormControl;
