//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is the loading component, which is displayed whenever an API is being
// called and pending.
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import styled from "styled-components";
import React, { CSSProperties } from "react";

/**
 * It returns a div with a class of loader-wrapper, which contains a div with a class of loader, which
 * contains a div with a class of loader loader-inner
 * @returns A loading spinner
 */
interface LoadingProps {
  inline?: boolean;
}
const Loading: React.SFC<LoadingProps> = (props) => {
  const inline = props.inline;
  const wrapperStyle = inline ? ({} as CSSProperties) : ({ height: "50vh" } as CSSProperties);
  const loaderWrapperClass = inline ? "loader-wrapper-inline" : "loader-wrapper";
  return (
    <Wrapper style={wrapperStyle}>
      <div className={loaderWrapperClass}>
        <div className="loader">
          <div className="loader loader-inner"></div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 90vw;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-wrapper {
    width: 10rem;
    height: 10rem;
  }

  .loader-wrapper-inline {
    width: 7rem;
    height: 7rem;
    transform: scale(0.5);
  }

  .loader {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 10px solid var(--clr-white);
    border-top-color: var(--clr-primary-1);
    border-bottom-color: var(--clr-primary-2);
    border-radius: var(--radius-50);
    animation: rotate 2s linear infinite;
  }

  .loader-inner {
    border-top-color: var(--clr-primary-1);
    border-bottom-color: var(--clr-primary-2);
    animation-duration: 2.5s;
  }

  @keyframes rotate {
    0% {
      transform: scale(1) rotate (360deg);
    }
    50% {
      transform: scale(0.8) rotate(-360deg);
    }
    100% {
      transform: scale(1) rotate (360deg);
    }
  }

  @media screen and (min-width: 800px) {
    width: 60vw;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export default Loading;
