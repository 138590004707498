import React, { DetailedHTMLProps, ForwardedRef, forwardRef, InputHTMLAttributes, useState } from "react";
import styled from "styled-components";

export const InputElement = forwardRef(
  (
    props: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [focused, setFocused] = useState(false);
    const { onFocus, onBlur, ...inputProps } = props;

    return (
      <StyledInput className={`InputElement ${focused ? "InputElement--focus" : ""}`}>
        <input
          onFocus={(e) => {
            onFocus && onFocus(e);
            setFocused(true);
          }}
          onBlur={(e) => {
            onBlur && onBlur(e);
            setFocused(false);
          }}
          ref={ref}
          {...inputProps}
        />
      </StyledInput>
    );
  }
);

const StyledInput = styled.div`
  &.InputElement {
    background: none;
    height: 50px;
    line-height: 50px;
    padding: 0;
    width: 100%;
    outline: none;
    border: var(--input-element-border);
    border-radius: var(--input-element-border-radius);
    border-bottom: var(--input-element-border-bottom);
    position: relative;

    input {
      font-family: var(--p-regular);
      font-size: 18px;
      border: 0;
      height: 100%;
      width: 100%;
      display: block;
      background: var(--bg-transparent);
      padding: 0 16px;
      color: var(--clr-summary-primary-1);

      &::placeholder {
        color: var(--clr-summary-primary-1);
        font-size: 18px;
        opacity: 0.3;
      }

      &:focus-visible {
        outline: none;
      }

      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder {
        color: var(--clr-placeholder);
        font-family: var(--p-regular-italic);
        opacity: 0.1;
      }
    }

    &--focus {
      border-color: var(--clr-secondary-1);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 2px solid #cfdce2;
        border-radius: var(--radius-6px);
      }
    }
  }
`;
