export const SET_FACTS = "SET_FACTS";
export const GET_FACTS = "GET_FACTS";
export const UPDATE_FACTS = "UPDATE_FACTS";

type GetFactsAction = {
  type: typeof GET_FACTS;
  payload: any;
}

type SetFactsAction = {
  type: typeof SET_FACTS;
  payload: any;
}

type UpdateFactsAction = {
  type: typeof UPDATE_FACTS;
  payload: any;
}

export type FactsActions =
  | GetFactsAction
  | SetFactsAction
  | UpdateFactsAction;
