import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../State/store";
import { getBooking } from "../State/ActionCreators";

type Props = {};

const StatusPage = (props: Props) => {
  const { code } = useParams();
  const [verification, setVerification] = useState("");
  const [lastName, setLastName] = useState("");
  const dispatch = useDispatch();

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  const language = facts["language"] || "en";

  const getBooking_ = useSelector((state: RootState) => {
    return state.getBooking;
  });

  const {
    error,
    loading,
    bookingData,
    success,
  }: { error: boolean; success: boolean; loading: boolean; bookingData: any } = getBooking_;

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      dispatch(getBooking(code, verification));
    } catch (error) {
      console.log(error);
    }
  };

  if (bookingData?.booking_id === "" || bookingData === undefined) {
    return (
      <Div>
        <input
          type="text"
          id="verification"
          value={verification}
          onChange={(e) => setVerification(e.target.value)}
          placeholder="Last name or email"
        />

        <input
          type="text"
          id="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Enter your last name"
        />
        <button onClick={handleClick}>Send Data</button>
      </Div>
    );
  }

  return (
    <div>
      {loading && <>Loading...</>}

      <h3>booking_id: {bookingData?.booking_id}</h3>
      <h3>status: {bookingData?.status}</h3>
      <h3>Media: {bookingData?.guis?.[0]?.media?.[0] ?? "No media available"}</h3>
      <h3>title: {bookingData?.guis?.[0]?.title[language] ?? "no title"}</h3>
      <h3>text: {bookingData?.guis?.[0]?.text[language] ?? "no text"}</h3>

      <div>
        {bookingData?.vendors?.map((vendor: any, index: number) => (
          <div key={index}>
            <div>
              <span>partner_shop</span>: {vendor?.partner_shop}
            </div>

            {vendor?.products?.map((product: any, idx: number) => (
              <div key={idx}>
                {product?.guis?.map((gui: any, j: number) => (
                  <div key={j}> "title": {gui?.title?.[language]}</div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusPage;

const Div = styled.div`
  padding: 4px;
  margin: 4px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  height: 100vh;
`;
