import styled from "styled-components";
import { ReactNode } from "react";
import { envStaticHost } from "../Utils/windowHelper";

/* quite a hack. we now that the linear border design is only used in volkswagen
 * and can specify the image url like this.
 * TODO: generate path using referer. I don't know how to do this at runtime
 */

const factsString = localStorage.getItem("facts") as any;
const facts = JSON.parse(factsString);
const linear_stamp_border = `${envStaticHost}/static/${facts?.referer}/icon/stamp-border.svg`;

interface Props {
  linear?: boolean; // use linear border graphics instead of shadow
}

export const StampBox: React.FC<Props> = ({ linear, children }) => {
  if (linear) {
    return (
      <StampContainerLinear>
        <StampLinear />
        <StampContentLinear>{children}</StampContentLinear>
      </StampContainerLinear>
    );
  } else {
    return (
      <StampContainer>
        <Stamp />
        <StampContent>{children}</StampContent>
      </StampContainer>
    );
  }
};

const StampContainerLinear = styled.div``;

const StampContentLinear = styled.div`
  margin-top: -27px;
  padding-top: 38px;
  background: var(--clr-summary-bg);
  z-index: 2;
  position: relative;
  border: solid;
  border-width: 0 1.5px;
  border-image: var(--border-image);

  @media screen and (min-width: 800px) {
    border-width: 0 2px;
  }
`;

const StampLinear = styled.div`
  margin-top: -10px;
  height: 24px;
  width: 100%;
  display: inline-block;
  padding-top: 10px;
  position: relative;
  z-index: 3;
  background-image: url(${linear_stamp_border});
  background-color: transparent;
  background-repeat: repeat-x;
  background-size: 20px 10px;
  background-position: 1px 0;

  @media screen and (min-width: 800px) {
    height: 25px;
    background-size: 25px 12px;
  }
`;

const StampContainer = styled.div`
  box-shadow: rgb(0 0 0) 0px -7px 18px -17px;
`;

const StampContent = styled.div`
  margin-top: -14px;
  padding-top: 18px;
  background: var(--clr-bs-stamp-bg);
  z-index: 2;
  position: relative;
`;

export const Stamp = styled.div`
  margin-top: -10px;
  height: 20px;
  width: 100%;
  display: inline-block;
  padding-top: 10px;
  position: relative;
  filter: drop-shadow(0px 0px 3px #ededed);
  background-image: radial-gradient(transparent 0px, transparent 7px, white 7px, white);
  background-repeat: repeat-x;
  background-size: 20px 30px;
  background-position: 0px -20px;

  @media screen and (min-width: 800px) {
    background-image: radial-gradient(transparent 0px, transparent 10px, white 10px, white);
    background-size: 30px 35px;
  }
`;
