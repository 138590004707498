//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components is thrown when there is an error in calling the API or
// no packages or products to display
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import { DesktopWrapper } from "../Utils/styles";
import styled from "styled-components";

type errorProps = {
  errorImage: string;
  headerText?: string;
  bodyText?: string;
  aTag?: string;
};

/**
 * This function is a React component that takes in props and returns a styled div with an image,
 * header, and body text
 * @param  - errorImage: The image you want to display
 * @returns A functional component that takes in props and returns a div with a class of
 * error-container.
 */
const Error: React.FC<errorProps> = ({ errorImage, headerText, bodyText, aTag }) => {
  return (
    <DesktopWrapper>
      <Wrapper>
        <div className="error-container">
          <div className="error-image">
            <img src={errorImage} alt="no packages" />
          </div>

          <h5 className="error-text">{headerText}</h5>

          <p className="body-text">
            {bodyText} <br /> <a href="mailto:a  support.live@banbutsu.com?subject = Feedback">{aTag}</a>
          </p>
        </div>
      </Wrapper>
    </DesktopWrapper>
  );
};

const Wrapper = styled.div`
  background: var(--popup-bg);
  margin: 0 auto;

  .error-container {
    text-align: center;

    h5 {
      color: var(--clr-primary-1);
    }

    a {
      font-size: 16px;
      color: var(--clr-primary-3);
      font-family: var(--p-bold);
      text-decoration: underline;
    }

    .error-text {
      padding: 10px 0;
      width: 100%;
    }

    .body-text {
      color: var(--clr-primary-3);
      width: 75%;
      margin: 0 auto;
    }

    .error-image {
      img {
        width: 8rem;
      }
    }
  }
`;

export default Error;
