//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components holds values like location, start and end date of the trip
// which is being used on the product screen
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";
import { envStaticHost } from "../Utils/windowHelper";

type Props = {
  startCity: string;
  startCountry: string;
  endCity: string;
  endCountry: string;
  kilometre: string;
  mapLink?: string;
  referer?: string;
};

const Travel: React.FC<Props> = ({ startCity, startCountry, endCity, endCountry, kilometre, mapLink, referer }) => {
  return (
    <Wrapper className="section-center">
      <div className={referer}>
        <a href={mapLink} target="_blank" rel="noreferrer">
          {" "}
          <div className="container">
            <div>
              <h5>{startCity}</h5>
              <span>{startCountry}</span>
            </div>
            <div className="distance">
              {/* <p>....</p> */}
              <img src={`${envStaticHost}/static/${referer}/car/icon-car-suv.svg`} alt="" />{" "}
              <span>
                {isNaN(Math.round(parseInt(kilometre) / 1000)) ? 0 : Math.round(parseInt(kilometre) / 1000)} km
              </span>
            </div>
            {/* <p>....</p> */}

            <div>
              <h5>{endCity}</h5>
              <span>{endCountry}</span>
            </div>
          </div>
        </a>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .etravel_mercedes,
  .etravel_geo {
    width: 100%;
    margin: 0 auto;
    background: var(--clr-primary-1);
    border-radius: var(--radius-buttons);
    box-shadow: var(--box-shadow);
    /* padding: 0px 15px; */

    .container {
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      height: 6rem;
      color: var(--clr-white);
      text-align: center;

      div {
        width: 25%;
      }

      .distance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          text-align: center;
        }

        img {
          width: 2.5rem;
          align-self: center;
          filter: var(--clr-primary-1-filter);
        }
      }

      &,
      h4,
      span {
        text-align: left;
      }

      h5 {
        margin-bottom: 9px;
        text-transform: uppercase;
        color: var(--clr-white);
      }

      span {
      }

      img {
        height: 2.5rem;
      }

      div:last-child {
        &,
        h4,
        span {
          text-align: right;
        }
      }
    }
  }

  .manx {
    width: 95vw;
    margin: 0 auto;
    background: var(--clr-primary-1);
    border-radius: var(--radius-components);
    box-shadow: var(--box-shadow);
    padding: 0px 15px;

    .container {
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      height: 6rem;
      color: var(--clr-white);
      text-align: center;

      div {
        width: 25%;
      }

      .distance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          text-align: center;
        }

        img {
          width: 2.5rem;
          align-self: center;
          filter: var(--clr-primary-1-filter);
        }
      }

      &,
      h4,
      span {
        text-align: left;
      }

      h5 {
        margin-bottom: 9px;
        text-transform: uppercase;
        color: var(--clr-white);
      }

      span {
      }

      img {
        height: 2.5rem;
      }

      div:last-child {
        &,
        h4,
        span {
          text-align: right;
        }
      }
    }
  }

  .sharenow,
  .sandbox,
  .mini,
  .volkswagen,
  .ces24 {
    width: 95vw;
    margin: 0 auto;
    background: var(--clr-white);
    border-radius: var(--radius-components);
    box-shadow: var(--box-shadow);
    padding: 0px 15px;

    .container {
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      height: 6rem;
      color: var(--clr-primary-2);
      text-align: center;

      div {
        width: 25%;
      }

      .distance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          text-align: center;
        }

        img {
          width: 2.5rem;
          align-self: center;
          filter: var(--clr-secondary-1-filter);
        }
      }

      &,
      h4,
      span {
        text-align: left;
      }

      h5 {
        margin-bottom: 9px;
        text-transform: uppercase;
        color: var(--clr-primary-1);
      }

      span {
      }

      img {
        height: 2.5rem;
      }

      div:last-child {
        &,
        h4,
        span {
          text-align: right;
        }
      }
    }
  }

  .etravel_audi {
    margin: 2rem auto;
    background: var(--clr-white);
    border-radius: var(--radius-components);
    box-shadow: var(--box-shadow);
    padding: 0px 15px;

    .container {
      display: flex;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      height: 6rem;
      color: var(--clr-primary-2);
      text-align: center;

      div {
        width: 25%;
      }

      .distance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
          text-align: center;
        }

        img {
          width: 2.5rem;
          align-self: center;
          filter: var(--iconset-filter);
        }
      }

      &,
      h4,
      span {
        text-align: left;
      }

      h5 {
        margin-bottom: 9px;
        text-transform: uppercase;
        color: var(--clr-primary-1);
      }

      span {
      }

      img {
        height: 2.5rem;
      }

      div:last-child {
        &,
        h4,
        span {
          text-align: right;
        }
      }
    }
  }
  @media screen and (min-width: 800px) {
    width: 50vw;
  }
`;

export default Travel;
