//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this page calls one endpoint, and also handles the customisation of each
// product to fit the end users preference.
//
// POST /v1/portal/update-packages

// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  DesktopWrapper,
  WhiteBackground,
  SectionWrapper,
  Formatted,
  CustomBtnWidth,
  ButtonSticky,
} from "../Utils/styles";
import {
  Button,
  PartnerHeader,
  DateTimeComponent,
  AmountButtons,
  Error,
  TotalComponent,
  ProductChoice,
  ProductLabel,
  Iconset,
  Travel,
} from "../components/index";
import { updatePackagesAction, getPackagesAction } from "../State/ActionCreators/index";
import { useNavigate } from "react-router-dom";
import { RootState } from "../State/store";
import styled from "styled-components";
import {
  backToTop,
  createNewResponseObjectNumber,
  createNewResponseObjectProductGui,
  writeBackup,
} from "../Utils/helper";
import { UPDATE_FACTS } from "../State/ActionTypes/facts";
import { envStaticHost } from "../Utils/windowHelper";

interface guisChoice {
  de?: string;
  en?: string;
  value?: string;
}

interface guis {
  editable?: boolean;
  fact_name: string;
  inc?: number;
  max?: number;
  min?: number;
  required?: boolean;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  widget: string;
  media: string[];
  choice: guisChoice[];
  class: string;
}

interface products {
  cost: number;
  currency: string;
  facts: any;
  guis: guis[];
  latest_cancel: string;
  product_id: string;
  cost_net: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface categories {
  en?: string;
}

interface packageProps {
  categories: categories;
  facts: any;
  guis: guis[];
  count: number;
  package_id: string | undefined;
  product_groups: product_groups[];
}

const ProductPageV2 = () => {
  /* Using the useNavigate hook to navigate to a different page. */
  const navigate = useNavigate();

  /* Importing the useDispatch hook from the react-redux library. */
  const dispatch = useDispatch();

  /* Destructuring the useParams object. */
  const { productid: productIdParams, packageid: packageIdParams } = useParams<{
    productid?: string;
    packageid?: string;
  }>();

  /* Using the useSelector hook to get the updatedPackages from the Redux store. */
  const getUpdatedPackages = useSelector((state: RootState) => {
    return state.getUpdatedPackages;
  });

  const {
    loading: loadingUpdatedPackages,
    error: errorUpdatedPackages,
    updatedPackages,
  }: {
    loading: boolean;
    error: boolean;
    updatedPackages?: packageProps[];
  } = getUpdatedPackages;

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /* Checking if there is a localStorage item called "packages" and if there is, it is parsing it into an
array of packageProps. If there is no localStorage item called "packages", it is creating an empty
array. */
  const packages: packageProps[] = JSON.parse(localStorage.getItem("packages") || "[]");

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  useEffect(() => {
    /* Checking if the loadingUpdatedPackages, errorUpdatedPackages, or updatedPackages are true. If they
  are true, it will return nothing. */
    if (loadingUpdatedPackages || errorUpdatedPackages || !updatedPackages?.[0]) {
      return;
    }

    /* Setting the state of the package to the first item in the updatedPackages array. */
    setPackageState(updatedPackages[0]);
  }, [updatedPackages, loadingUpdatedPackages, errorUpdatedPackages]);

  const [packageState, setPackageState] = useState<any>(() => {
    /* Finding the package that matches the packageIdParams and render
    accoordingly */
    const singlePackage = packages.find((item) => item.package_id === packageIdParams);

    if (singlePackage) {
      const product_groups = singlePackage?.product_groups?.map((pg: product_groups) => {
        const { deletable, multiple } = pg;
        const products = pg?.products?.map((product: products) => {
          return {
            ...product,
          };
        });
        return { deletable, multiple, products };
      });
      return {
        ...singlePackage,
        package_id: packageIdParams,
        product_groups,
      };
    } else {
      return {};
    }
  });

  /**
   * It takes in a duration, endTimeInDays, startTimeInDays, durationMin, and durationMax and returns a
   * duration
   * @param {string} duration - the duration of the trip
   * @param {Date} endTimeInDays - The end date of the trip.
   * @param {Date} startTimeInDays - The start date of the trip
   * @param {string} durationMin - The minimum duration of the trip.
   * @param {string} durationMax - The maximum number of days that the user can select.
   * @returns The duration of the trip.
   */
  const durationFailSafe = (
    duration: string,
    endTimeInDays: Date,
    startTimeInDays: Date,
    durationMin: string,
    durationMax: string
  ) => {
    if (!duration) {
      let value;
      value = new Date(endTimeInDays).getDate() - new Date(startTimeInDays).getDate() + 1;
      duration = value.toString();
    }

    if (duration < durationMin) {
      duration = durationMin;
    }

    if (duration > durationMax) {
      duration = durationMax;
    }
    return duration;
  };

  useEffect(() => {
    backToTop();
  }, []);

  /**
   * It takes a fact name and a value, creates a new response object, updates the package state, and
   * dispatches the new response object to the redux store
   * @param {string} factName - The name of the fact you want to get the value of.
   * @param {string} value - The value of the fact that you want to get.
   */
  const getProductFactsGuiValue = async (factName: string, value: string) => {
    const newResponseObject = createNewResponseObjectProductGui(
      {
        packages: [packageState],
        combine_packages: true,
      },
      productIdParams,
      factName,
      value
    );

    setPackageState(newResponseObject.packages[0]);
    dispatch(updatePackagesAction(newResponseObject));

    writeBackup("readStore", [packageState]);
  };

  /**
   * It takes a boolean value and returns a new response object number
   * @param {boolean} value - boolean - this is the value of the checkbox
   */
  const getNumberOfIndividual = async (value: boolean) => {
    const newResponseObjectNumber = createNewResponseObjectNumber(
      {
        packages: [packageState],
        combine_packages: true,
      },
      productIdParams,
      value
    );

    setPackageState(newResponseObjectNumber.packages[0]);
    dispatch(updatePackagesAction(newResponseObjectNumber));

    writeBackup("readStore", [packageState]);
  };

  const [autoUpdateLoading, setAutoUpdateLoading] = useState<boolean>();

  useEffect(() => {
    /**
     * gets the error count in the storage
     */
    const errCount = JSON.parse(localStorage.getItem("err") || "null");
    /**
     * if there is an update error, the select and update process
     * is automatically triggered
     */
    const handleErrorOnUpdatePackages = async () => {
      Object.keys(facts).length > 0 &&
        setTimeout(() => {
          dispatch(
            getPackagesAction({
              facts,
            })
          );

          const readStore = JSON.parse(localStorage.getItem("readStore") || "[]");

          setTimeout(() => {
            if (Object.keys(facts).length > 0) {
              dispatch(
                updatePackagesAction({
                  packages: [...readStore],
                  combine_packages: true,
                })
              );
            }
            setPackageState(readStore[0]);
          }, 500);
        }, 500);
    };

    /**
     * triggers the function if the
     * errorCount is less than 3 and error is true
     */
    if (errorUpdatedPackages && errCount < 3) {
      setAutoUpdateLoading(true);
      handleErrorOnUpdatePackages();
    } else if (!errorUpdatedPackages) {
      setAutoUpdateLoading(false);
    }
  }, [errorUpdatedPackages, dispatch, facts, autoUpdateLoading]);

  const handleChange = useCallback(
    (key, value) => {
      /* Updating the state of the facts object. */
      dispatch({
        type: UPDATE_FACTS,
        payload: {
          [key]: value,
        },
      });
    },
    [dispatch]
  );

  return (
    <DesktopWrapper>
      <WhiteBackground>
        <ProductWrapper>
          {errorUpdatedPackages ? (
            <>
              <PartnerHeader
                backgroundImage={`${envStaticHost}/static/${facts?.referer}/city-skyline/munich.jpg`}
                logoContainer={false}
              />

              <Error
                errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_3.png`}
                headerText={
                  englishLanguage
                    ? "Sorry, a server error occured updating your package"
                    : "Entschuldigung, es scheint ein Problem zu geben."
                }
                bodyText={
                  englishLanguage
                    ? "Please go back and try again"
                    : "Bitte gehen Sie zurück und versuchen Sie es erneut"
                }
              />
              <CustomBtnWidth>
                <Button title="Back Home" onClick={() => navigate(`/experience/${facts?.referer}`)} />
              </CustomBtnWidth>
            </>
          ) : (
            <React.Fragment>
              {packageState?.product_groups?.map((product_groups: product_groups) =>
                product_groups?.products
                  ?.filter((singleProduct: products) => singleProduct?.product_id === productIdParams)
                  ?.map((singleProduct: products, idx: number) =>
                    singleProduct?.guis?.map((gui: guis, idx: number) => {
                      switch (gui?.widget) {
                        case "label":
                          return (
                            <React.Fragment key={idx}>
                              <ProductLabel {...gui} />
                            </React.Fragment>
                          );

                        case "iconset":
                          return (
                            <React.Fragment key={idx}>
                              <div className="iconset">
                                {gui?.media?.map((logo: string, i: number, array: string[]) => (
                                  <Iconset
                                    arrayLength={array.length}
                                    key={i}
                                    image={logo}
                                    idx={i}
                                    text={englishLanguage ? gui?.text.en?.split("/")[i] : gui?.text.de?.split("/")[i]}
                                  />
                                ))}
                              </div>
                            </React.Fragment>
                          );

                        case "travel":
                          return (
                            <div key={idx} style={{ background: "var(--generalBackground)" }}>
                              <Travel
                                startCity={facts?.business_area}
                                startCountry={facts?.pickup_country}
                                endCity={
                                  englishLanguage
                                    ? singleProduct?.facts?.location_en?.split(",")[0]
                                    : singleProduct?.facts?.location?.split(",")[0]
                                }
                                endCountry={
                                  englishLanguage
                                    ? singleProduct?.facts?.location_en?.split(",")[1]
                                    : singleProduct?.facts?.location?.split(",")[1]
                                }
                                kilometre={singleProduct?.facts?.travel_distance}
                                mapLink={gui?.media?.[0]}
                                referer={facts?.referer}
                              />
                            </div>
                          );

                        case "calendar":
                          return (
                            <div key={idx} style={{ background: "var(--generalBackground)" }}>
                              <DateTimeComponent
                                minDate={facts?.starttime}
                                maxDate={facts?.endtime}
                                daysDuration={
                                  singleProduct?.facts?.days_duration ||
                                  durationFailSafe(
                                    singleProduct?.facts?.days_duration,
                                    facts?.endtime,
                                    facts?.starttime,
                                    singleProduct?.facts?.days_duration_min,
                                    singleProduct?.facts?.days_duration_max
                                  )
                                }
                                daysOffset={singleProduct?.facts?.days_offset || "0"}
                                handleChange={handleChange}
                                getProductFactsGuiValue={getProductFactsGuiValue}
                                allowDatePicker={gui?.editable}
                                isProductPage={true}
                                productDaysDurationMin={singleProduct?.facts?.days_duration_min}
                                productDaysDurationMax={singleProduct?.facts?.days_duration_max}
                                daysAvailable={singleProduct?.facts?.days_available}
                              />
                            </div>
                          );

                        case "number":
                          return (
                            <div
                              key={idx}
                              style={{ background: "var(--generalBackground)", margin: "0 auto", padding: "12px 0" }}
                            >
                              <SectionWrapper key={idx} className="">
                                <AmountButtons
                                  increase={() => {
                                    getNumberOfIndividual(true);
                                  }}
                                  decrease={() => {
                                    getNumberOfIndividual(false);
                                  }}
                                  children={
                                    <>
                                      <Formatted noWrap>{englishLanguage ? gui?.title?.en : gui?.title?.de}</Formatted>:{" "}
                                      {singleProduct?.facts?.count}{" "}
                                    </>
                                  }
                                  referer={facts?.referer}
                                />
                              </SectionWrapper>
                            </div>
                          );

                        case "choice":
                          return (
                            <SectionWrapper key={idx} className="">
                              <div className="fields">
                                <ProductChoice
                                  key={idx}
                                  guis={gui}
                                  referer={facts?.referer}
                                  singleProduct={singleProduct}
                                  getProductFactsGuiValue={getProductFactsGuiValue}
                                />
                              </div>
                            </SectionWrapper>
                          );
                        default:
                          return null;
                      }
                    })
                  )
              )}

              {packageState?.product_groups?.map((product_groups: product_groups) =>
                product_groups?.products
                  ?.filter((singleProduct: products) => singleProduct?.product_id === productIdParams)
                  ?.map((singleProduct: products, idx: number) => (
                    <div
                      key={idx}
                      style={{ background: "var(--generalBackground)", margin: "0 auto", padding: "12px 0" }}
                    >
                      <SectionWrapper key={idx}>
                        <div
                          key={idx}
                          style={{ background: "var(--generalBackground)", margin: "0 auto", padding: "12px 0" }}
                        >
                          <TotalComponent total={singleProduct?.cost} loading={loadingUpdatedPackages} />
                        </div>
                      </SectionWrapper>
                    </div>
                  ))
              )}

              {packageState?.product_groups?.map((product_groups: product_groups) =>
                product_groups?.products
                  ?.filter((singleProduct: products) => singleProduct.product_id === productIdParams)
                  ?.map((singleProduct: products, idx: number) => (
                    <CustomBtnWidth key={idx}>
                      <ButtonSticky>
                        <Button
                          title={
                            singleProduct?.facts?.enabled === "0"
                              ? englishLanguage
                                ? "Add"
                                : "Hinzufügen"
                              : englishLanguage
                              ? "Remove"
                              : "Entfernen"
                          }
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            event.stopPropagation();

                            dispatch(
                              updatePackagesAction(
                                createNewResponseObjectProductGui(
                                  {
                                    packages: [packageState],
                                    combine_packages: true,
                                  },
                                  singleProduct?.product_id,
                                  "enabled",
                                  singleProduct?.facts?.enabled === "0" ? "1" : "0"
                                )
                              )
                            );

                            setPackageState({
                              ...packageState,
                              facts: {
                                ...packageState?.facts,
                                enabled: singleProduct?.facts?.enabled === "0" ? "1" : "0",
                              },
                            });

                            writeBackup("readStore", [packageState]);

                            setTimeout(() => {
                              navigate(-1);
                            }, 500);
                          }}
                        />
                      </ButtonSticky>
                    </CustomBtnWidth>
                  ))
              )}
            </React.Fragment>
          )}
        </ProductWrapper>
      </WhiteBackground>
    </DesktopWrapper>
  );
};

const ProductWrapper = styled.div`
  hr {
    background: var(--hr-);
    height: 2px;
    border: none;
    width: 70%;
    margin: 0.5rem 0 0.5rem auto;
  }

  .customise-heading {
    margin: 1rem 0 1.5rem;
  }

  .fields {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    & > div {
      flex: 1;
      margin: 0 0 16px;
    }
  }

  .iconset {
    background: var(--generalBackground);
    display: flex;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 12px 0;

    @media screen and (min-width: 800px) {
      width: 50vw;
      margin: 0 auto;
    }
  }

  .iconset::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 800px) {
    hr {
      width: 50vw;
    }

    .customise-heading {
      width: 50vw;
      margin: 1rem auto 1.5rem;
    }

    .fields {
      flex-direction: row;

      & > div {
        flex: 0 0 100%;

        &:nth-child(2n - 1) {
          padding-right: 12px;
        }
        &:nth-child(2n) {
          padding-left: 12px;
        }
      }
    }
  }
`;

export default ProductPageV2;
