// location map poi type definitions
//

// the poiTypes definition is a list of records of
//  key:   the poi type string used in the `location_poi` product fact
//  glyph: the glyph in the "Material Icons" font, used as label text
//         in a google maps marker
//
// Besides its front-end use, this file is read when building
// the cmd/admin tool.
// Therefore:
// 1. do not change the formatting
// 2. when changing the content, call `go generate` in cmd/admin
//    and commit the changes.
// 2a. also update the poi type section in the README.md of platform/basedata
// 3. when changing the path, update the reference in cmd/admin/poi_types.go
//
export const poiTypes = [
    { key: "hiking",              glyph: "\ue50a" },
    { key: "skiing",              glyph: "\ue509" },
    { key: "cycling",             glyph: "\ue52f" },
    { key: "e_bike",              glyph: "\ueb1b" },
    { key: "golfing",             glyph: "\ueb45" },
    { key: "e_sports",            glyph: "\uea28" },
    { key: "relaxing",            glyph: "\uea78" },
    { key: "climbing",            glyph: "\ue3f7" },
    { key: "nature",              glyph: "\uea99" },
    { key: "shop",                glyph: "\uea12" },
    { key: "hotel",               glyph: "\uefdf" },
    { key: "laundry",             glyph: "\ue54a" },
    { key: "event",               glyph: "\ue53f" },
    { key: "restaurant",          glyph: "\ue56c" },
    { key: "reference_location",  glyph: "\ue153" },
    { key: "flag",                glyph: "\ue153" },
    { key: "parking",             glyph: "\ue54f" },
    { key: "ev_charging",         glyph: "\ue56d" },
    { key: "luggage",             glyph: "\uf235" },
    { key: "garage",              glyph: "\uf011" },
    { key: "warehouse",           glyph: "\uebb8" },
    { key: "factory",             glyph: "\uebbc" },
    { key: "repair",              glyph: "\ue869" },
    { key: "train",               glyph: "\ue570" },
    { key: "car",                 glyph: "\ue531" },
    { key: "car_rental",          glyph: "\uea55" },
    { key: "taxi",                glyph: "\ue559" },
    { key: "boat",                glyph: "\ue532" },
    { key: "shuttle",             glyph: "\ueb3c" },
    { key: "scooter",             glyph: "\ueb1f" },
    { key: "plane",               glyph: "\ue539" },
]
