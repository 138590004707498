//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is an error page called when there is a problem with the session id, route,
// or expired Jwt
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";
import { Hero, Button } from "../components/index";
import { useNavigate } from "react-router-dom";
import { CustomBtnWidth, DesktopWrapper, WhiteBackground } from "../Utils/styles";
import { RootState } from "../State/store";
import { useSelector } from "react-redux";
import { Error } from "../components";
import { envStaticHost } from "../Utils/windowHelper";

const ErrorScreen = () => {
  let navigate = useNavigate();

  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  return (
    <DesktopWrapper>
      <WhiteBackground>
        <Wrapper>
          <Hero
            showCloseBar={true}
            navigationRoute={() => navigate(-1)}
            showLanguageBar={false}
            vehicle={facts.vehicle}
            defaultBackdropAction={false}
            cityBackdropAction={true}
            business_area={facts.business_area}
            isFinalOverviewPage={false}
            isLandingPage={false}
            referer={facts.referer}
            facts={facts}
          />
          <Error
            errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_1.png`}
            headerText={englishLanguage ? "Page not found" : "Seite nicht gefunden"}
            bodyText={
              englishLanguage ? "Please go back and try again." : "Bitte gehen Sie zurück und versuchen Sie es erneut."
            }
          />
          <CustomBtnWidth>
            <Button
              title={englishLanguage ? "Go Back" : "zurückgehen"}
              onClick={() => {
                //resetLocalStorage();
                navigate("/");
              }}
            />
          </CustomBtnWidth>
        </Wrapper>
      </WhiteBackground>
    </DesktopWrapper>
  );
};

const Wrapper = styled.section`
  height: 80vh;

  .activityLower {
    font-size: 1rem;
  }
`;
export default ErrorScreen;
