// ************************************************************

// send page back to top function
export const backToTop = () => {
  const body = document.querySelector("#root");

  body?.scrollIntoView({
    behavior: "smooth",
  });
};
// ************************************************************

// ************************************************************
// format price function
export const formatPrice = (number: number, en: boolean) => {
  const newNumber = Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(number / 100);

  if (number === 0) {
    return en ? "Free" : "Gratis";
  }

  return newNumber;
};
// ************************************************************

interface guis {
  editable: boolean;
  fact_name: string;
  inc: number;
  max: number;
  min: number;
  required: boolean;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  widget: string;
  media: string[];
}

interface products {
  cost: number;
  currency: string;
  facts: any;
  guis: guis[];
  latest_cancel: string;
  product_id: string;
  cost_net: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

// eslint-disable-next-line
interface package_facts {
  activity?: string | number;
  adults?: string;
  age_youngest?: string;
  agreetc?: string;
  children?: string;
  business_area?: string;
  endtime?: string;
  pickup_city?: string;
  pickup_country?: string;
  pickup_postcode?: string;
  pickup_street?: string;
  referer?: string /* sharenow */;
  starttime?: string;
  vehicle?: string;
  title?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  home_country?: string;
  home_country_code?: string;
  home_region?: string;
  home_city?: string;
  home_post_code?: string;
  home_street?: string;
  home_message?: string;
  billing_country?: string;
  billing_country_code?: string;
  billing_region?: string;
  billing_city?: string;
  billing_post_code?: string;
  billing_street?: string;
  billing_message?: string;
}

interface packageProps {
  package_id?: string;
  product_groups?: product_groups[];
  categories?: categories[];
  facts?: any;
  guis?: guis[];
}

// interface packagesArr {
//   packages: {
//     package_id?: string;
//     product_groups?: product_groups[];
//     categories?: categories[];
//     facts?: any;
//     guis?: guis[];
//   }[];
// }

// ************************************************************
interface Fact {
  count: string; // Assuming count is stored as a string
}

interface Product {
  product_id: string;
  facts: Fact;
}

interface ProductGroup {
  products: Product[];
}

interface Package {
  product_groups: ProductGroup[];
}

interface Packages {
  packages: Package[];
  combine_packages: boolean;
}

export const createNewResponseObjectNumber = (
  packages: Packages,
  productIdParams: string | undefined,
  addOrMinusCount: boolean
): Packages => {
  let packageIndex: number | undefined, productGroupIndex: number | undefined, productIndex: number | undefined;

  // Find the indexes
  packages.packages.some((singlePackage, pIndex) => {
    return singlePackage.product_groups.some((productGroup, pgIndex) => {
      const pIdx = productGroup.products.findIndex((product) => product.product_id === productIdParams);
      if (pIdx !== -1) {
        packageIndex = pIndex;
        productGroupIndex = pgIndex;
        productIndex = pIdx;
        return true; // Stop searching once found
      }
      return false;
    });
  });

  if (packageIndex === undefined || productGroupIndex === undefined || productIndex === undefined) {
    // Product not found, return original packages or handle error
    return packages;
  }

  // Deep clone the packages up to the product to be manipulated to avoid mutating the original object
  const manipulatedPackages: Packages = JSON.parse(JSON.stringify(packages));

  // Direct reference to the target product's facts for easier manipulation
  const targetFacts =
    manipulatedPackages.packages[packageIndex].product_groups[productGroupIndex].products[productIndex].facts;

  // Convert count to number, adjust it, and convert back to string as needed
  let count = parseInt(targetFacts.count);
  if (addOrMinusCount) {
    count += 1;
  } else {
    count = Math.max(count - 1, 1); // Ensure count does not go below 1
  }
  targetFacts.count = count.toString();

  return manipulatedPackages;
};

// ************************************************************

// ************************************************************
// Response Object responsible for updating selected
// packages on the package screen
interface categories {
  en?: string;
}

// eslint-disable-next-line
interface createNewObjectProps {
  packages: {
    package_id: string;
    product_groups: product_groups[];
    categories?: categories[];
    facts?: any;
    guis?: guis[];
  }[];
}

export const createNewResponseObjectProductGui = (
  packages: any,
  productIdParams: string | undefined,
  fact_name: string,
  eTargetValue: string
) => {
  let PackageIndex: number = 0;

  let productGroupIndex: number = 0;

  let productIndex: number = 0;

  // get the indexes to get the path to the product whose facts should be manipulated

  packages?.packages?.map((singlePackage: packageProps, PKIndex: number) => {
    return singlePackage?.product_groups?.forEach((productGroup: product_groups, PGIndex: number) => {
      return productGroup?.products?.forEach((product: products, PIndex: number) => {
        if (product?.product_id === productIdParams) {
          PackageIndex = PKIndex;

          productGroupIndex = PGIndex;

          productIndex = PIndex;
        }
      });
    });
  });

  // make a copy of the old world - the old packages

  let manipulatedPackages = { ...packages };

  // manipulate the facts of the produt of the product group

  manipulatedPackages.packages[PackageIndex].product_groups[productGroupIndex].products[productIndex].facts[fact_name] =
    eTargetValue;

  // return the object

  return manipulatedPackages;
};
// ************************************************************

// ************************************************************
interface updatePackagesArr {
  packages: {
    package_id?: string;
    product_groups?: product_groups[];
    categories?: categories[];
    facts?: any;
    guis?: guis[];
  }[];
  combine_packages: boolean;
}

export const clearAll = (packages: updatePackagesArr) => {
  packages?.packages?.map((singlePackage: packageProps, PKIndex: number) => {
    return singlePackage?.product_groups?.map((productGroup: product_groups, PGIndex: number) => {
      return productGroup?.products?.map((product: products, PIndex: number) => {
        return (product.facts.enabled = "0");
      });
    });
  });

  // make a copy of the old world - the old packages
  let manipulatedPackages = { ...packages };

  return manipulatedPackages;
};
// ************************************************************

export const createNewResponseObjectPackageFacts = (packages: any, packageId: string) => {
  let PackageIndex: any;

  // get the indexes to get the path to the product whose facts should be manipulated
  packages?.packages?.map(
    (singlePackage: packageProps, PKIndex: number) =>
      singlePackage?.package_id === packageId && (PackageIndex = PKIndex)
  );

  // make a copy of the old world - the old packages
  let manipulatedPackages = { ...packages };

  // manipulate the facts of the produt of the product group
  manipulatedPackages.packages[PackageIndex].facts.enabled =
    manipulatedPackages.packages[PackageIndex].facts.enabled === "0" ? "1" : "0";

  // console.log("manipulatedPack", manipulatedPackages.packages[PackageIndex].facts.enabled);

  // return the object
  return manipulatedPackages;
};

// **** BACK UP CALL***** /
export const writeBackup = (key: string, value: packageProps[]) => {
  localStorage.setItem(key, JSON.stringify(value));
};

// clear local storage but keep the precious cookie facts
export const resetLocalStorage = () => {
  const cookiefacts = localStorage.getItem("cookiefacts") as string;
  localStorage.clear();
  localStorage.setItem("cookiefacts", cookiefacts);
};
