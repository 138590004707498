//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this page calls one endpoint, and also handles the agree terms and condition
// for each product. Until the terms are accepted, the end user cannot move
// forward
//
// POST /v1/portal/register-payment-source-id
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../State/store";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router";
import { registerPayerAction, confirmBookingAction } from "../State/ActionCreators";
import { Error, ButtonLoading } from "../components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "./index";
import { CustomBtnWidth } from "../Utils/styles";
import { InputElement } from "./InputElement";
import { FiCopy } from "react-icons/fi";
import { envStaticHost, envStripeKey, getPartnerConfiguration } from "../Utils/windowHelper";

interface registerPayerProp {
  booking_id: string;
  payment_source_id: any /* payment menthod id */;
}

const stripePromise = loadStripe(envStripeKey);

const StripeCheckout = () => {
  let navigate = useNavigate();

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  /* Checking if the envURL is a demo environment. */
  // const demoEnvironment = envURL?.substring(8, 12) === "demo" || envURL?.substring(8, 12) === "stag";

  const showPayBg = getPartnerConfiguration(facts.referer).SHOW_PAYMENTPAGE_BG;

  return (
    <>
      {showPayBg && (
        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", zIndex: -1000 }}>
          <img
            src={`${envStaticHost}/static/${facts?.referer}/banner/pay.png`}
            alt="banner"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      )}

      <CloseBtn>
        <img
          src={`${envStaticHost}/static/${facts?.referer}/icon/black-round-backarrow.svg`}
          alt=""
          className="closeIcon"
          onClick={() => navigate(-1)}
        />
        {/* demo UI */}
        {/* {demoEnvironment && (
          <div className="demo-icon">
            <img src={`${envStaticHost}/static/${facts?.referer}/icon/black-round-demo.svg`} alt="demo" />
          </div>
        )} */}
        {/* demo UI */}
      </CloseBtn>

      <div style={{ paddingBottom: "16px" }} className="section-center">
        <PayInfoText>
          <div className={facts?.referer}>{englishLanguage ? "Payment Information" : "Zahlungsinformationen"}</div>
        </PayInfoText>
      </div>

      <Wrapper>
        <PaymentIcons>
          <div>
            <img src={`${envStaticHost}/static/${facts?.referer}/payment/MasterCard.png`} alt="mastercard" />
          </div>
          <div>
            <img src={`${envStaticHost}/static/${facts?.referer}/payment/Visa.png`} alt="visacard" />
          </div>
          <div>
            <img src={`${envStaticHost}/static/${facts?.referer}/payment/AMEX.png`} alt="amex" />
          </div>
        </PaymentIcons>
        {/* {demoEnvironment && <DemoCard />} */}

        <Elements
          stripe={stripePromise}
          options={{
            fonts: [
              {
                family: `var(--p-regular)`,
                src: `url('${envStaticHost}/static/font/AsteriskSansPro-Regular.otf')`,
              },
              {
                family: `var(--p-regular-italic)`,
                src: `url('${envStaticHost}/static/font/AsteriskSansPro-RegularItalic.otf')`,
              },
            ],
          }}
        >
          <CheckoutForm facts={facts} />
        </Elements>
      </Wrapper>
    </>
  );
};

const PaymentIcons = styled.div`
  display: flex;
  margin-bottom: 24px;

  @media screen and (min-width: 800px) {
    margin-bottom: 21px;
    margin-top: 0;
    padding-top: 5px;
  }

  & > div {
    flex: 0;
    border: 1px solid var(--icon-boarder);
    border-radius: var(--radius-6px);
    padding: 1px;
    height: 50px;
    margin-right: 10px;

    img {
      height: 100%;
    }
  }
`;

const CloseBtn = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;

  .closeIcon {
    margin: 15px;
    width: 7%;
    /* font-size: 1.7rem; */
    /* color: black; */
  }

  .demo-icon {
    margin: 15px;
    height: 22px;
    width: 58px;
    background: black;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 14px;
      color: white;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 15px;

  @media screen and (min-width: 800px) {
    width: 50vw;
    margin: 0 auto;
    padding: 0;
  }
`;

const PayInfoText = styled.h1`
  width: 80%;
  padding-left: 0;
  text-transform: capitalize;
  color: var(--clr-summary-primary-1);

  .manx {
    font-family: "FormulaCondensed Bold";
    font-size: 40px;
  }

  .etravel_mercedes,
  .etravel_geo {
    font-family: "CorpoA";
    font-size: 40px;
    color: white;
    text-transform: capitalize;
  }

  @media screen and (min-width: 800px) {
    width: 50vw;
    margin: 0px auto;
  }
`;

interface Facts {
  [key: string]: string;
}

const CheckoutForm = ({ facts }: { facts: Facts }) => {
  const [isCopied, setIsCopied] = useState(false);
  const skipConfirmation = !getPartnerConfiguration(facts.referer).USE_CONFIRMATION_PAGE;

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { packageid: packageIdParams } = useParams<{ packageid?: string }>();

  /* Destructuring the bookingId from the getCreateBooking object. */
  const getCreateBooking = useSelector((state: RootState) => {
    return state.getCreateBooking;
  });
  const { bookingId: booking_id }: { bookingId: string } = getCreateBooking;

  const [mockCardNumber, mockExpiryDate, mockCvv] = facts?.credit_card?.split(",") || [];

  /* Destructuring the state object. */
  const getRegisterPay = useSelector((state: RootState) => {
    return state.getRegisterPayer;
  });

  let {
    error: errorRegisterPay,
    success: successRegisterPay,
    loading: loadingRegisterPay,
  }: {
    error: boolean;
    loading: boolean;
    success: boolean;
    pay_id: string;
  } = getRegisterPay;

  /* Using the useSelector hook to get the customerEmail from the getInformationDetails reducer. */
  const getInformationDetails = useSelector((state: RootState) => {
    return state.getInformationDetails;
  });

  const { customerEmail } = getInformationDetails;

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = facts.language === "en";

  const [cardHolder, setCardHolder] = useState<string>("");
  const [cardNumberComplete, setCardNumberComplete] = useState<boolean>(false);
  const [cardCvcComplete, setCardCvcComplete] = useState<boolean>(false);
  const [cardExpiryComplete, setCardExpiryComplete] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [succeeded, setSucceeded] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: FormEvent) => {
    setProcessing(true);
    e.preventDefault();

    if (!stripe || !elements || !cardHolder || !cardNumberComplete || !cardCvcComplete || !cardExpiryComplete) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement)!,
      billing_details: {
        name: cardHolder,
        email: customerEmail,
      },
    });

    /* Creating a new object with the booking_id and payment_source_id. */
    const reqObject: registerPayerProp = {
      booking_id,
      payment_source_id: paymentMethod?.id,
    };

    if (error) {
      setError(`${error?.message}`);
      setProcessing(false);
    } else {
      dispatch(registerPayerAction(reqObject));
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  /* The above code is setting the style for the input field. */

  const generalStyle = {
    base: {
      lineHeight: "50px",
      color: `var(--clr-summary-primary-1)`,
      fontFamily: `var(--p-regular)`,
      fontSize: "14px",
      padding: "0 38px",
      "::placeholder": {
        fontFamily: `var(--p-regular-italic)`,
        color: `var(--clr-placeholder)`,
        fontSize: "14px",
      },
    },
  };
  const eqsInput = {
    base: {
      lineHeight: "50px",
      color: `var(--clr-summary-primary-1)`,
      fontFamily: `var(--p-regular)`,
      fontSize: "14px",
      padding: "0 38px",
      "::placeholder": {
        fontFamily: `var(--p-regular-italic)`,
        color: `lightgrey`,
        fontSize: "14px",
      },
    },
  };

  const vwInput = {
    base: {
      lineHeight: "50px",
      color: "#ffffff",
      fontFamily: `var(--p-regular)`,
      fontSize: "14px",
      padding: "0 38px",
      "::placeholder": {
        fontFamily: `var(--p-regular-italic)`,
        color: "#ffffff",
        fontSize: "14px",
      },
    },
  };

  const manxInput = {
    base: {
      lineHeight: "50px",
      color: "#FF2900",
      fontFamily: `var(--p-regular)`,
      fontSize: "14px",
      padding: "0 38px",
      "::placeholder": {
        fontFamily: `var(--p-regular-italic)`,
        color: "#FF2900",
        fontSize: "14px",
      },
    },
  };

  const sharenowInput = {
    base: {
      lineHeight: "50px",
      color: `var(--clr-summary-primary-1)`,
      fontFamily: `var(--p-regular)`,
      fontSize: "14px",
      padding: "0 38px",
      "::placeholder": {
        fontFamily: `var(--p-regular-italic)`,
        color: `var(--clr-placeholder)`,
        fontSize: "14px",
      },
    },
  };

  const miniInput = {
    base: {
      lineHeight: "50px",
      color: `var(--clr-summary-primary-1)`,
      fontFamily: `var(--p-regular)`,
      fontSize: "14px",
      padding: "0 38px",
      "::placeholder": {
        fontFamily: `var(--p-regular-italic)`,
        color: `var(--clr-placeholder)`,
        fontSize: "14px",
      },
    },
  };

  const audiInput = {
    base: {
      lineHeight: "50px",
      color: `var(--clr-summary-primary-1)`,
      fontFamily: `var(--p-regular)`,
      fontSize: "14px",
      padding: "0 38px",
      "::placeholder": {
        fontFamily: `var(--p-regular-italic)`,
        color: `#cccccc`,
        fontSize: "12px",
      },
    },
  };

  const styleMappings: any = {
    manx: manxInput,
    etravel_mercedes: eqsInput,
    etravel_audi: audiInput,
    sharenow: sharenowInput,
    mini: miniInput,
    volkswagen: vwInput,
  };

  const style = facts?.referer && styleMappings[facts.referer] ? styleMappings[facts.referer] : generalStyle;

  // needs refactor
  // error in registerpay in redux needs to be switched to false
  // instead of going back home
  // we do not want to send users back home after failed payment
  if (errorRegisterPay) {
    return (
      <>
        <Error
          errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_4.png`}
          headerText={
            englishLanguage
              ? "An error occurred during your payment submission."
              : "Leider ist bei der Übermittlung Deiner Zahlung ein Fehler aufgetreten."
          }
          bodyText={
            englishLanguage
              ? "Please go back to the home page and try again. If a payment was debited from your account, please contact us immediately"
              : "Gehe zur Startseite und versuche es erneut. Falls eine Zahlung bei Dir abgebucht wurde, setze Dich umgehend mit uns in Verbindung"
          }
          aTag="support.live@banbutsu.com"
        />

        <CustomBtnWidth>
          <Button
            title={englishLanguage ? "Go back" : "Zur Startseite"}
            onClick={() => {
              navigate(`/`);
            }}
          />
        </CustomBtnWidth>
      </>
    );
  }

  /* The above code is a React component that is being rendered when the payment is successful.
  and there are no errors in registring a payment
  */
  if (succeeded && successRegisterPay && !errorRegisterPay) {
    if (skipConfirmation) {
      dispatch(confirmBookingAction({ booking_id: booking_id }));
      setTimeout(() => {
        navigate(`/experience/${facts?.referer}/packages/${packageIdParams}/final-overview`);
      }, 500);
    } else {
      setTimeout(() => {
        navigate(`/experience/${facts?.referer}/packages/${packageIdParams}/confirmation`);
      }, 500);
    }

    return (
      <>
        <article>
          <h5>Thank You</h5>
          <h5>Your Payment was successful</h5>
        </article>
      </>
    );
  }

  /**
   * If the language is English and the loadingRegisterPay is false, return the string "Checkout"
   * @returns The buttonState function is being returned.
   */
  const buttonState = () => {
    if (englishLanguage && !loadingRegisterPay) return "Checkout";

    if (!englishLanguage && !loadingRegisterPay) return "Bezahlen";

    if (loadingRegisterPay) return <ButtonLoading />;

    return "checkout";
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardWrapper>
        {mockCardNumber && (
          <CopyToClipboard text={mockCardNumber} onCopy={() => setIsCopied(true)}>
            <p style={{ color: "red", padding: "8px 0" }}>
              mock card number: {mockCardNumber}
              <FiCopy style={{ color: isCopied ? "green" : "black", margin: "0 10px" }} />
            </p>
          </CopyToClipboard>
        )}
        <label>
          {" "}
          <h5 style={{ color: "var(--clr-summary-primary-1)" }}>{englishLanguage ? "Card Number" : "Kartennummer"}</h5>
        </label>

        <InputWrapper>
          <CardNumberElement
            options={{
              style,
              showIcon: false,
            }}
            onChange={({ complete }) => setCardNumberComplete(complete)}
          />
        </InputWrapper>

        <label>
          {" "}
          <h5 style={{ color: "var(--clr-summary-primary-1)" }}>
            {englishLanguage ? "Cardholder Name" : "Karteninhaber"}
          </h5>
        </label>

        <InputWrapper>
          <InputElement
            value={cardHolder}
            onChange={({ target: { value } }) => setCardHolder(value || "")}
            placeholder={englishLanguage ? "John Doe" : "Max Mustermann"}
          />
        </InputWrapper>

        {mockExpiryDate && (
          <p style={{ color: "red", padding: "8px 0" }}>
            mock expiry: {mockExpiryDate} <span style={{ paddingLeft: "14px" }}></span> mock cvv: {mockCvv}
          </p>
        )}
        <div className="card__details">
          <div>
            <label>
              <h5 style={{ color: "var(--clr-summary-primary-1)" }}>
                {englishLanguage ? "Expiration Date" : "Ablaufdatum"}
              </h5>
            </label>

            <InputWrapper>
              <CardExpiryElement options={{ style }} onChange={({ complete }) => setCardExpiryComplete(complete)} />
            </InputWrapper>
          </div>
          <div>
            <label>
              <h5 style={{ color: "var(--clr-summary-primary-1)" }}>CVV</h5>
            </label>

            <InputWrapper>
              <CardCvcElement
                options={{
                  style,
                  placeholder: "***",
                }}
                onChange={({ complete }) => setCardCvcComplete(complete)}
              />
            </InputWrapper>
          </div>
        </div>
        {error && (
          <div className="card__error">
            The payment for this item didn't go through.
            <br />
            <span>{error}</span>
          </div>
        )}
        <CustomBtnWidth className="card__btn">
          <Button
            title={buttonState()}
            type="submit"
            disable={
              !stripe || !cardHolder || !cardCvcComplete || !cardExpiryComplete || !cardNumberComplete || processing
            }
            inactive={
              !(!stripe || !cardHolder || !cardCvcComplete || !cardExpiryComplete || !cardNumberComplete || processing)
            }
          />
        </CustomBtnWidth>
      </CardWrapper>
    </form>
  );
};

const InputWrapper = styled.div`
  position: relative;
  height: 50px;
  margin: 8px 0 21px;

  .InputElement {
    border: var(--input-element-border);
    border-radius: var(--input-element-border-radius);
    border-bottom: var(--input-element-border-bottom);

    &--focus {
      border-color: var(--clr-secondary-1);
      color: var(--clr-summary-primary-1);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 3px solid #cfdce2;
        border-radius: 12px;
      }
    }
  }

  .StripeElement {
    background: none;
    height: 50px;
    line-height: 50px;
    padding: 0 16px;
    width: 100%;
    outline: none;
    font-size: 14px;
    border: var(--input-element-border);
    border-radius: var(--input-element-border-radius);
    border-bottom: var(--input-element-border-bottom);
    position: relative;

    input {
      font-family: var(--p-regular);
      border: 0;
      height: 100%;
      width: 100%;
      display: block;
      background: none;
      /* color: var(--clr-summary-primary-1); */

      &:focus-visible {
        outline: none;
      }

      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder {
        font-family: var(--p-regular-italic);
        color: var(--clr-summary-primary-1);
      }
    }

    &--focus {
      border-color: var(--clr-secondary-1);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 3px solid #cfdce2;
        border-radius: 12px;
      }
    }
  }
`;

const CardWrapper = styled.div`
  label {
    background: var(--clr-white);
    line-height: 15px;
    color: #677687;
    padding: 0;
    margin-bottom: 1rem;

    h5 {
      font-family: var(--p-bold);
      /* color: var(--clr-summary-primary-1); */
    }
  }

  .card {
    &__btn {
      padding: 0;
    }

    &__error {
      background: var(--clr-red);
      border-radius: 8px;
      padding: 15px;
      font-family: var(--p-regular);
      color: var(--clr-white);
      margin-bottom: 15px;

      span {
      }
    }

    &__details {
      display: flex;

      div {
        flex: 1;

        &:first-child {
          flex: 2;

          & > div {
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

export default StripeCheckout;
