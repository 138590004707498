import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../State/store";
import { useNavigate } from "react-router-dom";
import { envStaticHost } from "../Utils/windowHelper";

interface guisChoice {
  de?: string;
  en?: string;
  value?: string;
}

interface guis {
  editable?: boolean;
  fact_name?: string;
  inc?: number;
  max?: number;
  min?: number;
  required?: boolean;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  widget?: string;
  media: string[];
  choice: guisChoice[];
  class: string;
}

const ProductLabel: React.FC<guis> = ({ ...gui }) => {
  /* A hook that allows you to navigate to a different page. */
  let navigate = useNavigate();

  /* Using the useSelector hook to get the language from the store. */
  const stateFacts = useSelector((state: RootState) => {
    return state.facts;
  });

  const { referer, language } = stateFacts;

  const englishLanguage = language === "en";

  return (
    <Wrapper>
      <div className={referer}>
        <section className={gui?.class}>
          <div className="closeBtnContainer">
            <img
              src={`${envStaticHost}/static/${referer}/icon/black-round-x.svg`}
              className="closeIcon"
              onClick={() => navigate(-1)}
              alt="close"
            />
          </div>

          <div className="container">
            <div className="image-logo-container">
              {gui?.media && (
                <>
                  <img
                    src={gui?.media?.[2] || gui?.media?.[0]}
                    alt="gui"
                    className="media_1"
                  />

                  {referer !== "manx" && referer !== "etravel_mercedes" && (
                    <div className="logo-container">
                      <img
                        src={gui?.media?.[1]}
                        alt="partner"
                        className="logo"
                      />
                    </div>
                  )}

                  <div className="gradient" />
                </>
              )}
              <div className="white" />
            </div>

            <div className="text-container">
              {gui?.title && (
                <h1
                  dangerouslySetInnerHTML={{
                    __html: englishLanguage ? gui?.title?.en : gui?.title?.de,
                  }}
                />
              )}

              {gui?.text && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: englishLanguage ? gui?.text?.en : gui?.text?.de,
                  }}
                />
              )}
            </div>
            <br />
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: 800px) {
    width: 60vw;
    margin: 0;
  }

  .etravel_mercedes,
  .etravel_geo {
    background: var(--inverse-background);
    padding: 0px 0px 12px 0px;

    .hero-indent {
      width: 100vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 60vw;
        margin: 0;
      }

      .closeBtnContainer {
        position: absolute;
        top: 0;
        z-index: 3;

        .closeIcon {
          margin: 1rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .container {
        padding-bottom: 36px;
        h1 {
          font-family: "FormulaCondensed Bold";
        }

        .image-logo-container {
          width: 100%;
          position: relative;
          overflow: hidden;
          height: 285px;

          .gradient {
            background: var(--blend);
            /* background: linear-gradient(180deg, transparent 20%, rgb(255, 242, 233) 100%); */
            z-index: 1;
            height: 100%;
            width: 100vw;
            position: absolute;
            top: 0;
            /** */
            /* border-bottom-left-radius: 75px; */

            @media screen and (min-width: 800px) {
              width: 60vw;
            }
          }

          .white {
          }

          .media_1 {
            width: 100%;
            height: 100%;
            margin: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            object-fit: cover;
            z-index: 1;

            /** */
            /* border-bottom-left-radius: 75px; */
          }

          .logo-container {
            position: absolute;
            width: 55%;
            margin-left: 12px;
            top: 50%;
            left: 0;
            transform: (0% 50%);
            z-index: 2;
            height: 65%;
          }

          @media screen and (min-width: 800px) {
          }

          .logo {
            width: 90%;
            height: 100%;
            filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(328deg)
              brightness(106%) contrast(100%);
            z-index: 2;

            @media screen and (min-width: 600px) {
              width: 20rem;
            }
          }
        }
      }

      .text-container {
        width: 90vw;
        margin: 0 auto;

        @media screen and (min-width: 800px) {
          width: 50vw;
        }

        h1 {
          font-size: 2rem;
          margin-bottom: 24px;
          color: var(--clr-primary-1);
          font-family: var(--headerText);
          /* text-transform: uppercase; */
        }

        p {
          line-height: 26px;
          color: var(--clr-black);
          font-size: 1rem;

          strong {
            color: var(--clr-secondary-3);
          }
        }
      }
    }

    /* LARGE-INDENT */
    .large-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 36px;
        /* margin-bottom: 36px; */

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            width: 2rem;
            filter: var(--iconset-filter);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--inverse-text);
            margin-bottom: 1rem;
            font-size: 18px;
          }

          p {
            font-size: 14px;
            line-height: 26px;
            color: var(--clr-black);

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }

    /* SMALL INDENT */
    .small-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0 0.5rem 0 0;

            filter: brightness(0) saturate(100%) invert(19%) sepia(90%)
              saturate(1544%) hue-rotate(173deg) brightness(96%) contrast(103%);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--inverse-text);
            margin-bottom: 1rem;
            font-size: 14px;
          }
        }
      }
    }

    /* paragraph */
    .paragraph {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* margin-bottom: 24px; */

        .image-logo-container {
          display: none;
        }

        .text-container {
          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            font-size: 1.45rem;
            margin: 36px 0 1rem;
            color: var(--inverse-text);
            text-transform: capitalize;
          }

          p {
            font-size: 1rem;
            line-height: 26px;
            color: var(--clr-black);

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }
  }

  .manx {
    background: var(--inverse-background);
    padding: 0px 0px 12px 0px;

    .hero-indent {
      width: 100vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 60vw;
        margin: 0;
      }

      .closeBtnContainer {
        position: absolute;
        top: 0;
        z-index: 3;

        .closeIcon {
          margin: 1rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .container {
        padding-bottom: 36px;
        h1 {
          font-family: "FormulaCondensed Bold";
        }
        /* background: var(--generalBackground); */

        .image-logo-container {
          width: 100%;
          position: relative;
          overflow: hidden;
          height: 285px;

          .gradient {
            background: linear-gradient(
              180deg,
              transparent 0%,
              rgb(255, 242, 233) 100%
            );
            z-index: 1;
            height: 100%;
            width: 100vw;
            position: absolute;
            top: 0;
            /** */
            /* border-bottom-left-radius: 75px; */

            @media screen and (min-width: 800px) {
              width: 60vw;
            }
          }

          .white {
          }

          .media_1 {
            width: 100%;
            height: 100%;
            margin: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            object-fit: cover;
            z-index: 1;

            /** */
            /* border-bottom-left-radius: 75px; */
          }

          .logo-container {
            position: absolute;
            width: 55%;
            margin-left: 12px;
            top: 50%;
            left: 0;
            transform: (0% 50%);
            z-index: 2;
            height: 65%;
          }

          @media screen and (min-width: 800px) {
          }

          .logo {
            width: 90%;
            height: 100%;
            filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(328deg)
              brightness(106%) contrast(100%);
            z-index: 2;

            @media screen and (min-width: 600px) {
              width: 20rem;
            }
          }
        }
      }

      .text-container {
        width: 90vw;
        margin: 0 auto;

        @media screen and (min-width: 800px) {
          width: 50vw;
        }

        h1 {
          font-size: 2rem;
          margin-bottom: 24px;
          color: var(--inverse-text);
          text-transform: uppercase;
        }

        p {
          line-height: 26px;
          color: var(--inverse-text);
          font-size: 1rem;

          strong {
            color: var(--clr-secondary-3);
          }
        }
      }
    }

    /* LARGE-INDENT */
    .large-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 36px;
        /* margin-bottom: 36px; */

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            width: 2rem;
            filter: var(--iconset-filter);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--inverse-text);
            margin-bottom: 1rem;
            font-size: 18px;
          }

          p {
            font-size: 14px;
            line-height: 26px;
            color: var(--inverse-text);

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }

    /* SMALL INDENT */
    .small-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0 0.5rem 0 0;

            filter: brightness(0) saturate(100%) invert(19%) sepia(90%)
              saturate(1544%) hue-rotate(173deg) brightness(96%) contrast(103%);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--inverse-text);
            margin-bottom: 1rem;
            font-size: 14px;
          }
        }
      }
    }

    /* paragraph */
    .paragraph {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* margin-bottom: 24px; */

        .image-logo-container {
          display: none;
        }

        .text-container {
          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            font-size: 1.45rem;
            margin: 36px 0 1rem;
            color: var(--inverse-text);
            text-transform: capitalize;
          }

          p {
            font-size: 1rem;
            line-height: 26px;
            color: var(--inverse-text);

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }
  }

  .volkswagen {
    background: var(--inverse-background);
    padding: 0px 0px 12px 0px;

    .hero-indent {
      width: 100vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 60vw;
        margin: 0;
      }

      .closeBtnContainer {
        position: absolute;
        top: 0;
        z-index: 3;

        .closeIcon {
          margin: 1rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .container {
        padding-bottom: 36px;
        /* background: var(--generalBackground); */

        .image-logo-container {
          width: 100%;
          position: relative;
          overflow: hidden;
          height: 285px;

          .gradient {
            background: linear-gradient(
              180deg,
              transparent 0%,
              rgba(6, 31, 77, 1) 100%
            );
            z-index: 1;
            height: 100%;
            width: 100vw;
            position: absolute;
            top: 0;
            /** */
            /* border-bottom-left-radius: 75px; */

            @media screen and (min-width: 800px) {
              width: 60vw;
            }
          }

          .white {
          }

          .media_1 {
            width: 100%;
            height: 100%;
            margin: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            object-fit: cover;
            z-index: 1;
            /** */
            /* border-bottom-left-radius: 75px; */
          }

          .logo-container {
            position: absolute;
            width: 55%;
            margin-left: 12px;
            top: 50%;
            left: 0;
            transform: (0% 50%);
            z-index: 2;
            height: 65%;
          }

          @media screen and (min-width: 800px) {
          }

          .logo {
            width: 90%;
            height: 100%;
            filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(328deg)
              brightness(106%) contrast(100%);
            z-index: 2;

            @media screen and (min-width: 600px) {
              width: 20rem;
            }
          }
        }
      }

      .text-container {
        width: 90vw;
        margin: 0 auto;

        @media screen and (min-width: 800px) {
          width: 50vw;
        }

        h1 {
          font-size: 2rem;
          margin-bottom: 24px;
          color: var(--inverse-text);
          text-transform: uppercase;
        }

        p {
          line-height: 26px;
          color: var(--inverse-text);
          font-size: 1rem;

          strong {
            color: var(--clr-secondary-3);
          }
        }
      }
    }

    /* LARGE-INDENT */
    .large-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 36px;
        /* margin-bottom: 36px; */

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            width: 2rem;
            filter: var(--clr-secondary-1-filter);
            /* filter: var(--clr-primary-1-filter); */
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--inverse-text);
            margin-bottom: 1rem;
            font-size: 18px;
          }

          p {
            font-size: 14px;
            line-height: 26px;
            color: var(--inverse-text);

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }

    /* SMALL INDENT */
    .small-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0 0.5rem 0 0;
            filter: brightness(0) saturate(100%) invert(19%) sepia(90%)
              saturate(1544%) hue-rotate(173deg) brightness(96%) contrast(103%);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--inverse-text);
            margin-bottom: 1rem;
            font-size: 14px;
          }
        }
      }
    }

    /* paragraph */
    .paragraph {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        /* margin-bottom: 24px; */

        .image-logo-container {
          display: none;
        }

        .text-container {
          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            font-size: 1.45rem;
            margin: 36px 0 1rem;
            color: var(--inverse-text);
            text-transform: capitalize;
          }

          p {
            font-size: 1rem;
            line-height: 26px;
            color: var(--inverse-text);

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }
  }

  .sharenow,
  .sandbox,
  .ces24 {
    /* HERO */
    .hero-indent {
      width: 100vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 60vw;
        margin: 0;
      }

      .closeBtnContainer {
        position: absolute;
        top: 0;
        z-index: 3;

        .closeIcon {
          margin: 1rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .container {
        margin-bottom: 36px;

        .image-logo-container {
          width: 100%;
          position: relative;
          overflow: hidden;
          height: 285px;

          .gradient {
            background: linear-gradient(180deg, #0000001f 0%, #45434380 100%);
            z-index: 1;
            height: 100%;
            width: 100vw;
            position: absolute;
            top: 0;
            /** */
            /* border-bottom-left-radius: 75px; */

            @media screen and (min-width: 800px) {
              width: 60vw;
            }
          }

          /** */
          .white {
            height: 120px;
            width: 101%;
            background: var(--clr-white);
            transform: skew(0deg, -5deg);
            position: absolute;
            left: 0;
            bottom: -65px;
            z-index: 2;
          }

          .media_1 {
            width: 100%;
            height: 100%;
            margin: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            object-fit: cover;
            z-index: 1;
            /** */
            /* border-bottom-left-radius: 75px; */
          }

          .logo-container {
            position: absolute;
            width: 55%;
            margin-left: 12px;
            top: 10%;
            left: 0;
            z-index: 2;
            height: 65%;
          }

          @media screen and (min-width: 800px) {
          }

          .logo {
            width: 90%;
            height: 100%;
            filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(328deg)
              brightness(106%) contrast(100%);
            z-index: 2;

            @media screen and (min-width: 600px) {
              width: 20rem;
            }
          }
        }
      }

      .text-container {
        width: 90vw;
        margin: 0 auto;

        @media screen and (min-width: 800px) {
          width: 50vw;
        }

        h1 {
          font-size: 2rem;
          margin-bottom: 24px;
          color: var(--clr-primary-1);
          text-transform: uppercase;
        }

        p {
          line-height: 26px;
          color: var(--clr-primary-3);
          font-size: 1rem;

          strong {
            color: var(--clr-secondary-3);
          }
        }
      }
    }

    /* LARGE-INDENT */
    .large-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            width: 2rem;
            filter: var(--clr-secondary-1-filter);
            /* filter: var(--clr-primary-1-filter); */
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--clr-primary-1);
            margin-bottom: 1rem;
            font-size: 18px;
          }

          p {
            font-size: 14px;
            line-height: 26px;
            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }

    /* SMALL INDENT */
    .small-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0 0.5rem 0 0;
            filter: brightness(0) saturate(100%) invert(19%) sepia(90%)
              saturate(1544%) hue-rotate(173deg) brightness(96%) contrast(103%);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--clr-primary-1);
            margin-bottom: 1rem;
            font-size: 14px;
          }
        }
      }
    }

    /* paragraph */
    .paragraph {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 24px;

        .image-logo-container {
          display: none;
        }

        .text-container {
          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            font-size: 1.45rem;
            margin: 36px 0 1rem;
            color: var(--clr-primary-1);
            text-transform: capitalize;
          }

          p {
            font-size: 1rem;
            line-height: 26px;

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }
  }

  .mini {
    /* HERO */
    .hero-indent {
      width: 100vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 60vw;
        margin: 0;
      }

      .closeBtnContainer {
        position: absolute;
        top: 0;
        z-index: 3;

        .closeIcon {
          margin: 1rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .container {
        margin-bottom: 36px;

        .image-logo-container {
          width: 100%;
          position: relative;
          overflow: hidden;
          height: 285px;

          .gradient {
            background: linear-gradient(180deg, #0000001f 0%, #45434380 100%);
            z-index: 1;
            height: 250px;

            width: 100vw;
            position: absolute;
            top: 0;
            /** */

            @media screen and (min-width: 800px) {
              width: 60vw;
            }
          }

          /** */
          .white {
            /* height: 120px;
            width: 101%;
            background: var(--clr-white);
            transform: skew(0deg, -5deg);
            position: absolute;
            left: 0;
            bottom: -65px;
            z-index: 2; */
          }

          .media_1 {
            width: 100%;
            /* height: 100%; */
            height: 250px;
            margin: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            object-fit: cover;
            z-index: 1;
            /** */
            /* border-bottom-left-radius: 75px; */
          }

          .logo-container {
            position: absolute;
            width: 55%;
            margin-left: 12px;
            top: 10%;
            left: 0;
            z-index: 2;
            height: 65%;
          }

          @media screen and (min-width: 800px) {
          }

          .logo {
            width: 90%;
            height: 100%;
            filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(328deg)
              brightness(106%) contrast(100%);
            z-index: 2;

            @media screen and (min-width: 600px) {
              width: 20rem;
            }
          }
        }
      }

      .text-container {
        width: 90vw;
        margin: 0 auto;

        @media screen and (min-width: 800px) {
          width: 50vw;
        }

        h1 {
          font-size: 2rem;
          margin-bottom: 24px;
          color: var(--clr-primary-1);
          text-transform: uppercase;
        }

        p {
          line-height: 26px;
          color: var(--clr-primary-3);
          font-size: 1rem;

          strong {
            color: var(--clr-secondary-3);
          }
        }
      }
    }

    /* LARGE-INDENT */
    .large-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            width: 2rem;
            filter: var(--clr-secondary-1-filter);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--clr-primary-1);
            margin-bottom: 1rem;
          }

          p {
            font-size: 14px;
            line-height: 26px;
            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }

    /* SMALL INDENT */
    .small-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0 0.5rem 0 0;
            filter: brightness(0) saturate(100%) invert(19%) sepia(90%)
              saturate(1544%) hue-rotate(173deg) brightness(96%) contrast(103%);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--clr-primary-1);
            margin-bottom: 1rem;
            font-size: 14px;
          }
        }
      }
    }

    /* paragraph */
    .paragraph {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 24px;

        .image-logo-container {
          display: none;
        }

        .text-container {
          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            font-size: 1.45rem;
            margin: 36px 0 1rem;
            color: var(--clr-primary-1);
            text-transform: capitalize;
          }

          p {
            font-size: 1rem;
            line-height: 26px;

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }
  }

  .etravel_audi {
    /* HERO */
    .hero-indent {
      width: 100vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 60vw;
        margin: 0;
      }

      .closeBtnContainer {
        position: absolute;
        top: 0;
        z-index: 3;

        .closeIcon {
          margin: 1rem;
          height: 2.5rem;
          width: 2.5rem;
        }
      }

      .container {
        margin-bottom: 36px;

        .image-logo-container {
          width: 100%;
          position: relative;
          overflow: hidden;
          height: 285px;

          .gradient {
            background: linear-gradient(180deg, #0000001f 0%, #45434380 100%);
            z-index: 1;
            height: 100%;
            width: 100vw;
            position: absolute;
            top: 0;
            /** */
            /* border-bottom-left-radius: 75px; */

            @media screen and (min-width: 800px) {
              width: 60vw;
            }
          }

          /** */
          .white {
            height: 120px;
            width: 101%;
            background: var(--clr-white);
            transform: skew(0deg, -5deg);
            position: absolute;
            left: 0;
            bottom: -65px;
            z-index: 2;
          }

          .media_1 {
            width: 100%;
            height: 100%;
            margin: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            object-fit: cover;
            z-index: 1;
            /** */
            /* border-bottom-left-radius: 75px; */
          }

          .logo-container {
            position: absolute;
            width: 55%;
            margin-left: 12px;
            top: 10%;
            left: 0;
            z-index: 2;
            height: 65%;
          }

          @media screen and (min-width: 800px) {
          }

          .logo {
            width: 90%;
            height: 100%;
            filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(328deg)
              brightness(106%) contrast(100%);
            z-index: 2;

            @media screen and (min-width: 600px) {
              width: 20rem;
            }
          }
        }
      }

      .text-container {
        width: 90vw;
        margin: 0 auto;

        @media screen and (min-width: 800px) {
          width: 50vw;
        }

        h1 {
          font-size: 2rem;
          margin-bottom: 24px;
          color: var(--clr-primary-1);
          text-transform: uppercase;
        }

        p {
          line-height: 26px;
          color: var(--clr-primary-3);
          font-size: 1rem;

          strong {
            color: var(--clr-secondary-3);
          }
        }
      }
    }

    /* LARGE-INDENT */
    .large-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            width: 2rem;
            filter: var(--iconset-filter);
            /* filter: var(--clr-primary-1-filter); */
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--clr-primary-1);
            margin-bottom: 1rem;
            font-size: 18px;
          }

          p {
            font-size: 14px;
            line-height: 26px;
            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }

    /* SMALL INDENT */
    .small-indent {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 36px;

        .image-logo-container {
          padding-right: 1rem;
          height: 0;

          .gradient {
            display: none;
          }

          .white {
            display: none;
          }

          .media_1 {
            height: 1.5rem;
            width: 1.5rem;
            margin: 0 0.5rem 0 0;
            filter: brightness(0) saturate(100%) invert(19%) sepia(90%)
              saturate(1544%) hue-rotate(173deg) brightness(96%) contrast(103%);
          }

          .logo {
            display: none;
          }
        }

        .text-container {
          width: 95vw;
          margin: 0 auto;

          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            color: var(--clr-primary-1);
            margin-bottom: 1rem;
            font-size: 14px;
          }
        }
      }
    }

    /* paragraph */
    .paragraph {
      width: 90vw;
      margin: 0 auto;

      @media screen and (min-width: 800px) {
        width: 50vw;
      }

      .closeBtnContainer {
        display: none;
      }

      .container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 24px;

        .image-logo-container {
          display: none;
        }

        .text-container {
          @media screen and (min-width: 800px) {
            width: 50vw;
          }

          h1 {
            font-size: 1.45rem;
            margin: 36px 0 1rem;
            color: var(--clr-primary-1);
            text-transform: capitalize;
          }

          p {
            font-size: 1rem;
            line-height: 26px;

            strong {
              color: var(--clr-secondary-3);
            }
          }
        }
      }
    }
  }
`;
export default ProductLabel;
