import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { envURL } from "./windowHelper";
import { resetLocalStorage } from "../Utils/helper";

const AxiosService = axios.create({
  baseURL: envURL,
});

AxiosService.interceptors.request.use(
  (config: AxiosRequestConfig = {}) => {
    const token = localStorage.getItem("token");
    if (!config.headers) config.headers = {};
    config.headers["Content-Type"] = "application/json";
    config.headers["Authorization"] = "Bearer " + token;
    return config;
  },
  (error) => {
    throw error;
  }
);

AxiosService.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error) => {
    if (axios.isCancel(error)) {
      throw error;
    } else {
      // If user is not logged in or doesn't have access to this resource refresh token and retry
      if (error.response.status === 401) {
        localStorage.setItem("restart", "1");
        window.location.href = "/";
      }

      throw error;
    }
  }
);

export default AxiosService;
