//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this page holds all Action functions and their types that calls different sets of API's, exported to
// their different pages
//
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import { ActionProps, ActionType } from "../ActionTypes/actionTypes";
import axios from "axios";
import { Dispatch } from "redux";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { envURL } from "../../Utils/windowHelper";
import AxiosService from "../../Utils/axiosService";
import { resetLocalStorage } from "../../Utils/helper";

export interface loginRoleRecord {
  role: string;
  user: string;
  password: string;
}

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

interface loginToken {
  data: {
    expires: number;
    token: string;
    option_guis: guis;
  };
}

interface userDecoded {
  authorized: string;
  exp: number;
  iat: Date | number;
  id: string;
  iss: string;
  role: string;
  JwtPayload: JwtPayload;
}

export const getLoginAction = () => async (dispatch: Dispatch<ActionProps>) => {
  try {
    dispatch({ type: ActionType.GET_LOGIN_TOKEN_REQUEST });

    const config = {};

    const cookiefacts = JSON.parse(localStorage.getItem("cookiefacts") as string);

    const factsPayload = {
      facts: {
        referer: cookiefacts.facts.referer,
      },
    };

    const { data }: loginToken = await axios.post(`${envURL}/login`, factsPayload, config);

    dispatch({
      type: ActionType.GET_LOGIN_TOKEN_SUCCESS,
      payload: data,
    });

    const token: string = data.token;

    const user: userDecoded = jwtDecode<userDecoded>(token);

    const option_guis = data?.option_guis;

    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
    localStorage.setItem("option_guis", JSON.stringify(option_guis));
  } catch (error: any) {
    dispatch({
      type: ActionType.GET_LOGIN_TOKEN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
    console.log(error);
    return false;
  }
};

/* Similar to getLoginAction, but requesting a specific role and supplying user/pass.
 * This function can be used independently of getLoginAction; it does not interfere
 * with getLoginAction's account.
 * Side effects are different: the returned token is stored at a different place,
 * using the requested role for keys ("user-<role>", "token-<role>"),
 * state events are dispatched under different names ("GET_ROLE_LOGIN_TOKEN_*").
 */
export const getRoleLoginAction = (login: loginRoleRecord) => async (dispatch: Dispatch<ActionProps>) => {
  try {
    dispatch({ type: ActionType.GET_ROLE_LOGIN_TOKEN_REQUEST });

    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data }: loginToken = await axios.post(`${envURL}/login`, login, headers);

    dispatch({
      type: ActionType.GET_ROLE_LOGIN_TOKEN_SUCCESS,
      payload: data,
    });

    const token: string = data.token;

    const user: userDecoded = jwtDecode<userDecoded>(token);

    localStorage.setItem("user-" + login.role, JSON.stringify(user));
    localStorage.setItem("token-" + login.role, token);
    // should not be done here
  } catch (error: any) {
    dispatch({
      type: ActionType.GET_ROLE_LOGIN_TOKEN_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
    console.log(error);
    return false;
  }
};

export interface factsRecord {
  [key: string]: string;
}

/*
 * read and update the record of global facts. Supply a jwt with the
 * appropriate role (operator.facts)
 */
// TODO the facts data type
export const globalFactsAction = (facts: factsRecord, token: string) => async (dispatch: Dispatch<ActionProps>) => {
  try {
    dispatch({ type: ActionType.GLOBAL_FACTS_REQUEST });

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const { data } = await axios.post(`${envURL}/config/global-facts`, facts, headers);

    dispatch({
      type: ActionType.GLOBAL_FACTS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    dispatch({
      type: ActionType.GLOBAL_FACTS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
    console.log(error);
    return false;
  }
};

interface guis {
  editable: boolean;
  fact_name: string;
  inc: number;
  max: number;
  min: number;
  required: boolean;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  widget: string;
  media: string[];
}

interface products {
  cost: number;
  currency: string;
  facts: any;
  guis?: guis[];
  latest_cancel: string;
  product_id: string;
  cost_net: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface categories {
  en: string;
}

export interface packageProps {
  categories?: categories[];
  facts: factsRecord;
  guis?: guis[];
  package_id?: string | undefined;
  product_groups?: product_groups[];
}

interface data {
  data?: {
    packages: {
      categories: categories;
      facts: factsRecord;
      guis: guis;
      count: number;
      package_id: string;
      product_groups: product_groups;
    };

    interests:
      | {
          factname: string;
          media: string;
          title: {
            en: string;
            de: string;
          };
        }[]
      | undefined;
  };
}

interface promotionProps {
  data: {
    promotion: {
      promotion_id: string;
      facts: factsRecord;
      guis: [
        {
          widget: string;
          class: string;
          title: {
            de: string;
            en: string;
          };
          text: {
            de: string;
            en: string;
          };
          media: string[];
        }
      ];
    };
  };
}

/*
 * clear local storage and restart from home.
 * This used to be done in the axiosService response interceptor for _each_ 400
 * response. We now do it here for each 400 response, with exceptions.
 * Where it actually should be done: when reading the response error from
 * dispatched state.
 * Also, it is called in too many cases. There should be more exceptions.
 * The situation right now is to mimick the former axiosService behaviour as
 * much as possible (for minimal impact on the upcoming vw demo June 27).
 */
const resetAndRestart = () => {
  resetLocalStorage();
  window.location.href = "/";
};

export const getPromotionsAction = (facts: {}) => async (dispatch: Dispatch<ActionProps>) => {
  const token = localStorage.getItem("token");
  if (!token) return;
  try {
    dispatch({ type: ActionType.GET_PROMOTIONS_REQUEST });

    const { data }: promotionProps = await AxiosService.post("/get-promotion", facts);

    dispatch({
      type: ActionType.GET_PROMOTIONS_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    console.log("getPromotionsAction", error);
    dispatch({
      type: ActionType.GET_PROMOTIONS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const getPackagesAction = (factsObject?: packageProps) => async (dispatch: Dispatch<ActionProps>) => {
  try {
    dispatch({ type: ActionType.GET_PACKAGES_REQUEST });

    const { data }: data = await AxiosService.post(`/select-packages`, factsObject);

    const packages = data?.packages;
    const interests = data?.interests;

    // Define the function to extract product GUIs
    const extractProductIdAndGuisData = (updatePayload: any) => {
      return updatePayload.flatMap(
        (pkg: any) =>
          pkg.product_groups?.flatMap((group: any) =>
            group.products.map((product: any) => ({
              product_id: product.product_id,
              guis: product.guis,
            }))
          ) ?? []
      );
    };

    // Call the function with the packages data
    const extractedData = extractProductIdAndGuisData(packages);

    // Store the extracted data in localStorage
    localStorage.setItem("extractedProductIdAndGuisData", JSON.stringify(extractedData));

    dispatch({ type: ActionType.GET_PACKAGES_SUCCESS, payload: packages });
    dispatch({ type: ActionType.GET_PACKAGES_LANDING_STATE, payload: packages });
    dispatch({ type: ActionType.GET_INTERESTS_SUCCESS, payload: interests });
    dispatch({
      type: ActionType.UPDATE_PACKAGES_SUCCESS,
      payload: [],
    });

    localStorage.setItem("packages", JSON.stringify(packages));
    localStorage.setItem("interests", JSON.stringify(interests));
  } catch (error: any) {
    console.log("getPackagesAction", error);
    dispatch({
      type: ActionType.GET_PACKAGES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
    if (error.response.status === 400) {
      resetAndRestart();
    }
  }
};

export interface updateProps {
  packages: {
    package_id: string;
    product_groups:
      | {
          products: {
            cost: number;
            currency: string;
            facts: factsRecord;
            guis: guis[];
            latest_cancel: string;
            product_id: string;
            cost_net: string;
          }[];
        }[]
      | undefined;
  }[];
}

interface interestProps {
  factname: string;
  media: string;
  title: {
    en: string;
    de: string;
  };
}

let errCount: number = 0;
export const updatePackagesAction = (updatePayload: any) => async (dispatch: Dispatch<ActionProps>) => {
  try {
    dispatch({
      type: ActionType.UPDATE_PACKAGES_REQUEST,
    });

    // Remove guis from Products for Payload
    const payloadWithoutGuis = {
      ...updatePayload,
      packages: updatePayload.packages.map((pkg: packageProps) => ({
        ...pkg,
        product_groups: pkg.product_groups?.map((group: product_groups) => ({
          ...group,
          products: group.products.map(({ guis, ...restOfProduct }) => restOfProduct),
        })),
      })),
    };

    const { data }: any = await AxiosService.post(`/update-packages`, payloadWithoutGuis);
    const updatedPackages = data.packages;

    const extractedDataString = localStorage.getItem("extractedProductIdAndGuisData");
    const extractedProductIdAndGuisData = extractedDataString ? JSON.parse(extractedDataString) : [];

    const combinedPackages = updatedPackages.map((pkg: packageProps) => ({
      ...pkg,
      product_groups: pkg.product_groups?.map((group: product_groups) => ({
        ...group,
        products: group.products.map((product) => {
          // Find the matching GUIs for this product
          const matchingGuis = extractedProductIdAndGuisData?.find(
            (item: { product_id: string; guis: guis[] }) => item.product_id === product.product_id
          )?.guis;
          return {
            ...product,
            guis: matchingGuis || [],
          };
        }),
      })),
    }));

    localStorage.setItem("packages", JSON.stringify(combinedPackages));

    dispatch({
      type: ActionType.UPDATE_PACKAGES_SUCCESS,
      payload: updatedPackages,
    });
    localStorage.setItem("packages", JSON.stringify(updatedPackages));

    const interests: interestProps[] = JSON.parse(localStorage.getItem("interests") || "[]");
    dispatch({ type: ActionType.GET_INTERESTS_SUCCESS, payload: interests });

    // Step 3: Combine and Save the Initial Request with the Saved Product GUIs

    // This step might be adjusted based on how you intend to use the combined data.
    // For example, if you need to recombine for the frontend, you might do it when fetching/displaying package details.
  } catch (error: any) {
    console.log("updatePackagesAction", error);
    errCount++;
    localStorage.setItem("err", JSON.stringify(errCount));

    dispatch({
      type: ActionType.UPDATE_PACKAGES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
    if (error.response.status === 400) {
      resetAndRestart();
    }
  }
};

export const createBookingAction = (createBookingData: any) => async (dispatch: Dispatch<ActionProps>) => {
  try {
    dispatch({
      type: ActionType.CREATE_BOOKING_REQUEST,
    });

    const { data }: any = await AxiosService.post(`/create-booking`, createBookingData);

    dispatch({
      type: ActionType.CREATE_BOOKING_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    console.log("createBookingAction", error);
    dispatch({
      type: ActionType.CREATE_BOOKING_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
    if (error.response.status === 400) {
      resetAndRestart();
    }
  }
};

export const getAIRecommendationAction = () => async (dispatch: Dispatch<ActionProps>) => {
  try {
    dispatch({
      type: ActionType.GET_AI_RECOMMENDATION_REQUEST,
    });

    const { data }: any = await AxiosService.get(`/recommend`);

    dispatch({
      type: ActionType.GET_AI_RECOMMENDATION_SUCCESS,
      payload: data.text,
    });
  } catch (error: any) {
    console.log("getAIRecommendationAction", error);
    dispatch({
      type: ActionType.GET_AI_RECOMMENDATION_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
  }
};

export const getBooking =
  (booking_code: string | undefined, verification: string) => async (dispatch: Dispatch<ActionProps>) => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const headers = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };

    try {
      dispatch({
        type: ActionType.GET_BOOKING_REQ,
      });

      const { data }: any = await AxiosService.post(
        `/get-booking`,
        {
          booking_code: `${booking_code}`,
          verification: `${verification}`,
        },
        headers
      );

      dispatch({
        type: ActionType.GET_BOOKING_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      console.log("getBooking", error);
      dispatch({
        type: ActionType.GET_BOOKING_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };

interface registerPayerProp {
  booking_id: string;
  payment_source_id: string /* payment menthod id */;
}

export const registerPayerAction = (reqPayload: registerPayerProp) => async (dispatch: Dispatch<ActionProps>) => {
  dispatch({
    type: ActionType.REGISTER_PAYER_REQUEST,
  });
  try {
    const { data }: any = await AxiosService.post(`/register-payment-source-id`, reqPayload);
    console.log("registerPayerData", data);

    dispatch({
      type: ActionType.REGISTER_PAYER_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    console.log("registerPayerAction", error);
    dispatch({
      type: ActionType.REGISTER_PAYER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
    if (error.response.status === 400) {
      resetAndRestart();
    }
  }
};

interface confirmBooking {
  booking_id: string;
}

export const confirmBookingAction = (booking_id: confirmBooking) => async (dispatch: Dispatch<ActionProps>) => {
  dispatch({
    type: ActionType.CONFIRM_BOOKING_REQUEST,
  });
  try {
    const { data }: any = await AxiosService.post(`/confirm-booking`, booking_id);

    dispatch({
      type: ActionType.CONFIRM_BOOKING_SUCCESS,
      payload: data,
    });

    console.log("confirmbooking", data);
  } catch (error: any) {
    console.log("confirmBookingAction", error);
    dispatch({
      type: ActionType.CONFIRM_BOOKING_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    });
    if (error.response.status === 400) {
      resetAndRestart();
    }
  }
};

export const getPhoneNumberAction = (data: string) => (dispatch: Dispatch<ActionProps>) => {
  dispatch({ type: ActionType.GET_PHONE_NUMBER, payload: data });
};

export const getFirstNameAction = (data: string | undefined) => (dispatch: Dispatch<ActionProps>) => {
  dispatch({ type: ActionType.GET_FIRST_NAME, payload: data });
};

export const getLastNameAction = (data: string | undefined) => (dispatch: Dispatch<ActionProps>) => {
  dispatch({ type: ActionType.GET_LAST_NAME, payload: data });
};

export const getEmailAction = (data: string | undefined) => (dispatch: Dispatch<ActionProps>) => {
  dispatch({ type: ActionType.GET_EMAIL, payload: data });
};

// Full Address
export const getAddressAction =
  (data: { postalCode: string; streetAndNo: string; city: string; country: string }) =>
  (dispatch: Dispatch<ActionProps>) => {
    dispatch({ type: ActionType.GET_FULL_ADDRESS, payload: data });
  };
// Full Address
