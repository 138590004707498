//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is one of the few components used in the bbt platform
// this component is used in the landing page to display all packages available to
// end user
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { AiFillPlusCircle, AiFillCheckCircle } from "react-icons/ai";
import { packageProps } from "../Screens/LandingPageScreen";
import { formatPrice } from "../Utils/helper";
import { createNewResponseObjectPackageFacts } from "../Utils/helper";
import { MdArrowForwardIos } from "react-icons/md";

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

interface Props {
  gui: guis;
  englishLanguage: boolean;
  eachPackage: packageProps;
  packages: any;
  // setBtnDisable: Function;
  referer: string;
}

const PackageCard: React.FC<Props> = ({ englishLanguage, gui, eachPackage, packages, referer }) => {
  /* This is a function that checks if all packages are disabled. */
  // const everyPackagedIsDisabled = packages?.every((eachPackage: packageProps) => eachPackage.facts.enabled === "0");

  /* This is a react hook that is used to set the state of the component. */
  // change to a function
  const [packEnabled, setPackEnabled] = useState<string>("");

  /* This is a react hook that is used to set the state of the component. */
  const updatePackageFacts = useCallback(() => {
    createNewResponseObjectPackageFacts(
      {
        packages: [eachPackage],
      },
      eachPackage?.package_id
    );
  }, [eachPackage]);

  const mainCost = eachPackage.product_groups.map((pg: any) => {
    const cost = pg?.products?.map((p: any) => p.facts.enabled === "1" && p.cost);
    return cost;
  });

  const totalPackageCost = mainCost.flat().reduce((partialSum: number, a: number) => partialSum + a, 0);

  useEffect(() => {
    setPackEnabled(eachPackage?.facts?.enabled);
  }, [packEnabled, eachPackage?.facts?.enabled]);

  return (
    <Wrapper>
      <div className={`${referer}`}>
        <Button className={packEnabled === "0" ? "not-active" : "active-color"}>
          <Content>
            <h4
              dangerouslySetInnerHTML={{
                __html: englishLanguage ? gui?.title?.en : gui?.title?.de,
              }}
            />

            <p
              dangerouslySetInnerHTML={{
                __html: englishLanguage
                  ? `${gui?.text?.en.substring(0, 80)}...`
                  : `${gui?.text?.de.substring(0, 80)}...`,
              }}
            />

            {/* price and details */}
            <div className="price_persons_route">
              <p>From</p>
              <div className={"price"}>
                {" "}
                <h4>{formatPrice(totalPackageCost, englishLanguage)}</h4>
              </div>

              <div>
                <p>{"more"}</p>
                {/* <MdArrowForwardIos /> */}
              </div>
            </div>
          </Content>
          <ImageWrapper>
            {gui?.widget === "label" && <img src={gui?.media?.[0]} alt="" />}
            <div className={packEnabled === "0" ? "patch" : "patch active-color"}></div>
            <div className="iconWrapper">
              {packEnabled === "0" ? (
                <AiFillPlusCircle
                  className="icon"
                  onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
                    e.stopPropagation();
                    setPackEnabled("1");
                    updatePackageFacts();
                    localStorage.setItem("packages", JSON.stringify(packages));
                  }}
                />
              ) : (
                <AiFillCheckCircle
                  className="active"
                  onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
                    e.stopPropagation();
                    setPackEnabled("0");
                    updatePackageFacts();
                    localStorage.setItem("packages", JSON.stringify(packages));
                  }}
                />
              )}
            </div>
          </ImageWrapper>
        </Button>
      </div>
    </Wrapper>
  );
};

const Button = styled.button`
  cursor: pointer;
  border-radius: var(--radius-components);
  margin: 0 auto;
  /* box-shadow: var(--box-shadow); */
  transition: var(--transition);
  margin-bottom: 0.5rem;
  border: none;
  display: flex;
`;

const ImageWrapper = styled.div`
  .mini {
    height: 100%;
    position: relative;
    overflow: hidden;
    padding: 0;

    .iconWrapper {
      position: absolute;
      top: 25%;
      left: 25%;

      @media screen and (min-width: 800px) {
        left: 35%;
      }

      .icon {
        font-size: 3.5rem;
        color: white;
      }
    }

    img {
      width: 100%;
      height: inherit;
      border-radius: var(--radius-components);
      object-fit: cover;
    }
  }
`;

const Content = styled.div`
  width: 65%;
  height: 100%;
  padding: 10px;
  display: grid;
  align-items: center;
  border-bottom-left-radius: var(--radius-components);
  border-top-left-radius: var(--radius-components);

  @media screen and (min-width: 800px) {
    padding-left: 12px;
    width: 75%;
  }

  .price_persons_route {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;

    div {
      display: flex;
      align-items: center;
      p {
        text-align: center;
      }
    }

    p {
      color: var(--clr-primary-1);
      text-align: center;
    }

    @media screen and (min-width: 800px) {
      width: 100%;
    }
  }

  .price {
    border-radius: 6px;
    /* width: 40%; */
    display: flex;
    justify-content: center;
    padding: 0 3px;
    align-items: center;
    background: var(--clr-primary-1);
    height: 1.7rem;
    margin: 0.5rem;

    h4 {
      color: var(--clr-white);

      span {
        font-size: 1rem;
      }

      @media screen and (min-width: 800px) {
        font-size: 13px;
      }
    }
  }

  h4 {
    color: var(--clr-primary-1);
    text-align: left;

    font {
      font-size: 0.625rem;
    }
  }

  p {
    font-size: 12px;
    padding: 4px 0;
    text-align: left;

    @media screen and (min-width: 800px) {
      font-size: 12px;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 15px;

  .sharenow,
  .sandbox,
  .ces24 {
    ${Button} {
      box-shadow: var(--box-shadow);
    }

    .active-color {
      background: var(--clr-pack-active);
    }

    .not-active {
      background: var(--card-background);
    }

    ${Button} {
      height: 10rem;
      background: var(--clr-white);
      width: 100%;
    }

    ${ImageWrapper} {
      height: 100%;
      width: 35%;
      position: relative;
      overflow: hidden;

      .iconWrapper {
        background: var(--clr-secondary-1);
        position: absolute;
        top: 35%;
        left: 45%;
        border-radius: 50%;
        height: 3.5rem;

        .active {
          font-size: 3.5rem;
          color: white;
        }

        .icon {
          font-size: 3.5rem;
          color: white;
        }
      }

      .patch {
        height: 4rem;
        width: 2rem;
        position: absolute;
        top: 5px;
        left: -30px;
        transform: rotate(20deg);
        scale: 3.5;
        background: var(--clr-white);
      }

      img {
        width: 100%;
        height: inherit;
        border-top-right-radius: var(--radius-components);
        border-bottom-right-radius: var(--radius-components);
        object-fit: cover;
      }
    }

    ${Content} {
      background: var(--card-white);
      padding: 7px 15px;
    }
  }

  .mini {
    ${Button} {
      box-shadow: var(--box-shadow);
    }

    .active-color {
      background: var(--clr-pack-active);
    }

    .not-active {
      background: var(--card-background);
    }

    ${Button} {
      height: 10rem;
      width: 100%;
      padding: 12px;
      /* background: blue; */
    }

    ${ImageWrapper} {
      height: 100%;
      width: 35%;
      position: relative;
      overflow: hidden;
      margin: 0 0 0 12px;

      .iconWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .active {
          font-size: 3.5rem;
          color: white;
        }

        .icon {
          font-size: 3.5rem;
          color: white;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: var(--radius-components);
        object-fit: cover;
      }
    }

    ${Content} {
      /* background: var(--card-background); */
      padding: 0;
    }
  }

  .volkswagen {
    ${Button} {
      box-shadow: var(--box-shadow);
    }

    .active-color {
      background: var(--clr-pack-active);
    }

    .not-active {
      background: var(--card-background);
    }

    ${Button} {
      height: 130px;
      background: var(--card-background);
      width: 100%;
      padding: 12px;
      color: var(--clr-white);
    }

    ${Content} {
      background: transparent;
      padding: 0;

      h4,
      p {
        color: var(--clr-white);
      }

      .price {
        background: var(--clr-white);

        h4 {
          color: var(--clr-primary-1);
        }
      }
    }

    ${ImageWrapper} {
      height: 100%;
      width: 35%;
      position: relative;
      overflow: hidden;
      margin: 0 0 0 12px;

      .iconWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .active {
          font-size: 3.5rem;
          color: white;
        }

        .icon {
          font-size: 3.5rem;
          color: white;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: var(--radius-components);
        object-fit: cover;
      }
    }
  }

  .manx {
    .active-color {
      background: var(--clr-primary-1);
      h4,
      p {
        color: white;
      }

      ${Content} {
        .price {
          background: var(--clr-white);

          h4 {
            color: var(--clr-primary-1);
          }
        }
      }
    }

    .not-active {
      background: var(--clr-white);

      p {
        color: var(--clr-primary-1);
      }

      ${Content} {
        .price {
          background: var(--clr-white);

          h4 {
            color: var(--clr-black);
          }
        }
      }
    }

    ${Button} {
      height: 130px;
      width: 100%;
    }

    ${ImageWrapper} {
      height: 100%;
      width: 35%;
      position: relative;
      overflow: hidden;
      margin: 0 0 0 12px;

      .iconWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .active {
          font-size: 3.5rem;
          color: white;
        }

        .icon {
          font-size: 3.5rem;
          color: white;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: var(--radius-components);
        object-fit: cover;
      }
    }
  }

  .etravel_mercedes,
  .etravel_geo {
    .active-color {
      background: white;
      h4,
      p {
        color: black;
      }

      ${Content} {
        .price {
          background: #0088c5;
          h4 {
            color: var(--clr-white);
          }
        }
      }
    }

    .not-active {
      background: var(--clr-white);

      h4,
      p {
        color: black;
      }

      ${Content} {
        .price {
          background: #0088c5;
          h4 {
            color: var(--clr-white);
          }
        }
      }
    }

    ${Button} {
      height: 150px;
      width: 100%;
    }

    ${ImageWrapper} {
      height: 100%;
      width: 35%;
      position: relative;
      overflow: hidden;
      margin: 0 0 0 12px;
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%);

      .iconWrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* z-index: 99; */

        .active {
          color: #0088c5;
          font-size: 3.5rem;
        }

        .icon {
          font-size: 3.5rem;
          color: white;
        }
        .active::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 100%;
          background-color: white;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: var(--radius-components);
        object-fit: cover;
      }
    }

    ${ImageWrapper}::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to left, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 1) 96%);
      /* z-index: 1; */
    }
  }

  .etravel_audi {
    margin: 1rem 0;

    ${Button} {
      box-shadow: var(--box-shadow);
      border: 2px solid red;
    }

    .active-color {
      background: var(--clr-pack-active);
    }

    .not-active {
      border: var(--card-background);
    }

    ${Button} {
      height: 10rem;
      background: var(--clr-white);
      width: 100%;
    }

    ${ImageWrapper} {
      height: 100%;
      width: 35%;
      position: relative;
      overflow: hidden;

      .iconWrapper {
        background: var(--clr-secondary-1);
        position: absolute;
        top: 35%;
        left: 45%;
        border-radius: 50%;
        height: 3.5rem;

        .active {
          font-size: 3.5rem;
          color: white;
        }

        .icon {
          font-size: 3.5rem;
          color: white;
        }
      }

      .patch {
        height: 4rem;
        width: 2rem;
        position: absolute;
        top: 5px;
        left: -30px;
        transform: rotate(20deg);
        scale: 3.5;
        background: var(--clr-white);
      }

      img {
        width: 100%;
        height: inherit;
        border-top-right-radius: var(--radius-components);
        border-bottom-right-radius: var(--radius-components);
        object-fit: cover;
      }
    }

    ${Content} {
      background: var(--card-white);
      padding: 7px 15px;
    }
  }

  @media screen and (min-width: 800px) {
    width: 50vw;
    padding: 0;
  }
`;

export default PackageCard;
