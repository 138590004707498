//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this file holds all redux reducer functions and payloads
//
// POST /v1/portal/register-payment-source-id
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import { ActionType, ActionProps } from "../ActionTypes/actionTypes";

interface loginTokenProps {
  loading: boolean;
  error: boolean;
  token: string;
  expires?: number;
}

// REDUCER FOR GET TOKEN
export const getLoginTokenReducer = (
  state: loginTokenProps = {
    loading: true,
    error: false,
    token: "",
  },
  action: ActionProps
) => {
  if (action.type === ActionType.GET_LOGIN_TOKEN_REQUEST) {
    return { error: false, loading: true, token: "" };
  }
  if (action.type === ActionType.GET_LOGIN_TOKEN_SUCCESS) {
    return { loading: false, error: false, token: action.payload };
  }
  if (action.type === ActionType.GET_LOGIN_TOKEN_FAIL) {
    return { loading: false, error: action.payload };
  }
  return state;
};
export const getRoleLoginTokenReducer = (
  state: loginTokenProps = {
    loading: false,
    error: false,
    token: "",
  },
  action: ActionProps
) => {
  if (action.type === ActionType.GET_ROLE_LOGIN_TOKEN_REQUEST) {
    return { error: false, loading: true, token: "" };
  }
  if (action.type === ActionType.GET_ROLE_LOGIN_TOKEN_SUCCESS) {
    return { loading: false, error: false, token: action.payload };
  }
  if (action.type === ActionType.GET_ROLE_LOGIN_TOKEN_FAIL) {
    return { loading: false, error: action.payload };
  }
  return state;
};
// END REDUCER FOR GET TOKEN

interface globalFactsProps {
  loading: boolean;
  error: string;
  data: string;
}

// GLOBAL_FACTS REDUCER
export const globalFactsReducer = (
  state: globalFactsProps = {
    loading: false,
    error: "",
    data: "",
  },
  action: ActionProps
) => {
  if (action.type === ActionType.GLOBAL_FACTS_REQUEST) {
    return { error: "", loading: true, data: "" };
  }
  if (action.type === ActionType.GLOBAL_FACTS_SUCCESS) {
    return { loading: false, error: "", data: action.payload };
  }
  if (action.type === ActionType.GLOBAL_FACTS_FAIL) {
    return { loading: false, error: action.payload };
  }
  return state;
};
// END REDUCER FOR GLOBAL_FACTS

// GET PACKAGES REDUCER
interface guis {
  editable: boolean;
  fact_name: string;
  inc: number;
  max: number;
  min: number;
  required: boolean;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  widget: string;
  media: string[];
}

interface product_facts {
  Title: string; // change Title to title
  Vendor: string; // change Vendor to vendor
  count: string;
}

interface products {
  cost: number;
  currency: string;
  facts: product_facts;
  guis: guis[];
  latest_cancel: string;
  product_id: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface categories {
  en: string;
}

interface packageProps {
  package_id?: string;
  product_groups?: product_groups[];
  categories?: categories[];
  facts?: any;
  guis?: guis[];
}

export const getPackagesLandingUpdateReducer = (state: packageProps = {}, action: ActionProps) => {
  if (action.type === ActionType.GET_PACKAGES_LANDING_STATE) {
    return { loading: false, error: false, packagesv2: action.payload };
  }
  return state;
};

export const getPackagesReducer = (state: packageProps = { facts: {} }, action: ActionProps) => {
  if (action.type === ActionType.GET_PACKAGES_REQUEST) {
    return { loading: true };
  }
  if (action.type === ActionType.GET_PACKAGES_SUCCESS) {
    return { loading: false, error: false, packages: action.payload };
  }

  if (action.type === ActionType.GET_INTERESTS_SUCCESS) {
    return { interests: action.payload };
  }
  if (action.type === ActionType.GET_PACKAGES_FAIL) {
    return { loading: false, error: action.payload };
  }
  return state;
};
// END GET PACKAGES REDUCER

// GET PROMOTIONS REDUCER
interface promotionProps {
  promotion?: {
    promotion_id?: string;
    facts?: any;
    guis?: [
      {
        widget: string;
        class: string;
        title: {
          de: string;
          en: string;
        };
        text: {
          de: string;
          en: string;
        };
        media: string[];
      }
    ];
  };
}

export const getPromotionsReducer = (state: promotionProps = {}, action: ActionProps) => {
  if (action.type === ActionType.GET_PROMOTIONS_REQUEST) {
    return { loading: true, error: false };
  }
  if (action.type === ActionType.GET_PROMOTIONS_SUCCESS) {
    return { loading: false, error: false, promotion: action.payload };
  }
  if (action.type === ActionType.GET_PROMOTIONS_FAIL) {
    return { loading: false, error: action.payload };
  }
  return state;
};
// END GET PROMOTIONS REDUCER

interface updatedPackagesProps {
  package_id?: string;
  product_groups?: product_groups;
  facts?: {
    adults: string;
    age_youngest: string;
    agreetc: string;
    children: string;
    endtime: string;
    pickup_city: string;
    pickup_country: string;
    pickup_postcode: string;
    pickup_street: string;
    starttime: string;
    vehicle: string;
  };
}

interface updatePackagesInitialState {
  error: boolean;
  loading: boolean;
  updatedPackages: updatedPackagesProps;
}

// UPDATE PACKAGE REDUCER
export const getUpdatedPackagesReducer = (
  state: updatePackagesInitialState = {
    loading: true,
    error: false,
    updatedPackages: {},
  },
  action: ActionProps
) => {
  if (action.type === ActionType.UPDATE_PACKAGES_REQUEST) {
    return { loading: true, error: false };
  }
  if (action.type === ActionType.UPDATE_PACKAGES_SUCCESS) {
    return {
      loading: false,
      error: false,
      updatedPackages: action.payload,
      success: true,
    };
  }
  if (action.type === ActionType.GET_INTERESTS_SUCCESS) {
    return { interests: action.payload };
  }

  if (action.type === ActionType.UPDATE_PACKAGES_FAIL) {
    return {
      loading: false,
      errorMessage: action.payload,
      error: true,
    };
  }
  return state;
};
// END UPDATE PACKAGE REDUCER

// REDUCER FOR GET TOKEN
interface CreateBooking {
  loading: false;
  success: false;
  bookingId: string;
  bookingCode: string;
  error: boolean;
}

export const getCreateBookingReducer = (
  state: CreateBooking = {
    loading: false,
    success: false,
    bookingId: "",
    bookingCode: "",
    error: false,
  },
  action: ActionProps
) => {
  if (action.type === ActionType.CREATE_BOOKING_REQUEST) {
    return { loading: true, success: false, error: false };
  }
  if (action.type === ActionType.CREATE_BOOKING_SUCCESS) {
    return {
      loading: false,
      success: true,
      bookingId: action.payload.booking_id,
      bookingCode: action.payload.booking_code,
      error: false,
    };
  }
  if (action.type === ActionType.CREATE_BOOKING_FAIL) {
    return {
      error: true,
      loading: false,
      success: false,
      errorMessage: action.payload,
    };
  }
  return state;
};
// END REDUCER FOR GET TOKEN

// REDUCER FOR GET AI RECOMMENDATION
interface GetAIRecommendation {
  loading: boolean;
  success: boolean;
  error: boolean;
  recommendation: {
    en: string;
    de: string;
  };
}

export const getAIRecommendationReducer = (
  state: GetAIRecommendation = {
    loading: false,
    success: false,
    recommendation: {
      en: "",
      de: "",
    },
    error: false,
  },
  action: ActionProps
) => {
  if (action.type === ActionType.GET_AI_RECOMMENDATION_REQUEST) {
    return { loading: true, success: false, error: false };
  }
  if (action.type === ActionType.GET_AI_RECOMMENDATION_SUCCESS) {
    return {
      loading: false,
      success: true,
      recommendation: action.payload,
      error: false,
    };
  }
  if (action.type === ActionType.GET_AI_RECOMMENDATION_FAIL) {
    return {
      error: true,
      loading: false,
      success: false,
      errorMessage: action.payload,
    };
  }
  return state;
};

interface BookingData {
  booking_id: string;
  facts: string;
  guis: string[];
  status: string;
  vendors: string[];
}

interface GetBooking {
  loading: boolean;
  success: boolean;
  error: boolean;
  bookingData: BookingData;
}

export const getBookingReducer = (
  state: GetBooking = {
    loading: false,
    success: false,
    bookingData: { booking_id: "", facts: "", guis: [], status: "", vendors: [] },
    error: false,
  },
  action: ActionProps
) => {
  if (action.type === ActionType.GET_BOOKING_REQ) {
    return { loading: true, success: false, error: false };
  }
  if (action.type === ActionType.GET_BOOKING_SUCCESS) {
    return {
      loading: false,
      success: true,
      bookingData: action.payload,
      error: false,
    };
  }
  if (action.type === ActionType.GET_BOOKING_FAIL) {
    return {
      error: true,
      loading: false,
      success: false,
      errorMessage: action.payload,
    };
  }
  return state;
};
// END REDUCER FOR GET AI RECOMMENDATION

// register payer
interface registerPayer {
  loading: boolean;
  error: boolean;
  pay_id: string;
  success: boolean;
}

export const registerPayerReducer = (
  state: registerPayer = {
    loading: false,
    error: false,
    pay_id: "",
    success: false,
  },
  Action: ActionProps
) => {
  if (Action.type === ActionType.REGISTER_PAYER_REQUEST) {
    return { loading: true, error: false };
  }
  if (Action.type === ActionType.REGISTER_PAYER_SUCCESS) {
    return {
      error: false,
      loading: false,
      payer_id: Action.payload,
      success: true,
    };
  }
  if (Action.type === ActionType.REGISTER_PAYER_FAIL) {
    return {
      loading: false,
      errorMessage: Action.payload,
      error: true,
      success: false,
    };
  }

  return state;
};
// end register payer

interface confirmBookingProps {
  errorConfirmingBooking: boolean;
  loading: boolean;
  error: boolean;
}
export const confirmBookingReducer = (
  state: confirmBookingProps = {
    errorConfirmingBooking: false,
    loading: false,
    error: false,
  },
  Action: ActionProps
) => {
  if (Action.type === ActionType.CONFIRM_BOOKING_REQUEST) {
    return { loading: true, errorConfirmingBooking: false };
  }
  if (Action.type === ActionType.CONFIRM_BOOKING_SUCCESS) {
    return {
      error: false,
      loading: false,
      confirmBooking: Action.payload,
    };
  }
  if (Action.type === ActionType.CONFIRM_BOOKING_FAIL) {
    return {
      loading: false,
      errorMessage: Action.payload,
      error: true,
    };
  }

  return state;
};
// end register payer

// SKI EQUIPMENT REDUCER

interface singleInfoProps {
  customerAddress: {
    postalCode: string;
    streetAndNo: string;
    city: string;
    country: string;
  };
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  // condition_Booking: boolean;
  filters: {
    max_Experience_Level: number;
    min_Experience_Level: number;
    experience_Level: number;
  };

  phone_Number: string;
  booking_Id: string;
  expStartTime: string;
}

export const getInformationDetailsReducer = (
  state: singleInfoProps = {
    // PERSONAL INFO STATE
    customerFirstName: "",
    customerLastName: "",
    customerAddress: {
      postalCode: "",
      streetAndNo: "",
      city: "",
      country: "",
    },
    customerEmail: "",
    phone_Number: "",
    // END OF PERSONAL INFO STATE

    // FILTERS FOR EXPERIENCE LEVEL
    filters: {
      max_Experience_Level: 9,
      min_Experience_Level: 1,
      experience_Level: 5,
    },
    // END OF FILTERS FOR EXPERIENCE LEVEL

    booking_Id: "",

    // EXP START TIME
    expStartTime: "",
    // END EXP START TIME
  },
  action: ActionProps
) => {
  // GET_EXP_START_TIME
  if (action.type === ActionType.EXP_START_TIME) {
    return { ...state, expStartTime: action.payload };
  }
  // END GET_EXP_START_TIME

  // GET BOOKING ID
  if (action.type === ActionType.CREATE_BOOKING_SUCCESS) {
    return { ...state, booking_Id: action.payload.booking_id };
  }
  // END GET BOOKING ID

  // GET PHONE NUMBER
  if (action.type === ActionType.GET_PHONE_NUMBER) {
    return { ...state, phone_Number: action.payload };
  }
  // END GET PHONE NUMBER

  // FIRST NAME
  if (action.type === ActionType.GET_FIRST_NAME) {
    return { ...state, customerFirstName: action.payload };
  }
  // END OF FIRST NAME

  // GET_LAST_NAME
  if (action.type === ActionType.GET_LAST_NAME) {
    return { ...state, customerLastName: action.payload };
  }
  // END GET_LAST_NAME

  // GET_EMAIL
  if (action.type === ActionType.GET_EMAIL) {
    return { ...state, customerEmail: action.payload };
  }
  // END GET_EMAIL

  // GET_ADDRESS
  if (action.type === ActionType.GET_FULL_ADDRESS) {
    const { postalCode, streetAndNo, city, country } = action.payload;
    return {
      ...state,
      customerAddress: {
        ...state.customerAddress,
        postalCode,
        streetAndNo,
        city,
        country,
      },
    };
  }
  // END GET_ADDRESS

  return state;
};
