//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is the total components that shows the total of the products selected
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import { formatPrice } from "../Utils/helper";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../State/store";
import ButtonLoading from "./ButtonLoading";

interface total {
  total: number;
  loading: boolean;
}

const TotalComponent: React.FC<total> = ({ total, loading }) => {
  /* This is a react hook that is used to get the state of the language from the redux store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  return (
    <ProductWrapper>
      {/* TOTALS */}
      <Totals>
        <hr />

        <div className="amount-container">
          {englishLanguage ? (
            <h5>
              Total <span>(incl. VAT)</span>
            </h5>
          ) : (
            <h5>
              Gesamtsumme <span>(inkl. MwSt.) </span>
            </h5>
          )}

          <div>
            {loading ? <ButtonLoading primarySNColor={true} /> : <h4>{formatPrice(total, englishLanguage)}</h4>}
          </div>
        </div>
      </Totals>
    </ProductWrapper>
  );
};

const Totals = styled.div`
  padding: 1rem auto 6rem;
  width: 100%;

  @media screen and (min-width: 800px) {
    margin: 1rem auto 2rem;
  }

  h5,
  h4 {
    color: var(--clr-black);
  }

  .amount-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    margin: 1.2rem 0 1.2rem auto;
  }

  @media screen and (min-width: 800px) {
    width: 50vw;

    .amount-container {
      width: 50vw;
      margin: 0 auto;
    }
  }
`;

const ProductWrapper = styled.div`
  hr {
    background: var(--hr-);
    /* background: #7070704d; */
    height: 2px;
    border: none;
    width: 70%;
    margin: 0.5rem 0 0.5rem auto;
  }

  @media screen and (min-width: 800px) {
    hr {
      width: 50vw;
    }
  }
`;

export default TotalComponent;
