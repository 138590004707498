//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is the final page on the bbt platform flow, whihc shows the confirmed
// booking and more details concerning the purchases product
//
// POST /v1/portal/register-payment-source-id
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { BookingSummaryComponent, PageTitle, Hero } from "../components/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../State/store";
import { getFirstNameAction } from "../State/ActionCreators/index";
import { backToTop } from "../Utils/helper";
import { FiCheckCircle } from "react-icons/fi";
import { IconContext } from "react-icons";
import { DesktopWrapper, WhiteBackground } from "../Utils/styles";
import { IoIosArrowForward } from "react-icons/io";
import { IoWarningOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import { envStaticHost } from "../Utils/windowHelper";

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

interface products {
  product_id: string;
  facts: any;
  cost: number;
  currency: string;
  guis: guis[];
  latest_cancel: string;
  cost_net: number;
}

interface categories {
  en: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface packageProps {
  package_id?: string;
  product_groups?: product_groups[];
  categories?: categories[];
  facts?: any;
  guis?: guis[];
}

const FinalOverviewScreen = () => {
  const dispatch = useDispatch();

  // let navigate = useNavigate();

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  /* Destructuring the useParams hook from react-router-dom and assigning it to a variable called
packageIdParams. */
  const { packageid: packageIdParams } = useParams<{ packageid?: string }>();

  /* Destructuring the customerFirstName from the getInformationDetails object. */
  const getInformationDetails = useSelector((state: RootState) => {
    return state.getInformationDetails;
  });
  const { customerFirstName } = getInformationDetails;

  /* Destructuring the bookingId from the getCreateBooking object. */
  const getCreateBooking = useSelector((state: RootState) => {
    return state.getCreateBooking;
  });
  const { bookingId: bookingId, bookingCode: bookingCode }: { bookingId: string; bookingCode: string } =
    getCreateBooking;

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /* Checking if there is a localStorage item called "packages" and if there is, it is parsing it into
  a JSON object and assigning it to the packages variable. If there is no localStorage item called
  "packages", it is assigning an empty array to the packages variable. */
  const packages: packageProps[] = JSON.parse(localStorage.getItem("packages") || "[]");

  useEffect(() => {
    backToTop();
    dispatch(getFirstNameAction(customerFirstName));
  }, [dispatch, customerFirstName]);

  /**
   * If the business area is Cologne or Köln, then return the vehicle booking link for Cologne. If the
   * business area is Munich or München, then return the vehicle booking link for Munich. If the
   * business area is Vienna or Wien, then return the vehicle booking link for Vienna. Otherwise,
   * return the vehicle booking link for the business area
   * @returns a link to the sharenow website.
   */
  const redirectUserToSharenow = () => {
    if (facts.business_area.toLowerCase() === "cologne" || facts.business_area.toLowerCase() === "köln") {
      let vehicleBookingLink = `https://www.share-now.com/prebook/${facts.language}/rhineland/trip`;

      return vehicleBookingLink;
    } else if (facts.business_area.toLowerCase() === "munich" || facts.business_area.toLowerCase() === "münchen") {
      let vehicleBookingLink = ` https://www.share-now.com/prebook/${facts.language}/munich/trip`;

      return vehicleBookingLink;
    } else if (facts.business_area.toLowerCase() === "vienna" || facts.business_area.toLowerCase() === "wien") {
      let vehicleBookingLink = ` https://www.share-now.com/prebook/${facts.language}/vienna/trip`;

      return vehicleBookingLink;
    } else {
      let vehicleBookingLink = ` https://www.share-now.com/prebook/${
        facts.language
      }/${facts.business_area.toLowerCase()}/trip`;

      return vehicleBookingLink;
    }
  };

  return (
    <DesktopWrapper>
      <WhiteBackground>
        <Wrapper>
          <Hero
            showCloseBar={false}
            showLanguageBar={false}
            vehicle={facts.vehicle}
            business_area={facts.business_area}
            cityBackdropAction={true}
            defaultBackdropAction={false}
            flow={facts.flow}
            isFinalOverviewPage={true}
            isLandingPage={false}
            referer={facts.referer}
            facts={facts}
          />

          {packages &&
            packages.map((singlePackage: packageProps) => {
              if (singlePackage?.package_id === packageIdParams) {
                return (
                  <React.Fragment key={singlePackage?.package_id}>
                    <div className="hide-page-title">
                      <PageTitle headingTwoGray={`Hi ${customerFirstName ? customerFirstName : "there"}, `} />
                      <h2
                        style={{
                          color: "var(--clr-primary-1)",
                          padding: "0.5rem 15px 1rem",
                          textTransform: "uppercase",
                        }}
                      >
                        {" "}
                        {englishLanguage ? `YOUR BOOKINGS ARE CONFIRMED` : `DEINE BUCHUNGEN SIND BESTÄTIGT`}
                      </h2>
                    </div>

                    {facts?.flow === "0" && facts.referer === "sharenow" && (
                      <section>
                        <h4>
                          {" "}
                          <IoWarningOutline className="icon" style={{ color: "red" }} />
                          {englishLanguage ? "Your pending bookings" : "Deine ausstehenden Buchungen"}
                        </h4>
                        <a href={redirectUserToSharenow()} target="_blank" rel="noreferrer">
                          <div className="book-sharenow-container">
                            <div className="text-container">
                              <img
                                src={`${envStaticHost}/static/${facts.referer}/car/car-L.png`}
                                alt="sharenow vehicle"
                              />
                              <div>
                                <h3>Go to SHARE NOW</h3>
                                <p>Pre-book your vehicle for the selected trip duration</p>
                              </div>
                            </div>
                            <IoIosArrowForward className="icon" />
                          </div>
                        </a>
                      </section>
                    )}

                    <section>
                      <h4>
                        <IoCheckmarkCircleOutline
                          className="icon"
                          style={{
                            color:
                              facts?.referer === "manx" || facts?.referer === "etravel_mercedes"
                                ? "var(--clr-primary-1)"
                                : "#0eb468",
                          }}
                        />
                        {englishLanguage ? "Your confirmed bookings" : "Deine bestätigten Buchungen"}
                      </h4>
                    </section>

                    {singlePackage &&
                      singlePackage.product_groups?.map((product_group: product_groups) => {
                        return product_group?.products?.map((singleProduct: products) => {
                          if (singleProduct?.facts?.enabled === "1") {
                            return (
                              singleProduct &&
                              singleProduct.guis?.map((guis: guis, index: number) => {
                                if (guis?.widget === "label" && index === 0) {
                                  return (
                                    <React.Fragment key={singleProduct?.product_id}>
                                      <BookingSummaryComponent
                                        partnername={singleProduct?.facts?.vendor_name}
                                        orderID={bookingCode}
                                        description="Description to be added"
                                        telephone={singleProduct?.facts?.vendor_phone}
                                        referer={facts.referer}
                                        isPhoneAvail={singleProduct?.facts?.vendor_phone}
                                        email={singleProduct?.facts?.vendor_email}
                                        children={
                                          <>
                                            <div>
                                              <FiCheckCircle className="check-icon" />
                                            </div>
                                            <div>
                                              <h5
                                                style={{
                                                  color: "var(--clr-primary-1)",
                                                  margin: "0 0 0.5rem",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: englishLanguage ? guis?.title?.en : guis?.title?.de,
                                                }}
                                              />
                                            </div>
                                          </>
                                        }
                                      />
                                    </React.Fragment>
                                  );
                                } else return "";
                              })
                            );
                          } else return "";
                        });
                      })}
                  </React.Fragment>
                );
              } else return "";
            })}
          {/* {packages === [] ? "YES EMPTY" : "NAH WRONG ALGO"} */}
        </Wrapper>
      </WhiteBackground>
    </DesktopWrapper>
  );
};

const Wrapper = styled.div`
  background: var(--clr-summary-bg);

  .vw-header-text {
    color: white;
    top: 155px;
    position: absolute;
    z-index: 1;
    margin: 0 3rem;
  }

  section {
    margin: 0 auto;
    width: 100vw;

    h4 {
      color: var(--clr-bs-section-title);
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 90vw;
      margin: 0 auto;

      .icon {
        font-size: 2rem;
        padding-right: 0.5rem;
      }
    }

    .book-sharenow-container {
      height: 114px;
      box-shadow: var(--box-shadow);
      border-radius: var(--radius-components);
      padding: 0 15px;
      width: 90vw;
      margin: 0.5rem auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        div {
          h3 {
            color: var(--clr-bs-title);
            font-size: 14px;
            padding-bottom: 0.5rem;
          }

          p {
            font-size: 14px;
            color: var(--clr-bs-text);
          }
        }
      }

      img {
        width: 9rem;
        padding-right: 0.7rem;
      }

      .icon {
        color: var(--clr-secondary-1);
        font-size: 3rem;
        animation: myAnim 6s ease 0s infinite normal none;

        @keyframes myAnim {
          0%,
          100% {
            transform: translateX(0);
          }

          10%,
          30%,
          50%,
          70% {
            transform: translateX(-4px);
          }

          20%,
          40%,
          60% {
            transform: translateX(4px);
          }

          80% {
            transform: translateX(4px);
          }

          90% {
            transform: translateX(-4px);
          }
        }
      }
    }
  }

  @media screen and (min-width: 800px) {
    section {
      width: 50vw;
      margin: 0 auto;

      .book-sharenow-container {
        width: 50vw;
        margin: 1rem auto 1.5rem;
        padding-bottom: 15px;
      }
    }
  }
`;
export default FinalOverviewScreen;
