import styled from "styled-components";

// Btn Custom Width
export const CustomBtnWidth = styled.div`
  width: 100%;
  padding: 6px 15px;
  /* margin: 1rem auto; */
  position: relative;

  @media screen and (min-width: 800px) {
    width: 40vw;
    margin: 0 auto;
    padding: 0.5rem 0;
    /* padding: 2rem 0; */
    position: relative;
  }
`;
// End Btn Custom Width

// sticky button
export const ButtonSticky = styled.div`
  position: fixed;
  bottom: 20px;
  left: 5%;
  margin: 0 auto;
  width: 90%;
  border-radius: 25px;
  box-shadow: var(--box-shadow-btn-sticky);
  -webkit-box-shadow: var(--box-shadow-btn-sticky);
  -moz-box-shadow: var(--box-shadow-btn-sticky);

  @media screen and (min-width: 800px) {
    width: 40vw;
    margin: 0 auto;
    top: 90vh;
    left: 30%;
    height: 0;
  }
`;

export const DesktopWrapper = styled.div`
  @media screen and (min-width: 800px) {
    background: #e8e8e8;
    margin: 0 auto;
  }
`;

export const DesktopGradientWrapper = styled.div`
  @media screen and (min-width: 800px) {
    width: 60vw;
    margin: 0 auto;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 50%, #f4f4f4 95%, #ebebeb 100%);
  }
`;

export const SectionWrapper = styled.div`
  width: 95vw;
  margin: 0 auto;
  background: var(--generalBackground);

  @media screen and (min-width: 800px) {
    width: 50vw;
  }
`;

export const WhiteBackground = styled.div`
  @media screen and (min-width: 800px) {
    width: 60vw;
    background: var(--popup-bg);
    /* background: white; */
    margin: 0 auto;
  }
`;

type FormattedProps = {
  noWrap: boolean;
};

export const Formatted = styled.span.attrs(({ noWrap }: FormattedProps) => ({
  noWrap,
}))`
  ${({ noWrap }) => noWrap && "white-space: nowrap;"}
`;
