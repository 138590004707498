//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  david.koppenhoefer@banbutsu.com
//
// Display recommendation text provided by backend
//
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../State/store";
import { Loading } from ".";

type Props = {
  englishLanguage: boolean;
};

const AIRecommendation: React.FC<Props> = ({ englishLanguage }) => {
  const getAIRecommendation = useSelector((state: RootState) => {
    return state.getAIRecommendation;
  });

  const {
    success: successGetRecommendation,
    error: errorGetRecommendation,
    loading: loadingRecommendation,
    recommendation: recommendation,
  }: { success: boolean; error: boolean; loading: boolean; recommendation: any } = getAIRecommendation;

  const reco = successGetRecommendation ? recommendation[englishLanguage ? "en" : "de"] : "";

  return (
    <Wrapper>
      {loadingRecommendation ? (
        <Loading inline={true} />
      ) : (
        <div className="recom-container">
          <h4 dangerouslySetInnerHTML={{ __html: reco }} />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .recom-container {
    margin: 0 15px;
    h4 {
      margin: 0 5px;
      color: var(--inverse-paragraph-text);
    }
  }
  @media screen and (min-width: 800px) {
    .recom-container {
      width: 50vw;
      margin: 0px auto;
    }
  }
`;

export default AIRecommendation;
