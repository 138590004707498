import React, { useEffect, useCallback } from "react";
import styled from "styled-components";
import { DesktopWrapper, WhiteBackground, CustomBtnWidth } from "../Utils/styles";
import { Error, Button } from "../components";
import { getPromotionsAction } from "../State/ActionCreators/index";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../State/store";
import { envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";
import { UPDATE_FACTS } from "../State/ActionTypes/facts";
import { useNavigate } from "react-router-dom";

interface Props {
  onArrival?: () => void;
}

interface guis {
  widget: string;
  class: string;
  title: {
    de: string;
    en: string;
  };
  text: {
    de: string;
    en: string;
  };
  media: string[];
}

interface promotionsProps {
  promotion: {
    promotion_id: string;
    facts: any;
    guis: guis[];
  };
}

type factsGeneric<T> = {
  [key: string]: T;
};

const SplashScreen: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const facts: any = useSelector((state: RootState) => {
    return state.facts;
  }) as factsGeneric<any>;

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  const getPromotion = useSelector((state: RootState) => {
    return state.getPromotion;
  });

  const { loading, error, promotion }: { loading: boolean; error: boolean; promotion: promotionsProps } = getPromotion;

  const getButtonTextAndReferer = () => {
    const buttonText = promotion?.promotion?.guis?.reduce((acc, gui) => {
      if (gui?.text && (facts.language === "en" || facts.language === "de")) {
        const text = gui.text[facts.language as keyof typeof gui.text];
        return gui.widget === "button" && gui.class === "bottom-main" ? text : acc;
      } else {
        return acc;
      }
    }, "Start now");

    const referer = promotion?.promotion?.facts?.referer;

    return { buttonText, referer };
  };

  const { buttonText, referer } = getButtonTextAndReferer();

  const promotedFactExist = "promoted" in facts;

  useEffect(() => {
    setTimeout(() => {
      promotedFactExist &&
        dispatch(
          getPromotionsAction({
            facts: {
              ...facts,
            },
          })
        );
    }, 700);
    // eslint-disable-next-line
  }, [dispatch, facts]);

  // remember that we have seen the splash page now
  localStorage.setItem("splash_seen", "1");

  const position: React.CSSProperties | undefined = {
    position: "relative",
    background: "#e8e8e8",
  };

  const showLogo = getPartnerConfiguration(referer)?.SHOW_LOGO_ON_SPLASH;
  const showStartBtn = getPartnerConfiguration(referer)?.SHOW_START_BUTTON;

  return (
    <React.Fragment>
      <DesktopWrapper style={{ height: "100vh" }}>
        <WhiteBackground style={position}>
          {error && !loading ? (
            <>
              <ErrorLoadingWrapper>
                <Error
                  errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_3.png`}
                  headerText={
                    englishLanguage
                      ? "Sorry, a server problem has occurred"
                      : "Entschuldigung, es ist ein Serverproblem aufgetreten."
                  }
                  bodyText={englishLanguage ? "" : ""}
                />

                <CustomBtnWidth>
                  <Button
                    title="Reload"
                    onClick={() => {
                      window.location.reload();
                    }}
                  />
                </CustomBtnWidth>
              </ErrorLoadingWrapper>
            </>
          ) : (
            <React.Fragment>
              {facts?.referer === "mini" ||
                (!error && <LanguageComponent language={facts.language} referer={facts.referer} />)}

              {showLogo && <SplashLogo referer={facts.referer} />}

              {/* {!error && <LanguageComponent language={facts.language} referer={facts.referer} />} */}
              {promotion?.promotion?.guis?.map((gui: guis, idx: number) => {
                if (gui?.widget === "label" && gui?.class === "fill-screen") {
                  return gui.media.map((filePath: string) => {
                    const fileExt = filePath.split(".").pop();
                    if (fileExt === "png" || fileExt === "jpg") {
                      return (
                        <Wrapper key={idx}>
                          <div
                            className={gui?.class}
                            style={{
                              backgroundImage: `url(${envStaticHost}${gui?.media?.[0]}) `,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              height: "100vh",
                            }}
                          ></div>
                        </Wrapper>
                      );
                    } else {
                      return (
                        <Wrapper key={idx}>
                          <video autoPlay muted playsInline className={gui?.class} style={{}}>
                            <source src={`${envStaticHost}${gui?.media?.[0]}`} type="video/mp4" />
                          </video>
                        </Wrapper>
                      );
                    }
                  });
                }
                if (gui?.widget === "label" && gui?.class === "paragraph") {
                  return (
                    <Wrapper key={idx}>
                      <div className={gui?.class}>
                        <div className={facts.referer}>
                          <section className={facts.referer === "volkswagen" ? "paragraph_text_vw" : ""}>
                            {gui?.title && (
                              <h1
                                dangerouslySetInnerHTML={{
                                  __html: englishLanguage ? gui?.title?.en : gui?.title?.de,
                                }}
                              />
                            )}

                            {gui?.text && (
                              <h2
                                dangerouslySetInnerHTML={{
                                  __html: englishLanguage ? gui?.text?.en : gui?.text?.de,
                                }}
                              />
                            )}
                          </section>
                        </div>
                      </div>
                    </Wrapper>
                  );
                }

                if (gui?.widget === "label" && gui?.class === "bottom-paragraph") {
                  return (
                    <Wrapper key={idx}>
                      <div className={gui?.class}>
                        <section className={""}>
                          {gui?.text && (
                            <h2
                              dangerouslySetInnerHTML={{
                                __html: englishLanguage ? gui?.text?.en : gui?.text?.de,
                              }}
                            />
                          )}
                        </section>
                      </div>
                    </Wrapper>
                  );
                }

                if (gui?.widget === "label" && gui?.class === "legal") {
                  return (
                    <Wrapper key={idx}>
                      <div className={gui?.class}>
                        <section className={""}>
                          {gui?.text && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: englishLanguage ? gui?.text?.en : gui?.text?.de,
                              }}
                            />
                          )}
                        </section>
                      </div>
                    </Wrapper>
                  );
                }
              })}

              {promotion && showStartBtn && (
                <ButtonWrapper className="button-wrapper">
                  <CustomBtnWidth>
                    <ButtonStickySplash>
                      <Button title={buttonText} onClick={() => navigate(`/experience/${referer}/select`)} />
                    </ButtonStickySplash>
                  </CustomBtnWidth>
                </ButtonWrapper>
              )}
            </React.Fragment>
          )}
        </WhiteBackground>
      </DesktopWrapper>
    </React.Fragment>
  );
};

const LanguageComponent = ({ language, referer }: { language: string; referer: string }) => {
  const dispatch = useDispatch();

  /* Updating the state of the facts object. */
  const handleChange = useCallback(
    (key, value) => {
      dispatch({
        type: UPDATE_FACTS,
        payload: {
          [key]: value,
        },
      });
    },
    [dispatch]
  );

  return (
    <LanguageWrapper>
      <div className="language-header">
        <img
          src={`${envStaticHost}/static/${referer}/icon/black-round-world.svg`}
          alt="language"
          className="closeIcon"
          onClick={() => handleChange("language", language === "en" ? "de" : "en")}
        />
      </div>
    </LanguageWrapper>
  );
};

const SplashLogo = ({ referer }: { referer: string }) => {
  const logoWidth = getPartnerConfiguration(referer)?.LOGO_ON_SPLASH_WIDTH;

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        margin: "1rem",
        zIndex: 1000, // Ensure it's on top of other elements if necessary
      }}
    >
      <img
        src={`${envStaticHost}/static/${referer}/hero/splash_logo.svg`}
        alt="logo"
        style={{ width: `${logoWidth}`, height: "auto" }}
        // style={{ width: "auto", height: "auto" }}
      />
    </div>
  );
};

const ErrorLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background: white;
`;

const LanguageWrapper = styled.div`
  position: relative;
  width: 90%;
  margin: 0 auto;

  div {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 1;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  @media screen and (min-width: 800px) {
    left: 0;
  }
`;

// replicating ButtonSticky positioning of styles.ts
// The button at the bottom should be in the same place on all pages (bbt-1788)
const ButtonStickySplash = styled.div`
  position: fixed;
  bottom: 20px;
  left: 5%;
  margin: 0 auto;
  width: 90%;

  @media screen and (min-width: 800px) {
    width: 40vw;
    margin: 0 auto;
    top: 90vh;
    left: 30%;
    height: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  h1,
  h2 {
    width: 90vw;
    color: var(--clr-white);
  }

  .manx {
    h1 {
      position: absolute;
      top: 15px;
      left: 5%;
      font-family: "FormulaCondensed Bold";
      font-size: 2rem;
      line-height: 1;
      text-transform: uppercase;
      width: 80vw;
    }
    h2 {
      position: absolute;
      top: 50px;
      left: 5%;
      font-family: "FormulaCondensed Bold";
      font-size: 4rem;
      line-height: 1;
      text-transform: uppercase;
      width: 80vw;
    }
  }

  .etravel_mercedes {
    h1 {
      position: absolute;
      top: 15px;
      left: 5%;
      font-family: var(--headerText);
      font-size: 2.5rem;
      line-height: 1;
      text-transform: capitalize;
      width: 80vw;
      font-weight: normal;
    }
    h2 {
      position: absolute;
      top: 50px;
      left: 5%;
      font-family: var(--headerText);
      font-size: 2.5rem;
      line-height: 1;
      text-transform: capitalize;
      width: 80vw;
      font-weight: normal;
    }
  }

  .etravel_geo {
    h1 {
      position: absolute;
      top: 15px;
      left: 5%;
      font-family: "HelveticaNeue";
      font-size: 2.5rem;
      line-height: 1;
      width: 80vw;
      font-weight: bold;
      color: black;
      line-height: 1.3;

      p {
        font-size: 35px;
        line-height: 1.5;
        color: black;
        font-weight: bold;
      }
    }

    h2 {
      position: absolute;
      top: 50px;
      left: 5%;
      font-family: var(--headerText);
      font-size: 2rem;
      line-height: 1;
      width: 80vw;
      font-weight: normal;
      color: black;
    }
  }

  .etravel_audi {
    h1 {
      position: absolute;
      top: 15px;
      left: 5%;
      font-family: "AudiTypeBold";
      font-size: 2rem;
      line-height: 1;
      text-transform: capitalize;
      width: 80vw;
      font-weight: normal;
    }
    h2 {
      position: absolute;
      top: 50px;
      left: 5%;
      font-family: "AudiTypeBold";
      font-size: 2rem;
      line-height: 1;
      text-transform: capitalize;
      width: 80vw;
      font-weight: normal;
    }
  }

  .fill-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0 auto;
    text-align: left;
    background: #005478;
  }

  .paragraph {
    text-align: center;
    width: 100%;
    position: relative;
    top: 10vh;

    h2 {
      width: 90vw;
      margin: 0 auto 1rem;
      text-align: left;
    }

    h1 {
      text-align: left;
      width: 90vw;
      margin: 0 auto;
    }

    .paragraph_text_vw {
      color: var(--clr-white);

      h1 {
        position: absolute;
        top: 120px;
        left: 5%;
        color: var(--clr-white);
        font-size: 2rem;
      }

      h2 {
        position: absolute;
        top: 10px;
        left: 5%;
        color: var(--clr-white);
        font-size: 2rem;
      }
    }
  }

  .bottom-paragraph {
    width: 100%;
    text-align: center;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 22px;

    h2 {
      width: 90%;
      margin: 0 auto;
      padding-bottom: 0.5rem;
    }
  }

  .legal {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    position: absolute;
    bottom: -1000px;

    h3,
    p {
      width: 90vw;
      margin: 0 auto;
      color: var(--clr-secondary-gry);

      a {
        color: var(--clr-white);
        text-decoration: underline;
      }
    }
  }

  @media screen and (min-width: 800px) {
    h2,
    h1 {
      width: 60vw;
      padding: 0 15px;
    }

    .fill-screen {
      /* height: 100vh; */
    }

    .paragraph {
      top: 15vh;

      h1,
      h2 {
        width: 90%;
      }
    }

    .bottom-paragraph {
    }

    .legal {
      p {
        width: 90%;
        margin: 0.5rem auto;

        a {
          color: var(--clr-white);
          text-decoration: underline;
        }
      }
    }
  }
`;

export default SplashScreen;
