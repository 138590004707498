import React from "react";
import { Error } from ".";
import { envStaticHost } from "../Utils/windowHelper";

type Props = {
  englishLanguage: boolean;
  noPackagesToDisplay: boolean;
  referer: string;
};

const SignupNewsLetter: React.FC<Props> = ({ englishLanguage, noPackagesToDisplay, referer }) => {
  return (
    <div style={{ padding: "2.5rem 0" }}>
      {noPackagesToDisplay && (
        <>
          <Error
            errorImage={`${envStaticHost}/static/${referer}/error/Empty1.png`}
            bodyText={
              englishLanguage
                ? "Select your interest(s) and get matching recommendations."
                : "Bleib auf dem Laufenden und wir informieren Dich, wenn neue Aktivitäten verfügbar sind."
            }
          />
        </>
      )}
    </div>
  );
};

export default SignupNewsLetter;
