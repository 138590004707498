//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is one of the few components used in the bbt platform
// this component is used on the final booking screen, this acts as a card
// for each booking summary
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";
import { BiPhone, BiMailSend } from "react-icons/bi";
import { Button } from "../components/index";
import { useSelector } from "react-redux";
import { RootState } from "../State/store";
import { StampBox } from "./Stamp";

interface Props {
  partnername: string;
  orderID?: string;
  description?: string;
  telephone?: string;
  referer: string;
  isPhoneAvail: string;
  email: string;
}

/* This is a React component that is used to display a booking summary. */
const BookingSummaryComponent: React.FC<Props> = ({
  partnername,
  orderID,
  telephone,
  referer,
  children,
  isPhoneAvail,
  email,
}) => {
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });
  const linearStamp = referer === "volkswagen" || referer === "manx" || referer === "etravel_mercedes";

  return (
    <Wrapper>
      <StampBox linear={linearStamp}>
        <article>
          <div className="partner-info">
            <h1 style={{ fontSize: "14px" }}>{partnername}</h1>
            <h2 style={{ fontSize: "12px" }}>ORDER ID: {orderID}</h2>
          </div>

          <div className="description-container">
            <p>
              {englishLanguage
                ? "You have booked the following equipment for your activity:"
                : "Du hast folgende Leistung für ein unvergessliches Erlebnis gebucht:"}
            </p>
          </div>

          <div className="order-details-container">{children}</div>

          <div className="btn-container">
            {isPhoneAvail ? (
              <Button
                children={<BiPhone className="text-icons" />}
                title={englishLanguage ? "call" : "ANRUFEN"}
                onClick={() => window.open(`tel:${telephone}`)}
              />
            ) : (
              <Button
                children={<BiMailSend className="text-icons" />}
                title={englishLanguage ? "email" : "E-mail"}
                onClick={() => window.open(`mailto:${email || "support@banbutsu.com"}`)}
              />
            )}
          </div>
          <div className="description-container">
            {" "}
            <p>
              {englishLanguage
                ? "Your booking is confirmed and we will shortly send you a confirmation via email. You will receive the invoice from the service provider when picking up your equipment."
                : "Deine Buchung ist bestätigt und wir senden Dir in Kürze eine Bestätigung per E-Mail. Die Rechnung des Dienstleisters erhältst Du bei Abholung der Ausrüstung:"}
              <br />
              <br />
              {englishLanguage
                ? " If you wish to cancel the booking or have any further questions, please call the number above."
                : "Wenn Du die Buchung stornieren möchtest oder weitere Fragen hast, rufe bitte die oben genannte Nummer an."}
            </p>
          </div>
        </article>
      </StampBox>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 90vw;
  margin: 20px auto;
  position: relative;
  height: inherit;

  .order-details-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .check-icon {
      color: var(--clr-bs-check-icon);
      font-size: 1.5rem;
      margin: 0 0.5rem 0 0;
    }
  }

  article {
    padding: 0 1rem 2.5rem;
    background: var(--clr-summary-bg);

    h5 {
      font-size: 14px;
      margin: 0.5rem 0;
      color: var(--clr-bs-subtitle);
    }

    h3 {
      font-size: 14px;
      color: var(--clr-white);
      margin: 0.5rem 0;
    }

    .partner-info {
      h1 {
        color: var(--clr-bs-title);
        font-size: 20px;
        margin: 0 0 0.5rem;
      }
      h2 {
        font-size: 20px;
        color: var(--clr-bs-subtitle);
      }
    }

    .logo {
      width: 68px;
      filter: invert(68%) sepia(31%) saturate(453%) hue-rotate(153deg) brightness(86%) contrast(83%);
    }

    h2 {
      font-size: 10px;
    }

    .btn-container {
      width: 100%;
      height: 3rem;
      margin: 1.5rem auto;

      .btn {
        padding: 0;
        letter-spacing: 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        .text-icons {
          font-size: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }

    .description-container {
      margin: 1.5rem 0;

      p {
        color: var(--clr-bs-text);
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }

  @media screen and (min-width: 800px) {
    padding: 0 0 2rem;
    width: 50vw;
    margin: 20px auto 0;

    article {
      margin: 0 auto 2rem;

      .btn-container {
        width: 70%;
      }
    }
  }
`;
export default BookingSummaryComponent;
