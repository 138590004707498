import React, { useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../State/store";
import styled from "styled-components";
import { Button } from "../components";
import { getRoleLoginAction, globalFactsAction } from "../State/ActionCreators";

const ConfigureFactsScreen = () => {
  const dispatch = useDispatch();

  // login ---
  //
  const userval = useRef("");
  const passval = useRef("");
  const submitLogin = useCallback(() => {
    dispatch(
      getRoleLoginAction({
        user: userval.current,
        password: passval.current,
        role: "operator.facts",
      })
    );
    return false;
  }, [dispatch]);

  // login token state
  const getRoleLoginToken = useSelector((state: RootState) => {
    return state.getRoleLoginToken;
  });

  const {
    loading: loginLoading,
    error: loginError,
    token: loginToken,
  }: {
    loading: boolean;
    error: string;
    token: any;
  } = getRoleLoginToken;

  let fv: { [key: string]: string } = {};
  const factsval = useRef(fv);
  const submitFacts = useCallback(() => {
    //console.log("submit facts", factsval.current)
    dispatch(globalFactsAction(factsval.current, loginToken.token));
    return false;
  }, [dispatch, loginToken]);

  // global facts state
  const globalFacts = useSelector((state: RootState) => {
    return state.globalFacts;
  });
  const {
    loading: globalFactsLoading,
    error: globalFactsError,
    data: globalFactsData,
  }: {
    loading: boolean;
    error: string;
    data: string;
  } = globalFacts;

  //console.log("globalFactsLoading", globalFactsLoading, "globalFactsError", globalFactsError, "globalFactsData: '" + JSON.stringify(globalFactsData) + "'")

  // if we're logged in and there's no globalFacts data, get it already
  if (!loginError && loginToken && !globalFactsData && !globalFactsLoading && !globalFactsError) {
    dispatch(globalFactsAction({}, loginToken.token));
  }

  return (
    <Wrapper>
      <h2>Global Facts Configuration</h2>

      {!loginError && loginToken ? (
        <>
          {globalFactsError && (
            <>
              {globalFactsError}
              <br />
            </>
          )}
          {globalFactsData && (
            <>
              <h3>Global Facts</h3>
              <form>
                {Object.entries(globalFactsData).map(([fact, value]) => (
                  <div key={fact} className="row">
                    <div className="left">{fact}:</div>
                    <div className="right">
                      <input
                        type="text"
                        name={fact}
                        id={fact}
                        defaultValue={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => (factsval.current[fact] = e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </form>
              <div className="row">
                <div className="left" />
                <div className="right">
                  <button onClick={submitFacts}>submit</button>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <h3>Login</h3>
          {loginError && (
            <>
              {loginError}
              <br />
            </>
          )}
          <form>
            <div className="row">
              <div className="left">User:</div>
              <div className="right">
                <input
                  type="text"
                  name="user"
                  id="user"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => (userval.current = e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="left">Password:</div>
              <div className="right">
                <input
                  type="password"
                  name="password"
                  id="password"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => (passval.current = e.target.value)}
                />
              </div>
            </div>
          </form>
          <div className="row">
            <div className="left" />
            <div className="right">
              <button onClick={submitLogin}>log in</button>
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #efeada;
  color: #001133;
  width: 600px;

  h2,
  h3 {
    padding: 0 0 8px 0;
  }
  .row {
    display: flex;
  }
  .left {
    flex-basis: 20%;
    text-align: right;
    padding: 0 6px 6px 0;
  }
`;

export default ConfigureFactsScreen;
