//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components displays the end user vehicle, according to the data gotten
//  from referer and background
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useState } from "react";
import styled from "styled-components";
import { DesktopWrapper } from "../Utils/styles";
import { envVANILLA, envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";

interface Props {
  title?: string | undefined;
  // colorChange?: string;
  showArrowAndCloseBar?: boolean;
  showCloseBar: boolean;
  navigationRoute?: () => void;
  showLanguageBar: boolean; // showLanguageBar also acts as a trigger for location bar
  vehicle: string;
  language?: "de" | "en";
  business_area: string;
  onChange?: (key: string, value: string) => void;
  cityBackdropAction?: boolean;
  defaultBackdropAction?: boolean;
  defaultBackdropImage?: string;
  isFinalOverviewPage: boolean;
  isLandingPage: boolean;
  isTimelinePage?: boolean;
  isSummaryPage?: boolean;
  flow?: string;
  category?: string;
  referer: string;
  facts: string;
}

const Hero: React.FC<Props> = ({
  // colorChange,
  showCloseBar = false,
  navigationRoute,
  showLanguageBar = false,
  onChange,
  language,
  vehicle,
  business_area,
  isFinalOverviewPage,
  isLandingPage,
  isTimelinePage,
  isSummaryPage = false,
  flow,
  category,
  referer,
  facts,
}) => {
  /* Checking if the envURL is a demo environment. */
  // const demoEnvironment = envURL?.substring(8, 12) === "stag";

  /* Checking if the flow is 0, if it is, then it is setting the vanillaUser to true. */
  let vanillaUser = flow === "0";

  /* reading title text from partner config.
   * TODO language */
  const partnerConfig = getPartnerConfiguration(referer);
  var title = partnerConfig["HERO_LANDING_TITLE"];
  // titles other than HERO_TITLE are optional
  if (isSummaryPage && partnerConfig["HERO_SUMMARY_TITLE"]) {
    title = partnerConfig["HERO_SUMMARY_TITLE"];
  }
  if (isTimelinePage && partnerConfig["HERO_TIMELINE_TITLE"]) {
    title = partnerConfig["HERO_TIMELINE_TITLE"];
  }
  if (isFinalOverviewPage && partnerConfig["HERO_FINAL_OVERVIEW_TITLE"]) {
    title = partnerConfig["HERO_FINAL_OVERVIEW_TITLE"];
  }

  const hideVehicleOnFinalPage = getPartnerConfiguration(referer).HIDE_VEHICLE_ON_FINAL_OVERVIEW_PAGE;
  const hideVehicle = isFinalOverviewPage && hideVehicleOnFinalPage;

  const showLogo = getPartnerConfiguration(referer)?.SHOW_LOGO_ON_SPLASH;

  return (
    <DesktopWrapper>
      <Wrapper>
        <section className={referer}>
          {showLogo && <BenzLogo referer={referer} />}

          <div className="heroBackground">
            <Banner partnerConfig={partnerConfig} facts={facts} />
          </div>

          <div className="closeBtnContainer">
            {/* ************************************************************ */}
            <div className="showLanguageBar">
              {showLanguageBar && (
                <div className="language-header">
                  <img
                    src={`${envStaticHost}/static/${referer}/icon/black-round-world.svg`}
                    alt="language"
                    className="closeIcon"
                    onClick={() => onChange && onChange("language", language === "en" ? "de" : "en")}
                  />
                </div>
              )}
            </div>
            {/* ************************************************************ */}

            {/* ************************************************************ */}
            <div className="showClosebar">
              {showCloseBar && (
                <img
                  src={`${envStaticHost}/static/${referer}/icon/black-round-backarrow.svg`}
                  alt=""
                  className="closeIcon"
                  onClick={navigationRoute}
                />
              )}
            </div>
            {/* ************************************************************ */}
          </div>

          {/* navigate back to vanilla icon */}
          {vanillaUser && isLandingPage && (
            <>
              <img
                src={`${envStaticHost}/static/${referer}/icon/black-round-location.svg`}
                alt="backtocity"
                className="vanilla-link-icon "
                onClick={() => (window.location.href = `${envVANILLA}?l=${language}`)}
              />
            </>
          )}

          {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}

          <>
            <div className="rentalCarContainer">
              {!hideVehicle && (
                <img src={`${envStaticHost}${partnerConfig.HERO_LANDING_IMAGE(facts)}.png`} alt="hero" />
              )}
            </div>
          </>

          {/* ************************************************************ */}
          {!isFinalOverviewPage ? (
            <div className="bbt-tm">
              <p>
                Powered by <strong>banbutsu</strong>
              </p>
            </div>
          ) : (
            <div className="bbt-tm">
              <p style={{ color: "white" }}>
                Powered by <strong>banbutsu</strong>
              </p>
            </div>
          )}
          {/* ************************************************************ */}
        </section>
      </Wrapper>
    </DesktopWrapper>
  );
};

const Banner = ({ partnerConfig, facts }: { partnerConfig: any; facts: any }) => {
  const [format, setFormat] = useState<string>("jpg");

  const src = `${envStaticHost}${`${partnerConfig.HERO_LANDING_BANNER(facts)}.${format}`}`;

  const handleError = () => {
    if (format === "jpg") {
      setFormat("png");
    }
  };

  return (
    <React.Fragment>
      <img src={src} alt="banner" onError={() => handleError()} />
    </React.Fragment>
  );
};

const BenzLogo = ({ referer }: { referer: string }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        margin: "1rem",
        zIndex: 1000,
      }}
    >
      <img
        src={`${envStaticHost}/static/${referer}/hero/Logo_MercedesBenz.svg`}
        alt="logo"
        style={{ width: "50px", height: "50px" }}
      />
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  object-fit: contain;
  overflow: hidden;

  .manx {
    height: inherit;
    width: auto;

    @media screen and (min-width: 800px) {
      height: inherit;
    }

    h1 {
      font-size: 3rem;
      font-family: "FormulaCondensed Bold";

      span {
        font-size: 3rem;
        font-family: "FormulaCondensed Bold";
      }

      width: 70%;
      margin: 0;
      position: absolute;
      top: 30%;
      color: var(--inverse-text);
      padding: 0 15px;
    }

    .heroBackground {
      img {
        width: 100%;
        margin-bottom: -5%;
        margin-top: -5%;
      }
      @media screen and (min-width: 800px) {
        margin-bottom: -15%;
      }
    }

    .rentalCarContainer {
      width: 100%;
      z-index: 1;
      text-align: right;
      position: relative;
      font-family: "FormulaCondensed Bold";

      img {
        width: 50%;
        margin-top: -50%;

        @media screen and (min-width: 800px) {
          width: 70%;
        }
      }
    }

    .bbt-tm {
      display: none;
    }

    .closeBtnContainer {
      position: absolute;
      top: 0;
      z-index: 99;

      .closeIcon {
        height: 2rem;
        width: 2rem;
        margin: 1rem 0 1rem 1rem;
      }
    }

    .vanilla-link-icon {
      position: absolute;
      top: 0;
      margin: 1rem;
      right: 0;
      height: 2rem;
      width: 2rem;
      background: var(--clr-black);
      border-radius: var(--radius-50);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .volkswagen {
    height: inherit;
    width: auto;

    @media screen and (min-width: 800px) {
      height: inherit;
    }

    h1 {
      span {
        font-size: 16px;
      }

      width: 70%;
      margin: 0;
      position: absolute;
      top: 30%;
      color: var(--inverse-text);
      padding: 0 15px;
    }

    .heroBackground {
      img {
        width: 100%;
        margin-bottom: -10%;
        margin-top: -5%;
      }
      @media screen and (min-width: 800px) {
        margin-bottom: -15%;
      }
    }

    .rentalCarContainer {
      width: 100%;
      z-index: 1;
      text-align: right;
      position: relative;

      img {
        width: 80%;
        margin-top: -50%;

        @media screen and (min-width: 800px) {
          width: 55%;
        }
      }
    }

    .bbt-tm {
      display: none;
    }

    .closeBtnContainer {
      position: absolute;
      top: 0;
      z-index: 99;

      .closeIcon {
        height: 2rem;
        width: 2rem;
        margin: 1rem 0 1rem 1rem;
      }
    }

    .vanilla-link-icon {
      position: absolute;
      top: 0;
      margin: 1rem;
      right: 0;
      height: 2rem;
      width: 2rem;
      background: var(--clr-black);
      border-radius: var(--radius-50);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .sharenow,
  .sandbox,
  .ces24 {
    height: inherit;
    padding-top: 15px;

    @media screen and (min-width: 800px) {
      height: inherit;
      width: 60vw;
    }

    h1 {
      display: none;
    }

    .heroBackground {
      img {
        width: 100%;
        margin-bottom: -10%;
        margin-top: -5%;
      }
    }

    .rentalCarContainer {
      width: 100%;
      z-index: 1;
      text-align: center;
      position: relative;

      img {
        width: 75%;
        margin-top: -25%;
        margin-bottom: -12%;

        @media screen and (min-width: 800px) {
          position: absolute;
          width: 40%;
          top: 50%;
          left: 60%;
          transform: translate(-5%, 5%);
        }
      }
    }

    .bbt-tm {
      width: 80%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0;

      @media screen and (min-width: 800px) {
        width: 50vw;
        margin: 0 auto;
      }

      p {
        font-size: 10px;
        margin: 0;

        strong {
          font-weight: bold;
        }
      }
    }

    .closeBtnContainer {
      position: absolute;
      top: 0;
      z-index: 99;

      .closeIcon {
        height: 2rem;
        width: 2rem;
        margin: 1rem 0 1rem 1rem;
      }
    }

    .vanilla-link-icon {
      position: absolute;
      top: 0;
      margin: 1rem;
      right: 0;
      height: 2rem;
      width: 2rem;
      background: var(--clr-black);
      border-radius: var(--radius-50);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mini {
    height: inherit;
    overflow: hidden;
    background: var(--clr-white);

    @media screen and (min-width: 800px) {
      height: 290px;
      position: relative;
    }

    h1 {
      color: white;
      position: absolute;
      top: 38%;
      left: 5%;
      width: 60%;
      transform: translate(-5%, -50%);
    }

    .heroBackground {
      object-fit: contain;

      img {
        width: 100%;
        margin-bottom: -10%;
        margin-top: -5%;

        @media screen and (min-width: 800px) {
          width: 100%;
          height: 290px;
          object-fit: fill;
        }
      }
    }

    .rentalCarContainer {
      text-align: right;
      position: relative;

      @media screen and (min-width: 800px) {
        text-align: right;
        width: 50vw;
        margin: 0 auto;
      }

      img {
        width: 42%;
        margin-top: -30%;

        @media screen and (min-width: 800px) {
          position: absolute;
          top: 50%;
          left: 60%;
          transform: translate(15%, 15%);
          overflow: hidden;
        }
      }
    }

    .closeBtnContainer {
      position: absolute;
      top: 0;
      z-index: 99;

      .showLanguageBar {
        display: none;
      }

      .closeIcon {
        height: 2rem;
        width: 2rem;
        margin: 1rem 0 1rem 1rem;
      }
    }

    .bbt-tm {
      display: none;
    }
  }

  .etravel_mercedes,
  .etravel_geo {
    height: inherit;
    width: auto;
    margin-bottom: -10px;

    @media screen and (min-width: 800px) {
      height: inherit;
    }

    h1 {
      font-size: 2rem;
      font-family: "CorpoA";
      font-weight: normal;

      span {
        font-size: 2rem;
        font-family: "CorpoA";
      }

      width: 70%;
      margin: 0;
      position: absolute;
      top: 25%;
      color: white;
      padding: 0 15px;
    }

    .heroBackground {
      img {
        width: 100%;
        margin-bottom: -5%;
        margin-top: -5%;
      }
      @media screen and (min-width: 800px) {
        margin-bottom: -15%;
      }
    }

    .rentalCarContainer {
      width: 100%;
      z-index: 1;
      text-align: right;
      position: relative;
      font-family: "FormulaCondensed Bold";

      img {
        width: 50%;
        margin-top: -50%;

        @media screen and (min-width: 800px) {
          width: 70%;
        }
      }
    }

    .bbt-tm {
      display: none;
    }

    .closeBtnContainer {
      position: absolute;
      top: 0;
      z-index: 99;

      .closeIcon {
        height: 2rem;
        width: 2rem;
        margin: 1rem 0 1rem 1rem;
      }
    }

    .vanilla-link-icon {
      position: absolute;
      top: 0;
      margin: 1rem;
      right: 0;
      height: 2rem;
      width: 2rem;
      background: var(--clr-black);
      border-radius: var(--radius-50);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .etravel_audi {
    height: inherit;
    overflow: hidden;
    background: var(--clr-white);

    @media screen and (min-width: 800px) {
      height: 290px;
      position: relative;
    }

    h1 {
      color: white;
      position: absolute;
      top: 38%;
      left: 5%;
      width: 60%;
      transform: translate(-5%, -50%);
    }

    .heroBackground {
      object-fit: contain;

      img {
        width: 100%;
        margin-bottom: -10%;
        margin-top: -5%;

        @media screen and (min-width: 800px) {
          width: 100%;
          height: 290px;
          object-fit: fill;
        }
      }
    }

    .rentalCarContainer {
      display: none;
      text-align: right;
      position: relative;

      @media screen and (min-width: 800px) {
        text-align: right;
        width: 50vw;
        margin: 0 auto;
      }

      img {
        width: 300px;
        margin-top: -30%;

        @media screen and (min-width: 800px) {
          position: absolute;
          top: 50%;
          left: 60%;
          transform: translate(15%, 15%);
          overflow: hidden;
        }
      }
    }

    .closeBtnContainer {
      position: absolute;
      top: 0;
      z-index: 99;

      .showLanguageBar {
        /* display: none; */
      }

      .closeIcon {
        height: 2rem;
        width: 2rem;
        margin: 1rem 0 1rem 1rem;
      }
    }

    .bbt-tm {
      display: none;
    }
  }

  @media screen and (min-width: 800px) {
    width: 60vw;
    margin: 0 auto;
  }
`;
export default Hero;
