//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is one of the most important components, which is literally on every page
// This button holds different props which handles navigation from landing page to
// final booking
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import styled from "styled-components";

interface Props {
  title: JSX.Element | string | undefined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  inactive?: any;
  image?: string;
  disable?: any;
  type?: "button" | "submit";
}

/**
 * Button is a React.FC component that takes in a title, onClick, inactive, children, disable, and type
 * props and returns a Wrapper component that contains a ButtonWrapper component that has a className
 * of either btn or btn-inactive, an onClick event handler that calls the onClick prop, a disabled prop
 * that is set to the disable prop, and a type prop that is set to the type prop
 * @param  - title - The text that will be displayed on the button
 * @returns A button with a title, onClick, inactive, children, disable, and type.
 */
const Button: React.FC<Props> = ({ title, onClick, inactive = true, children, disable = false, type = "button" }) => {
  return (
    <Wrapper>
      <ButtonWrapper className={inactive ? "btn" : "btn btn-inactive"} onClick={onClick} disabled={disable} type={type}>
        {children}
        {title}
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 99;
`;

const ButtonWrapper = styled.button.attrs((props) => {
  return { type: props.type || "button" };
})``;

export default Button;
