//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  david.koppenhoefer@banbutsu.com
//
// this is the view-locations button shown on the package page
//-----------------------------------------------------------------------------

import React, { CSSProperties } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";

interface Props {
  link: string;
  englishLanguage: boolean;
  referer: string;
}

const ViewLocationsBtn: React.FC<Props> = ({ link, englishLanguage, referer }) => {
  let navigate = useNavigate();

  const configuration = getPartnerConfiguration(referer);

  const showMapBg = configuration && configuration.SHOW_MAP_BACKGROUND;

  const bgImage = `${envStaticHost}/static/${referer}/icon/view-locations-backdrop.png`;

  const backdropStyle = {
    backgroundImage: `url(${showMapBg ? bgImage : ""})`,
    backgroundSize: "cover",
  } as CSSProperties;

  return (
    <Wrapper>
      <div className={referer}>
        <div
          className="location-button"
          style={backdropStyle}
          onClick={() => {
            navigate(`${link}`);
          }}
        >
          <div className="location-button-icon">
            <img src={`${envStaticHost}/static/${referer}/icon/black-outlined-location.svg`} alt="location" />
          </div>
          <div className="location-button-text">
            <h5>{englishLanguage ? "View Locations" : "Karte Anzeigen"}</h5>
          </div>
          <div className="location-button-right-arrow">
            <MdArrowForwardIos />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 5px 15px;
  position: relative;

  @media screen and (min-width: 800px) {
    padding: 0;
  }

  .volkswagen,
  .sharenow,
  .sandbox,
  .etravel_audi,
  .ces24,
  .mini {
    .location-button {
      background: var(--clr-white);
      height: var(--location-height);
      border-radius: var(--radius-components);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1.5rem 0;
      padding: 0 15px 0 5px;
      box-shadow: var(--box-shadow);
      color: var(--clr-primary-1);

      h5 {
        color: var(--clr-primary-1);
        font-family: var(--p-bold);
      }

      @media screen and (min-width: 800px) {
        height: 5rem;
        width: 99%;
        margin: 0 auto;
      }

      .location-button-icon {
        height: 1.8rem;
        width: 1.8rem;
        margin: 1rem 1rem 1.2rem 1rem;
        filter: var(--clr-primary-1-filter);
      }

      .location-button-text {
        width: 100%;
      }

      .location-button-right-arrow {
        font-size: 1.6rem;
        margin: 0.3rem 0 0 0;
      }
    }
  }

  .manx {
    .location-button {
      background: var(--clr-primary-1);
      height: var(--location-height);
      border-radius: var(--radius-components);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1.5rem 0;
      padding: 0 15px 0 5px;
      box-shadow: var(--box-shadow);
      color: var(--clr-white);

      h5 {
        color: var(--clr-white);
        font-family: var(--p-bold);
      }

      @media screen and (min-width: 800px) {
        height: 5rem;
        width: 99%;
        margin: 0 auto;
      }

      .location-button-icon {
        height: 1.8rem;
        width: 1.8rem;
        margin: 1rem 1rem 1.2rem 1rem;
        filter: var(--clr-primary-1-filter);
      }

      .location-button-text {
        width: 100%;
      }

      .location-button-right-arrow {
        font-size: 1.6rem;
        margin: 0.3rem 0 0 0;
      }
    }
  }

  .etravel_mercedes,
  .etravel_geo {
    .location-button {
      background: var(--clr-primary-1);
      height: var(--location-height);
      border-radius: var(--radius-components);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1.5rem 0;
      padding: 0 15px 0 5px;
      box-shadow: var(--box-shadow);
      color: var(--clr-white);

      h5 {
        color: var(--clr-white);
        font-family: var(--p-bold);
        text-decoration: lowercase;
      }

      @media screen and (min-width: 800px) {
        height: 5rem;
        width: 99%;
        margin: 0 auto;
      }

      .location-button-icon {
        height: 1.8rem;
        width: 1.8rem;
        margin: 1rem 1rem 1.2rem 1rem;
        filter: var(--clr-primary-1-filter);
      }

      .location-button-text {
        width: 100%;
      }

      .location-button-right-arrow {
        font-size: 1.6rem;
        margin: 0.3rem 0 0 0;
      }
    }
  }
`;

export default ViewLocationsBtn;
