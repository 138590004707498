import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Footer } from "./components/index";
import {
  LandingPageScreen,
  TimelinePage,
  ConfirmationScreen,
  PaymentScreen,
  FinalOverviewScreen,
  ErrorScreen,
  SummaryInformationScreen,
  ProductPageScreen,
  SplashScreen,
  ProductLocationScreen,
  Main,
  ConfigureFactsScreen,
  StatusPage,
} from "./Screens/index";
import { useEffect, useState } from "react";
import { getLoginAction } from "./State/ActionCreators";
import { useDispatch, useSelector } from "react-redux";
import { SET_FACTS } from "./State/ActionTypes/facts";
import { RootState } from "./State/store";

const createHash = (text: string): string => {
  let hash = 0,
    i,
    chr;
  if (text.length === 0) return "";
  for (i = 0; i < text.length; i++) {
    chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString();
};

function App() {
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // guest.get(key, callback);
    /* Getting the token from the localStorage of the vanilla domain. */
    // vanillaStorage.get("token", (error: boolean, value: string) => {
    //   // value for the key of 'token' will be retrieved from localStorage on vanilla
    //   if (error) console.log(error);
    //   localStorage.setItem("token", value);
    // });

    /* This is used to restart the booking process. */
    const isRestart = localStorage.getItem("restart");

    /* Used to check if the user is a new user or not. */
    const ucHash = localStorage.getItem("uc_hash");

    const cookiefacts = JSON.parse(localStorage.getItem("cookiefacts") as string);

    var facts = cookiefacts?.facts;

    const hash = createHash(localStorage.getItem("cookiefacts") ?? "");

    // If no facts are set it's a new user
    // or cookiefacts hash is not equal to currently set hash it's another booking

    if (!facts || ucHash !== hash) {
      localStorage.setItem("uc_hash", hash);

      dispatch(getLoginAction());

      dispatch({
        type: SET_FACTS,
        payload: cookiefacts?.facts || null, // avoid the dangerous undefined payload
      });
    } else {
      /* This is used to restart the booking process. */
      if (isRestart) {
        // console.log("else");
        dispatch(getLoginAction());
      }

      dispatch({
        type: SET_FACTS,
        payload: facts,
      });
    }

    localStorage.removeItem("restart");

    setReady(true);
  }, [dispatch]);

  const { englishLanguage, referer } = useSelector((state: RootState) => ({
    englishLanguage: state.facts.language === "en",
    referer: state.facts.referer,
  }));

  useEffect(() => {
    document.title =
      referer === "mini"
        ? `Spike by MINI | Demo`
        : `${englishLanguage ? "Design Your Trip" : "Plane Deinen Trip"} | banbutsu & ${referer}`;
  }, [englishLanguage, referer]);

  if (!ready) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to={`/experience/${referer}`} />} />
        <Route path={`/experience/${referer}`} element={<Main />} />

        {/* ROUTE */}
        {/* <Route path="/experience/:partnerID"> */}
        <Route path={`/experience/${referer}`}>
          <Route path="select" element={<LandingPageScreen />} />
          <Route path="promoted" element={<SplashScreen />} />
          <Route path="packages/:packageid" element={<TimelinePage />} />

          <Route path="packages/:packageid">
            <Route path="product/:productid" element={<ProductPageScreen />} />
            <Route path="summary-information" element={<SummaryInformationScreen />} />

            <Route path="payment-gateway" element={<PaymentScreen />} />
            <Route path="confirmation" element={<ConfirmationScreen />} />
            <Route path="final-overview" element={<FinalOverviewScreen />} />
            <Route path="product-locations" element={<ProductLocationScreen />} />
          </Route>
        </Route>
        <Route path="/booking/:code" element={<StatusPage />} />
        {/* END  OF SEASONAL ROUTE */}

        {/* global facts admin panel addition */}
        <Route path={"/configure/facts"} element={<ConfigureFactsScreen />} />

        <Route path="*" element={<ErrorScreen />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
