//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components holds values like location, start and end date of the trip
// which is being used on the landing page and product screen
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled, { createGlobalStyle } from "styled-components";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/es";
import { differenceInCalendarDays } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../State/store";
import { BsChevronDown } from "react-icons/bs";
import { ProductPageDateCard } from "../components";

interface dateTimeProps {
  allowDatePicker: boolean | undefined;
  minDate: Date;
  maxDate: Date;
  daysDuration: string;
  daysOffset: string;
  handleChange: (key: string, value: string) => void;
  isProductPage: boolean;
  getProductFactsGuiValue?: Function | undefined;
  productDaysDurationMax?: string;
  productDaysDurationMin?: string;
  daysAvailable?: string;
}

interface excludeDates {
  availability: boolean;
  date: Date;
}

const DateTimeLocComponent: React.FC<dateTimeProps> = ({
  allowDatePicker,
  minDate,
  maxDate,
  daysDuration,
  daysOffset,
  handleChange,
  isProductPage,
  getProductFactsGuiValue,
  productDaysDurationMax,
  productDaysDurationMin,
  daysAvailable,
}) => {
  // Calendar function
  registerLocale("en", en);

  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });
  const referer = useSelector((state: RootState) => {
    return state.facts.referer;
  });
  // end Calendar function
  let [startDate, setStartDate] = useState<any>(() => {
    const date = new Date(minDate);
    date.setDate(date.getDate() + Number(daysOffset));
    return date;
  });

  let [endDate, setEndDate] = useState<any>(() => {
    const date = new Date(minDate);
    date.setDate(
      date.getDate() + Number(daysOffset) + Number(daysDuration) - 1
    );
    return date;
  });

  useEffect(() => {
    const startDate = new Date(minDate);
    startDate.setDate(startDate.getDate() + Number(daysOffset));
    setStartDate(startDate);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + Number(daysDuration) - 1);
    setEndDate(endDate);
  }, [daysOffset, daysDuration, minDate]);

  /**
   * When the start date is changed, the end date is automatically adjusted to be the start date plus
   * the duration minus one day.
   * @param {any} startDateValue - The start date value that was selected by the user.
   * @param {any} DurationMinOrMax - This is the minimum or maximum duration of the product.
   */
  const automaticallyAdjustProductDuration = (
    startDateValue: Date,
    DurationMinOrMax: string | undefined
  ) => {
    let mySelectedEndDate = new Date(startDateValue);
    mySelectedEndDate.setDate(
      mySelectedEndDate.getDate() + Number(DurationMinOrMax) - 1
    );
    setEndDate(mySelectedEndDate);
  };

  /**
   * It takes a string of "y"s and "n"s and converts it into an array of objects with a date and a
   * boolean value
   * @param {any} start - the first date in the range
   * @param {any} end - any) => {
   * @returns An array of dates that are not available.
   */
  const getDateArray = (start: Date, end: Date) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }

    return arr;
  };

  const dateArr = getDateArray(minDate, maxDate);

  const availArr: any = daysAvailable?.split("");

  const availabilityByDate = dateArr.map((day, id) => ({
    date: day,
    availability: availArr?.[id] === "y" ? true : false,
  }));

  const datesToBeExcluded = availabilityByDate.filter(
    (day) => !day.availability
  );

  const excludeDates = datesToBeExcluded.map((day: excludeDates) => day.date);
  const checkIfProductPageIsTrueAndExcludeDates = isProductPage
    ? excludeDates
    : [];

  /**
   * The function takes in two dates, calculates the difference between the two dates and then triggers
   * the onChange function with the calculated values
   * @param {any} dates - any - this is the date range that the user selects
   */
  const onChangeHandler = (dates: any) => {
    const [start, end] = dates;
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
    } else {
      setStartDate(new Date(start));
      setEndDate(end);
    }

    if (!start || !end) {
      return;
    }

    const daysOffset = differenceInCalendarDays(
      new Date(start),
      new Date(minDate)
    );

    const daysDuration = differenceInCalendarDays(
      new Date(end),
      new Date(start)
    );

    // Landingpage value trigger
    if (!isProductPage) {
      handleChange("days_offset", daysOffset.toString());
    }

    if (!isProductPage) {
      handleChange("days_duration", (daysDuration + 1).toString());
    }

    // ProductPage value trigger
    if (isProductPage) {
      getProductFactsGuiValue?.("days_offset", daysOffset.toString());
    }

    if (isProductPage) {
      if (daysDuration + 1 <= Number(productDaysDurationMin)) {
        // switch alert with a pop up componenet
        // alert(
        //   `this product has a minimum of ${productDaysDurationMin} days. Values will adjust accordingly`
        // );
        getProductFactsGuiValue?.(
          "days_duration",
          productDaysDurationMin?.toString()
        );

        automaticallyAdjustProductDuration(startDate, productDaysDurationMin);
      } else if (daysDuration + 1 >= Number(productDaysDurationMax)) {
        // switch alert with a pop up componenet
        // alert(
        //   `this product has a maximun of ${productDaysDurationMax} days. Values will adjust accordingly `
        // );

        getProductFactsGuiValue?.(
          "days_duration",
          productDaysDurationMax?.toString()
        );

        automaticallyAdjustProductDuration(startDate, productDaysDurationMax);
      } else {
        getProductFactsGuiValue?.(
          "days_duration",
          (daysDuration + 1).toString()
        );
      }
    }

    // if (!isProductPage) {
    //   dispatch(
    //     getPackagesAction({
    //       facts,
    //     })
    //   );
    // }
  };

  const getDateString = useCallback(
    (date: Date) => `${date.getFullYear()}${date.getMonth()}${date.getDate()}`,
    []
  );

  const renderDayContents = useCallback(
    (day: any, date: Date) => {
      const classNames = [];
      const firstDay = new Date(minDate);
      if (getDateString(firstDay) === getDateString(date)) {
        classNames.push("firstDay");
      }
      const lastDay = new Date(maxDate);
      if (getDateString(lastDay) === getDateString(date)) {
        classNames.push("lastDay");
      }

      return (
        <div className={classNames.join(" ")}>
          <div className="pre"></div>
          <div className="post"></div>
          <div className="day">{day}</div>
        </div>
      );
    },
    [minDate, maxDate, getDateString]
  );

  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleCalendarOpen = () => {
    setCalendarOpen(true);
  };

  const handleCalendarClose = () => {
    setCalendarOpen(false);
  };

  return (
    <Wrapper
      style={{ width: "100%", borderRadius: "var(--radius-components)" }}
    >
      {allowDatePicker ? (
        <div className="calendar">
          <DatePicker
            selected={startDate}
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={onChangeHandler}
            onCalendarClose={handleCalendarClose}
            onCalendarOpen={handleCalendarOpen}
            renderDayContents={renderDayContents}
            dateFormat="d MMM"
            excludeDates={checkIfProductPageIsTrueAndExcludeDates}
            customInput={
              <DatePickerContent
                startDate={startDate}
                endDate={endDate}
                isOpen={calendarOpen}
                englishLanguage={englishLanguage}
                isProductPage={isProductPage}
                referer={referer}
              />
            }
            minDate={new Date(minDate)}
            maxDate={new Date(maxDate)}
            popperPlacement="bottom"
            popperModifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, -16],
                },
              },
            ]}
            locale="en"
          />
          <DatePickerStyles />
        </div>
      ) : (
        <DatePickerContent
          startDate={startDate}
          endDate={endDate}
          isOpen={calendarOpen}
          englishLanguage={englishLanguage}
          isProductPage={isProductPage}
          referer={referer}
        />
      )}
    </Wrapper>
  );
};

type DatePickerContentProps = HTMLProps<HTMLDivElement> & {
  isProductPage: boolean;
  englishLanguage: boolean;
  isOpen: boolean;
  startDate: Date;
  endDate: Date;
  referer: string;
};

type StyledDatePickerContentProps = {
  isOpen: boolean;
};

const StyledDatePickerContent = styled.div.attrs(
  ({ isOpen }: StyledDatePickerContentProps) => ({
    isOpen,
  })
)`
  /* border: 2px solid #fff; */
  box-shadow: var(--box-shadow);
  position: relative;
  ${({ isOpen }) =>
    isOpen
      ? `
      border-color: #0CA1E3;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -5px;
        left: -5px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 3px solid #CFDCE2;
        border-radius: 20px;
      }
      `
      : ""}
`;

const DatePickerContent = forwardRef(
  (
    {
      isProductPage,
      englishLanguage,
      startDate,
      endDate,
      isOpen,
      value,
      onClick,
      referer,
    }: DatePickerContentProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <StyledDatePickerContent
      isOpen={isOpen}
      className={
        isProductPage ? `distance prodpagewidth` : "distance landingPageHeight"
      }
      onClick={onClick}
      ref={ref}
    >
      {!isProductPage ? (
        <>
          <section>
            <p>{dateFormat(startDate, "ddd dd.mm")}</p>
            <p className="to">to</p>
            <p>{dateFormat(endDate, "ddd dd.mm")}</p>
          </section>
          <div className="icon-container">
            <BsChevronDown className="icon" />
          </div>
        </>
      ) : (
        <ProductPageDateCard
          startDate={dateFormat(startDate, "dd mmm")}
          endDate={dateFormat(endDate, "dd mmm")}
          referer={referer}
        />
      )}
    </StyledDatePickerContent>
  )
);

const Wrapper = styled.div`
  padding: 1rem auto;
  background: var(--inverse-background);

  section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .distance {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    /* height: fit-content; */
    color: var(--clr-primary-2);
    text-align: center;
    margin: 0;
    border-radius: var(--radius-options-date);
    width: 95%;
    margin: 2rem auto;
    background: var(--clr-date-background);

    .icon-container {
      /* margin-left: 0.5rem; */
      .icon {
        font-size: 1.2rem;
        color: var(--clr-primary-1);
      }
    }

    .to {
      text-transform: lowercase;
      padding: 0 5px;
      color: var(--clr-date-picker-to);
      font-weight: normal;
    }

    &,
    h4,
    span {
      text-align: left;
      font-size: 11px;
    }

    p {
      text-transform: capitalize;
      color: var(--clr-date-text);
      font-family: var(--p-medium);
      text-align: center;
      font-weight: var(--clr-date-weight);
    }

    img {
      height: 2.5rem;
      /* filter: var(--clr-secondary-1-filter); */
      /* filter: brightness(0) saturate(100%) invert(54%) sepia(32%)
        saturate(4747%) hue-rotate(166deg) brightness(95%) contrast(91%); */
    }

    div:last-child {
      &,
      h4,
      span {
        text-align: right;
        /* margin: 1rem auto; */
      }
    }
  }

  .landingPageHeight {
    height: 2.5rem;
    padding: 0 5px;
    background: var(--clr-date-background);
  }

  .prodpagewidth {
    width: 95vw;
    margin: 1rem auto;
    background: var(--date-card-bg);
    display: flex;
    justify-content: center;

    @media screen and (min-width: 800px) {
      width: 50vw;
    }
  }

  @media screen and (min-width: 800px) {
    width: 50vw;
    /* margin: 1rem auto 0; */
  }
`;

const DatePickerStyles = createGlobalStyle`
  .react-datepicker-popper {
    padding: 0 16px;
    z-index: 999;
    
    &,
    .react-datepicker {
      width: 100%;
      
      @media screen and (min-width: 800px) {
        max-width: 400px;
      }
    }
    
    .react-datepicker {
      border-radius: 16px;
      border: 0;
      box-shadow: var(--box-shadow);
      
      &__month-container {
        float: none;
      }
      
      &__header {
        background: none;
        border: 0;
      }
      
      &__current-month {
        font-weight: 600;
        color: var(--clr-primary-1);
        font-family: var(--p-bold);
        padding: 16px 0;
      }
      
      &__navigation {
        top: 16px;
        
        &-icon:before {
          border-width: 2px 2px 0 0;
          border-color: var(--clr-secondary-1);
        }
      }
      
      &__triangle {
        display: none;
      }
      
      &__day-names,
      &__week {
        display: flex;
      }
      
      &__day-name {
        background: var(--clr-secondary-gry-3);
        color: var(--clr-primary-2);
      }
      
      &__day-name,
      &__day {
        flex: 1;
      }
      
      &__day-name,
      &__day > div > .day {
        padding: 8px 0;
      }
      
      &__header {
        padding: 8px;
      }
      
      &__month {
        margin: 0;
        padding: 8px 0;
      }
      
      &__week {
        padding: 0 8px;
        margin-bottom: 8px;
      }
      
      &__day {
        margin: 0;
        border-radius: 0;
        color: var(--clr-primary-4);
        position: relative;
        z-index: 0;
        background: transparent !important;

        .pre,
        .post {
          position: absolute;
          z-index: 2;
          top: 0;
          left: 0;
          width: 50%;
          height: 100%;
        }

        .post {
          left: auto;
          right: 0;
        }
        
        &:first-child {
          & > div {
            padding-left: 8px;
            margin-left: -8px;
            
            .pre {
              left: -8px;
              width: calc(50% + 8px);
            }
          }
        }

        &:last-child {
          & > div {
            padding-right: 8px;
            margin-right: -8px;

            .post {
              right: -8px;
              width: calc(50% + 8px);
            }
          }
        }
        
        &--disabled {
          * {
            color: var(--clr-secondary-gry-4) !important;
            background: transparent !important;
          }
        }
        
        & > div {
          background: #dbdbdb;
          
          &.firstDay {
            border-top-left-radius: var(--radius-components);
            border-bottom-left-radius: var(--radius-components);
          }

          &.lastDay {
            border-top-right-radius: var(--radius-components);
            border-bottom-right-radius: var(--radius-components);
          }
        }

        &--in-range > div,
        &--in-selecting-range > div {
          background: var(--clr-date-range);
        }

        &--range-start > div .post,
        &--selecting-range-start > div .post,
        &--range-end > div .pre,
        &--selecting-range-end > div .pre {
           background: var(--clr-date-range);
        }
        
        &--range-start > div .pre,
        &--selecting-range-start > div .pre,
        &--range-end > div .post,
        &--selecting-range-end > div .post {
           background: var(--clr-date-range);
        }
        
        .day {
          z-index: 3;
          width: 50px;
          height: 50px;
          margin: auto;
          position: relative;
          display: inline-flex;
          justify-content: center;
          align-items: center;
        }
        
        &:hover,
        &--selected,
        &--range-start, 
        &--selecting-range-start, 
        &--range-end,
        &--selecting-range-end {
          & > div {
            .day {
              border-radius:var(--radius-components);
              background: var( --clr-primary-1);
              color: var(--clr-white);
            }
            
            &.firstDay {
              .pre {
                background:  var(--clr-white);;
              }
            }

            &.lastDay {
              .post {
                background: var(--clr-white);
              }
            }
          }
        }
      }
    }
  }
`;

export default DateTimeLocComponent;
