//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  david.koppenhoefer@banbutsu.com
//
// this page displays product locations on an interactive map.
//-----------------------------------------------------------------------------

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PageTitle, Error, Loading } from "../components/index";
import { RootState } from "../State/store";
import { useParams } from "react-router";
import { DesktopWrapper, WhiteBackground } from "../Utils/styles";
import { computeMapZoom, getMarkerLabel } from "../Utils/location-map";
import { Wrapper as MapsReactWrapper, Status } from "@googlemaps/react-wrapper";
import { envStaticHost, getPartnerConfiguration } from "../Utils/windowHelper";
import { AiOutlineCloseCircle } from "react-icons/ai";

interface guis {
  required?: boolean;
  editable?: boolean;
  fact_name?: string;
  widget?: string;
  class?: string;
  inc?: number;
  max?: number;
  min?: number;
  text?: {
    de: string;
    en: string;
  };
  title?: {
    de: string;
    en: string;
  };
  media?: string[];
}

interface products {
  product_id: string;
  facts: any;
  cost: number;
  currency: string;
  guis: guis[];
  latest_cancel: string;
  cost_net: number;
}

interface categories {
  en?: string;
  de?: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface packageProps {
  package_id: string;
  product_groups: product_groups[];
  categories: categories[];
  facts: any;
  guis: guis[];
}

const ProductLocationScreen = () => {
  /* Using the useNavigate hook to navigate to a different page. */
  let navigate = useNavigate();

  /* Using the useSelector hook to get the language from the store. */
  const englishLanguage = useSelector((state: RootState) => {
    return state.facts.language === "en";
  });

  /* Destructuring the useParams hook from react-router-dom and assigning it to a variable called
packageIdParams. */
  const { packageid: packageIdParams } = useParams<{ packageid?: string }>();

  /* Using the useSelector hook to get the facts from the Redux store. */
  const facts = useSelector((state: RootState) => {
    return state.facts;
  });

  /* packages (or nothing) from the local storage */
  const packages: packageProps[] = JSON.parse(localStorage.getItem("packages") || "[]");
  /* ...and the one package we're talking about */
  const singlePackage = packages.find((item) => item.package_id === packageIdParams);
  /* ...all products of its product groups */
  const allProducts = singlePackage?.product_groups
    ?.map((eachProduct: product_groups) => eachProduct?.products?.map((singleProduct: products) => singleProduct))
    .flat();

  /* only keep enabled products with location koordinates */
  const locationProducts = allProducts?.filter(
    (prod: products) => prod.facts.enabled === "1" && prod.facts.location_lat && prod.facts.location_lng
  );
  /* extract data relevant for display */
  const markerData = locationProducts?.map((p: products) => {
    return {
      latlng: {
        lat: parseFloat(p.facts.location_lat),
        lng: parseFloat(p.facts.location_lng),
      },
      text: ((p: products) => {
        const gui = p.guis[0];
        const title = englishLanguage ? gui?.title?.en : gui?.title?.de;
        const subtitle = p.facts.vendor_name;
        if (subtitle) {
          return title + "<br/><br/>" + subtitle;
        }
        return title;
      })(p),
      label: getMarkerLabel(p.facts.location_poi),
    };
  });

  // ---------------
  // maps wiring

  // get center & zoom from poi set, or set defaults
  const mzrecord = markerData
    ? computeMapZoom(markerData!.map((p) => p.latlng))
    : { zoom: 0, center: { lat: 0, lng: 0 } };
  const maxInitialZoom = 17;
  if (mzrecord.zoom > maxInitialZoom) {
    mzrecord.zoom = maxInitialZoom;
  }
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow>();

  // We can have Status.LOADING, .FAILURE, .SUCCESS
  // and should return the appropriate rendering component.
  // (see https://www.npmjs.com/package/@googlemaps/react-wrapper)
  const render = (status: Status) => {
    console.log("render: ", status);

    switch (status) {
      case Status.LOADING:
        return <Loading />;
      case Status.FAILURE:
        return (
          <Error
            errorImage={`${envStaticHost}/static/${facts?.referer}/error/Error_3.png`}
            headerText={
              englishLanguage
                ? "Sorry, a map rendering problem has occurred"
                : "Entschuldigung, es ist ein Problem aufgetreten."
            }
            bodyText={englishLanguage ? "Please refresh the page" : "Bitte aktualisieren die Seite"}
          />
        );
    }
    return <></>; // the success case. but we don't come here, ever.
  };

  // TODO get the api key from env maybe
  const mapsApiKey = "AIzaSyAjlY3RL5bAtnMDHPH45K48oIQdxl38o2E";

  const configuration = getPartnerConfiguration(facts.referer);

  return (
    <DesktopWrapper>
      <WhiteBackground>
        <Wrapper>
          <div className={facts?.referer}>
            <div className="closeBtnContainer">
              {configuration.USE_DARK_BTN ? (
                <img
                  src={`${envStaticHost}/static/${facts?.referer}/icon/black-round-x.svg`}
                  alt="close"
                  className="closeIcon"
                  onClick={() => navigate(-1)}
                />
              ) : (
                <AiOutlineCloseCircle className="closeIcon_light" onClick={() => navigate(-1)} />
              )}
            </div>

            <div className="pagetitle-container">
              <h2>{englishLanguage ? "Your booking locations" : "Deine Buchungsorte"}</h2>
            </div>
          </div>

          <div className="maps-container">
            <MapsReactWrapper apiKey={mapsApiKey} render={render}>
              <Map center={mzrecord.center} zoom={mzrecord.zoom} style={{ flexGrow: "1", height: "100%" }}>
                {markerData?.map((p, index) => (
                  <Marker
                    infoWindow={infoWindow}
                    setInfoWindow={setInfoWindow}
                    text={p.text}
                    position={p.latlng}
                    label={p.label}
                    key={index}
                  />
                ))}
              </Map>
            </MapsReactWrapper>
          </div>
        </Wrapper>
      </WhiteBackground>
    </DesktopWrapper>
  );
};

// TODO configure map:
// language, controls, map style (try do "light landmass")

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
}

const Map: React.FC<MapProps> = ({ onClick, onIdle, children, style, ...options }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    console.log("useEffect, ref and map"); // logging to make sure call frequency is low
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  useEffect(() => {
    console.log("useEffect, options"); // logging to make sure call frequency is low
    if (map) {
      map.setOptions(options);
    }
  }, [options, map]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

interface MarkerProps extends google.maps.MarkerOptions {
  infoWindow?: google.maps.InfoWindow;
  setInfoWindow?: any;
  text?: string;
}

const Marker: React.FC<MarkerProps> = ({ infoWindow, setInfoWindow, text, ...options }) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);

      if (!infoWindow) {
        if (setInfoWindow) {
          setInfoWindow(new google.maps.InfoWindow());
        }
      }

      marker.addListener("click", () => {
        if (infoWindow) {
          infoWindow.close();
          if (text) {
            infoWindow.setContent(text);
            infoWindow.open({
              anchor: marker,
            });
          }
        }
      });
    }
  }, [marker, options, text, infoWindow, setInfoWindow]);

  return null;
};

const Wrapper = styled.section`
  margin: 0 auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  transition: var(--transition);
  background: var(--clr-white);

  &::-webkit-scrollbar {
    display: none;
  }

  .etravel_mercedes,
  .etravel_geo {
    display: flex;
    align-items: center;
    background: var(--clr-primary-1);
    color: white;

    .pagetitle-container {
      h2 {
        color: white;
        padding: 0.5rem;
        text-transform: capitalize;
      }
    }
  }

  .manx {
    display: flex;
    align-items: center;
    background: var(--clr-primary-1);
    color: white;

    .pagetitle-container {
      h2 {
        color: white;
        padding: 0.5rem;
        text-transform: capitalize;
      }
    }
  }

  .volkswagen,
  .sharenow,
  .mini,
  .miles,
  .ces24,
  .sandbox {
    display: flex;
    align-items: center;
    background: var(--popup-bg);

    .pagetitle-container {
      h2 {
        color: var(--inverse-text);
        padding: 0.5rem;
        text-transform: uppercase;
      }
    }
  }

  .closeBtnContainer {
    z-index: 99;

    .closeIcon {
      height: 2rem;
      width: 2rem;
      margin: 1rem 0 1rem 1rem;
    }

    .closeIcon_light {
      height: 2rem;
      width: 2rem;
      margin: 1rem 0 1rem 1rem;
      color: var(--clr-white);
    }
  }

  .maps-container {
    height: 600px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }

  @media screen and (min-width: 800px) {
    .closeBtnContainer {
      margin: 32px auto 16px;
    }

    .pagetitle-container {
      display: flex;
      flex-direction: row;
      width: 60vw;
      margin: 0 auto;
      margin-top: 0.3rem;
      background: var(--clr-white);
    }

    .btnWrapper {
      width: 60vw;
      margin: 0 auto;
      background: var(--clr-white);
    }
  }

  @media screen and (min-width: 1155px) {
    width: 100%;
  }
`;

export default ProductLocationScreen;
