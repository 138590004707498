import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import {
  // DesktopWrapper,
  WhiteBackground,
  CustomBtnWidth,
  // ButtonSticky,
} from "../Utils/styles";
import { PopUpPackageCard, PopUpOptions } from "../components";
import { packageProps } from "../Screens/LandingPageScreen";
import { createNewResponseObjectPackageFacts } from "../Utils/helper";
import { getPartnerConfiguration } from "../Utils/windowHelper";

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

type Props = {
  popUpModal: boolean;
  setPopUpModal: Function;
  eachPackage: packageProps | undefined;
  englishLanguage: boolean;
  packages: packageProps[];
  referer: string;
  slug: string;
  handleChange: Function;
};

const PopUp: React.FC<Props> = ({
  popUpModal,
  setPopUpModal,
  eachPackage,
  englishLanguage,
  packages,
  referer,
  slug,
  handleChange,
}) => {
  const isLightOnDark = getPartnerConfiguration(referer).IS_LIGHT_ON_DARK;
  //const lightOnDarkSuffix = isLightOnDark ? "_lightOnDark" : "_darkOnLight";

  const lightOnDarkSuffix = referer === "mini" ? "_mini" : isLightOnDark ? "_lightOnDark" : "_darkOnLight";
  // awful hack for bbt-1749 and bbt-1754, to be reverted soon probably

  /* A react hook that is used to set the state of the component. */
  const [pack, setPack] = useState<any>(eachPackage);

  const gui = eachPackage?.guis?.find((gui: guis) => gui.widget === "label" && gui);

  /* This is a react hook that is used to set the state of the component. */
  // change to a function
  const [packEnabled, setPackEnabled] = useState<string>(pack?.facts?.enabled);

  /* This is a react hook that is used to set the state of the component. */
  const updatePackageFacts = useCallback(() => {
    createNewResponseObjectPackageFacts(
      {
        packages: [eachPackage],
      },
      eachPackage?.package_id!
    );
  }, [eachPackage]);

  /**
   * It updates the package facts, then saves the packages to local storage, then sets the packEnabled
   * state to the pack facts enabled state, then sets the popUpModal state to false
   */
  const handleAddPackage = () => {
    updatePackageFacts();
    localStorage.setItem("packages", JSON.stringify(packages));
    setPackEnabled(pack?.facts?.enabled);
    setPopUpModal(false);
  };

  useEffect(() => {
    setPack(pack);
  }, [pack]);

  useEffect(() => {
    setPackEnabled(pack?.facts?.enabled);
  }, [pack?.facts?.enabled]);

  return (
    <PopUpWrapper>
      <div className={referer}>
        <Modal className={!popUpModal ? "overlay" : "overlay showPopUp"}>
          {slug === "packageCard" && (
            <Wrapper>
              <PopUpPackageCard
                handleAddPackage={handleAddPackage}
                englishLanguage={englishLanguage}
                referer={referer}
                gui={gui}
                lightOnDarkSuffix={lightOnDarkSuffix}
                setPopUpModal={setPopUpModal}
                eachPackage={eachPackage}
              />
            </Wrapper>
          )}

          {slug === "options" && (
            <Wrapper>
              <PopUpOptions
                setPopUpModal={setPopUpModal}
                referer={referer}
                englishLanguage={englishLanguage}
                handleChange={handleChange}
              />
            </Wrapper>
          )}
        </Modal>
      </div>
    </PopUpWrapper>
  );
};

const PopUpWrapper = styled.div`
  overflow: hidden;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -10;
    transform: scale(0);
    z-index: 99;
  }

  .showPopUp {
    visibility: visible;
    transform: scale(1);
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    /* background-color: rgba(0, 0, 0, 0.4); */
  }

  .showPopUp::-webkit-scrollbar {
    display: none;
  }
`;

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  border-radius: var(--radius-components);
  height: 80vh;
  width: 90vw;
  margin: 0 0 10vh 0;
  position: relative;
  overflow: scroll;
  background: var(--popup-bg);

  .headerImage {
    height: 220px;
    width: 100%;
    position: relative; /* Ensure the container is positioned relatively for absolute positioning inside it */
    overflow: hidden; /* Needed to contain the pseudo-element within the headerImage */
  }

  .headerImage::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--blend);
    z-index: 2;
  }

  .headerImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative; /* Positioned relative to allow z-index to work */
    z-index: 1; /* Ensures image is below the gradient */
  }

  .headerGradient {
    z-index: 1;
    top: 60px;
    position: absolute;
    border: 0;

    img {
      object-fit: cover;
      width: 0;
    }
  }

  .closeBtnContainer {
    z-index: 99;
    top: 0px;
    right: 0px;
    position: absolute;

    .closeIcon {
      height: 2rem;
      width: 2rem;
      margin: 1rem;
    }

    img {
      filter: var(--hero-icon-filter);
    }
  }

  .headerAndContent_lightOnDark {
    top: 150px;
    z-index: 3;
    position: absolute;
    width: 100%;
  }

  .headerTextContainer_darkOnLight {
    top: 3.3rem;
    z-index: 2;
    position: absolute;
    text-transform: uppercase;
    color: white;
  }

  .headerTextContainer_lightOnDark {
    color: var(--inverse-text);
  }

  // mini actually is dark on light,
  // but we need black text with light-on-dark positioning
  .headerAndContent_mini {
    top: 150px;
    z-index: 3;
    position: absolute;
  }

  .headerTextContainer_mini {
    color: black;
  }

  .headerText {
    font-size: 32px;
    padding: 0 1.5rem 1rem 1.5rem;
    font-family: var(--headerText);
  }

  .packageDescription {
    padding: 2.5rem 1.5rem;
    font-size: 16px;
    color: var(--inverse-paragraph-text);
  }

  @media screen and (min-width: 800px) {
    width: 45vw;
    position: absolute;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default PopUp;
