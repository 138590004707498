//-----------------------------------------------------------------------------
// Copyright 2020-2024 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this is one part of the landing page,
// facts.category === 'Experience'
//
// POST /v1/portal/login
// POST /v1/portal/select-packages

// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React, { useState } from "react";
import { NavigateFunction } from "react-router";
import { DateTimeComponent, Interest, OptionsControl, PackageCard, SignupNewsLetter, PopUp, AIRecommendation } from ".";
import styled from "styled-components";
import { getPartnerConfiguration } from "../Utils/windowHelper";

interface guis {
  required: boolean;
  editable: boolean;
  fact_name: string;
  widget: string;
  class: string;
  inc: number;
  max: number;
  min: number;
  text: {
    de: string;
    en: string;
  };
  title: {
    de: string;
    en: string;
  };
  media: string[];
}

interface products {
  product_id: string;
  facts: any;
  cost: number;
  currency: string;
  guis: guis[];
  latest_cancel: string;
}

interface categories {
  en: string;
  de: string;
}

interface product_groups {
  deletable: boolean;
  multiple: boolean;
  products: products[];
}

interface packageProps {
  package_id: string;
  product_groups: product_groups[];
  categories: categories[];
  facts: any;
  guis: guis[];
}

type Props = {
  facts: any;
  englishLanguage: boolean;
  handleChange: (key: string, value: string) => void;
  packages: any;
  noPackagesToDisplay: boolean;
  navigate: NavigateFunction;
  packagesInterests:
    | {
        factname: string;
        media: string;
        title: {
          en: string;
          de: string;
        };
      }[]
    | undefined;
};

const ExperienceLanding: React.FC<Props> = ({
  facts,
  englishLanguage,
  handleChange,
  packages,
  noPackagesToDisplay,
  navigate,
  packagesInterests,
}) => {
  /* The above code is using the useState hook to create a state variable called popUpModal and a
function called setPopUpModal. */
  const [popUpModal, setPopUpModal] = useState<boolean>(false);

  const [selectedItem, setSelectedItem] = useState<packageProps>();

  const configuration = getPartnerConfiguration(facts.referer);

  const option_guis = JSON.parse(localStorage.getItem("option_guis") as string);

  function handleClick(item: packageProps) {
    setSelectedItem(item);
  }

  const [slug, setSlug] = useState<string>("");

  return (
    <React.Fragment>
      <SectionBackground>
        <section className={facts?.referer}>
          <ControlWrapper id={"date-people-wrapper"}>
            <DateTimeComponent
              minDate={facts.starttime}
              maxDate={facts.endtime}
              daysDuration={facts.days_duration}
              daysOffset={facts.days_offset}
              handleChange={handleChange}
              allowDatePicker
              isProductPage={false}
            />
            <>
              {configuration && configuration.SHOW_COMPONENT_BASED_ON_CATEGORY.includes(facts.category) &&
               option_guis && option_guis.length > 0 && (
                <div
                  onClick={() => {
                    setPopUpModal(true);
                    setSlug("options");
                  }}
                >
                  <OptionsControl />
                </div>
              )}
            </>
          </ControlWrapper>
        </section>
      </SectionBackground>

      <Interest handleChange={handleChange} packagesInterests={packagesInterests} facts={facts} />

      <RecommendationWrapper>
        <section className={facts?.referer}>
          <AIRecommendation englishLanguage={englishLanguage} />
        </section>
      </RecommendationWrapper>

      <PopUp
        popUpModal={popUpModal}
        setPopUpModal={setPopUpModal}
        eachPackage={selectedItem}
        packages={packages}
        englishLanguage={englishLanguage}
        referer={facts.referer}
        slug={slug}
        handleChange={handleChange}
      />

      <SectionBackground>
        <section className={facts?.referer}>
          {packages?.map(
            (eachPackage: packageProps, index: number) =>
              eachPackage?.package_id &&
              eachPackage?.guis.map(
                (gui: guis) =>
                  gui.widget === "label" && (
                    <div className="landingPageDesktop" key={eachPackage?.package_id}>
                      <div
                        onClick={() => {
                          handleClick(eachPackage);
                          setPopUpModal(true);
                          setSlug("packageCard");
                        }}
                      >
                        <PackageCard
                          eachPackage={eachPackage}
                          gui={gui}
                          englishLanguage={englishLanguage}
                          packages={packages}
                          referer={facts?.referer}
                        />
                      </div>
                    </div>
                  )
              )
          )}
        </section>
      </SectionBackground>

      <SignupNewsLetter
        englishLanguage={englishLanguage}
        noPackagesToDisplay={noPackagesToDisplay}
        referer={facts?.referer}
      />
    </React.Fragment>
  );
};

const RecommendationWrapper = styled.div`
  .sharenow,
  .sandbox,
  .ces24 {
    margin: 9px 0 15px;
  }
  .mini {
    margin: 9px 0 15px;
  }
`;

const SectionBackground = styled.div`
  .volkswagen {
    padding: 12px 0 8px;
  }

  .manx {
    padding: 12px 0 8px;
  }

  .etravel_mercedes,
  .etravel_geo {
    padding: 12px 0 8px;
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;

  @media screen and (min-width: 800px) {
    width: 50vw;
    margin: 0 auto;
    padding: 0;
  }
`;

export default ExperienceLanding;
