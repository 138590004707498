//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this page calls one endpoint, and also handles the agree terms and condition
// for each product. Until the terms are accepted, the end user cannot move
// forward
//
// POST /v1/portal/register-payment-source-id
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import { StripeCheckout } from "../components/index";
import styled from "styled-components";
import { DesktopWrapper, WhiteBackground } from "../Utils/styles";

const PaymentScreen = () => {
  return (
    <DesktopWrapper>
      <WhiteBackground>
        <Wrapper>
          <StripeCheckout />
        </Wrapper>
      </WhiteBackground>
    </DesktopWrapper>
  );
};

const Wrapper = styled.section`
  min-height: 100vh;
  padding-top: 15px;
  background: var(--clr-white);
  background: var(--clr-payment-bg);

  form {
    width: 100%;
  }
`;

export default PaymentScreen;
