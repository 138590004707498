interface BuildEnvironment {
  FE_PORTAL: string;
  FE_STRIPE_PUBLIC_KEY: string;
  FE_VANILLA: string;
  FE_DOMAIN: string;
  FE_STATIC_HOST: string;
  PARTNER_CONFIGURATIONS: any;
}

const windowEnv: BuildEnvironment = (window as any).env;

export const envURL: string = windowEnv?.FE_PORTAL;

export const envStripeKey: string = windowEnv?.FE_STRIPE_PUBLIC_KEY;

export const envVANILLA: string = windowEnv?.FE_VANILLA;

export const envDomainName: string = windowEnv?.FE_DOMAIN;

export const envStaticHost: string = windowEnv?.FE_STATIC_HOST;

export const getPartnerConfiguration = (partner: string) => {
  return windowEnv?.PARTNER_CONFIGURATIONS[partner];
};
