//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this components holds the logo of every partner on each product page
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

import React from "react";
import styled from "styled-components";
import { IoCloseCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

interface Props {
  backgroundImage: any;
  logo?: string | undefined;
  logoContainer?: boolean;
}
/* A React component that takes in a background image, a logo and a boolean value. */
const PartnerHeader: React.FC<Props> = ({
  backgroundImage,
  logo,
  logoContainer = true,
}) => {
  let navigate = useNavigate();

  return (
    <Wrapper
      style={{
        backgroundImage: `linear-gradient(180deg, #0000001f 0%, #45434380 100%), url('${backgroundImage}')`,
      }}
    >
      <div className="closeBtnContainer">
        <IoCloseCircle className="closeIcon" onClick={() => navigate(-1)} />
      </div>

      {logoContainer && (
        <div className="img-container">
          <img src={logo} alt="partner logo" className="logo" />
        </div>
      )}

      <div className="white" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 285px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  object-fit: cover;
  position: relative;

  .white {
    height: 100px;
    width: 100%;
    background: var(--clr-white);
    transform: skew(0deg, -5deg);
    position: absolute;
    left: 0;
    bottom: -50px;
  }

  .closeBtnContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .closeIcon {
    margin: 1.5rem;
    font-size: 2rem;
    color: var(--clr-black);
  }

  .img-container {
    display: inline-block;
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .logo {
      margin: 0 0 15px 15px;
      width: 90%;
      filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(328deg)
        brightness(106%) contrast(100%);
    }

    @media screen and (min-width: 800px) {
      .logo {
        width: 70%;
      }
    }
  }
`;
export default PartnerHeader;
