//-----------------------------------------------------------------------------
// Copyright 2021-2022 by banbutsu dcp GmbH. Confidential. All rights reserved.
//-----------------------------------------------------------------------------
// Project: platform frontend
// Author:  bamidele.awotunde@banbutsu.com
//
// this file holds all TYPES for payload actions for different state
//
// Further details regarding endpoints on
// `https://git.iconmobile.com/banbutsu/dev/-/tree/master/src/platform/cmd/srv_portal`
//-----------------------------------------------------------------------------

export enum ActionType {
  GET_LOGIN_TOKEN_REQUEST = "GET_LOGIN_TOKEN_REQUEST",
  GET_LOGIN_TOKEN_SUCCESS = "GET_LOGIN_TOKEN_SUCCESS",
  GET_LOGIN_TOKEN_FAIL = "GET_LOGIN_TOKEN_FAIL",

  GET_ROLE_LOGIN_TOKEN_REQUEST = "GET_ROLE_LOGIN_TOKEN_REQUEST",
  GET_ROLE_LOGIN_TOKEN_SUCCESS = "GET_ROLE_LOGIN_TOKEN_SUCCESS",
  GET_ROLE_LOGIN_TOKEN_FAIL = "GET_ROLE_LOGIN_TOKEN_FAIL",

  GLOBAL_FACTS_REQUEST = "GLOBAL_FACTS_REQUEST",
  GLOBAL_FACTS_SUCCESS = "GLOBAL_FACTS_SUCCESS",
  GLOBAL_FACTS_FAIL = "GLOBAL_FACTS_FAIL",

  // SINGLE EXPERIENCE REQUEST
  GET_PACKAGES_REQUEST = "GET_PACKAGES_REQUEST",
  GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS",
  GET_PACKAGES_LANDING_STATE = "GET_PACKAGES_LANDING_STATE",
  GET_PACKAGES_FAIL = "GET_PACKAGES_FAIL",

  // SINGLE EXPERIENCE REQUEST
  GET_PROMOTIONS_REQUEST = "GET_PROMOTIONS_REQUEST",
  GET_PROMOTIONS_SUCCESS = "GET_PROMOTIONS_SUCCESS",
  GET_PROMOTIONS_FAIL = "GET_PROMOTIONS_FAIL",

  // UPDATE PACKAGES
  UPDATE_PACKAGES_REQUEST = "UPDATE_PACKAGES_REQUEST",
  UPDATE_PACKAGES_SUCCESS = "UPDATE_PACKAGES_SUCCESS",
  UPDATE_PACKAGES_FAIL = "UPDATE_PACKAGES_FAIL",

  // SAVE PHONE NUMBER
  GET_PHONE_NUMBER = "GET_PHONE_NUMBER",
  // SAVE PHONE NUMBER

  // CREATE_BOOKING_REQUEST
  CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST",
  CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS",
  CREATE_BOOKING_FAIL = "CREATE_BOOKING_FAIL",
  // END CREATE_BOOKING

  // GET_AI_RECOMMENDATION
  GET_AI_RECOMMENDATION_REQUEST = "GET_AI_RECOMMENDATION_REQUEST",
  GET_AI_RECOMMENDATION_SUCCESS = "GET_AI_RECOMMENDATION_SUCCESS",
  GET_AI_RECOMMENDATION_FAIL = "GET_AI_RECOMMENDATION_FAIL",
  // GET_AI_RECOMMENDATION

  // GET_BOOKING
  GET_BOOKING_REQ = "GET_BOOKING_REQ",
  GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS",
  GET_BOOKING_FAIL = "GET_BOOKING_FAIL",
  // GET_BOOKING

  // REGISTER_PAYER
  REGISTER_PAYER_REQUEST = "REGISTER_PAYER_REQUEST",
  REGISTER_PAYER_SUCCESS = "REGISTER_PAYER_SUCCESS",
  REGISTER_PAYER_FAIL = "REGISTER_PAYER_FAIL",
  // REGISTER_PAYER

  // CONFIRM_BOOKING
  CONFIRM_BOOKING_REQUEST = "CONFIRM_BOOKING_REQUEST",
  CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS",
  CONFIRM_BOOKING_FAIL = "CONFIRM_BOOKING_FAIL",
  // CONFIRM_BOOKING

  GET_INTERESTS_SUCCESS = "GET_INTERESTS_SUCCESS",

  // GET_FIRST_NAME
  GET_FIRST_NAME = "GET_FIRST_NAME",
  // END GET_FIRST_NAME

  // GET_LAST_NAME
  GET_LAST_NAME = "GET_LAST_NAME",
  // END GET_LAST_NAME

  // GET_EMAIL
  GET_EMAIL = "GET_EMAIL",
  // END GET_EMAIL

  // GET_FULL_ADDRESS
  GET_FULL_ADDRESS = "GET_FULL_ADDRESS",
  // END GET_FULL_ADDRESS

  // GET_ENGLISH_LANG
  GET_ENGLISH_LANG = "GET_ENGLISH_LANG",
  // END GET_ENGLISH_LANG

  // EXP_START_TIME
  EXP_START_TIME = "EXP_START_TIME",
  // END EXP_START_TIME
}

// GET TOKEN
interface getLoginTokenRequest {
  type: ActionType.GET_LOGIN_TOKEN_REQUEST;
  payload?: string;
}

interface getLoginTokenSuccess {
  type: ActionType.GET_LOGIN_TOKEN_SUCCESS;
  payload?: {
    token: string;
    expires: number;
  };
}

interface getLoginTokenFail {
  type: ActionType.GET_LOGIN_TOKEN_FAIL;
  payload: string;
}

interface getRoleLoginTokenRequest {
  type: ActionType.GET_ROLE_LOGIN_TOKEN_REQUEST;
  payload?: string;
}

interface getRoleLoginTokenSuccess {
  type: ActionType.GET_ROLE_LOGIN_TOKEN_SUCCESS;
  payload?: {
    token: string;
    expires: number;
  };
}

interface getRoleLoginTokenFail {
  type: ActionType.GET_ROLE_LOGIN_TOKEN_FAIL;
  payload: string;
}
// END OF GET TOKEN

// GLOBAL FACTS
interface globalFactsRequest {
  type: ActionType.GLOBAL_FACTS_REQUEST;
  payload?: any;
}

interface globalFactsSuccess {
  type: ActionType.GLOBAL_FACTS_SUCCESS;
  payload: any;
}

interface globalFactsFail {
  type: ActionType.GLOBAL_FACTS_FAIL;
  payload: string;
}
// END GLOBAL FACTS

// GET PACKAGE
interface getPackagesRequest {
  type: ActionType.GET_PACKAGES_REQUEST;
  payload?: boolean;
}

interface getPackagesSuccess {
  type: ActionType.GET_PACKAGES_SUCCESS;
  payload?: any;
}

interface getPackagesLandingState {
  type: ActionType.GET_PACKAGES_LANDING_STATE;
  payload?: any;
}

interface getInterestsSuccess {
  type: ActionType.GET_INTERESTS_SUCCESS;
  payload:
    | {
        factname: string;
        media: string;
        title: {
          en: string;
          de: string;
        };
      }[]
    | undefined;
}

interface getPackagesFail {
  type: ActionType.GET_PACKAGES_FAIL;
  payload: boolean;
}
// END GET PACKAGE

// GET PROMOTIONS
interface getPromotionsRequest {
  type: ActionType.GET_PROMOTIONS_REQUEST;
}

interface getPromotionsSuccess {
  type: ActionType.GET_PROMOTIONS_SUCCESS;
  payload: {
    promotion: {
      promotion_id: string;
      facts: any;
      guis: [
        {
          widget: string;
          class: string;
          title: {
            de: string;
            en: string;
          };
          text: {
            de: string;
            en: string;
          };
          media: string[];
        }
      ];
    };
  };
}

interface getPromotionsFail {
  type: ActionType.GET_PROMOTIONS_FAIL;
  payload: boolean;
}
// END GET PROMOTIONS

// UPDATE PACKAGES
interface updatepackagesRequest {
  type: ActionType.UPDATE_PACKAGES_REQUEST;
}

interface updatepackagesSuccess {
  type: ActionType.UPDATE_PACKAGES_SUCCESS;
  payload:
    | {
        packages: {
          package_id: string;
          product_groups:
            | {
                products: {
                  product_id: string;
                  facts: any;
                }[];
              }[]
            | undefined;
        }[];
      }
    | [];
}

interface updatepackagesFail {
  type: ActionType.UPDATE_PACKAGES_FAIL;
  payload: boolean;
}
// END UPDATE PACKAGES

interface createBookingRequest {
  type: ActionType.CREATE_BOOKING_REQUEST;
}

interface createBookingSuccess {
  type: ActionType.CREATE_BOOKING_SUCCESS;
  payload: {
    booking_id: string;
    booking_code: string;
  };
}

interface createBookingFail {
  type: ActionType.CREATE_BOOKING_FAIL;
  payload: boolean;
}

// GET AI RECOMMENDATION
interface getAIRecommendationRequest {
  type: ActionType.GET_AI_RECOMMENDATION_REQUEST;
}

interface getAIRecommendationSuccess {
  type: ActionType.GET_AI_RECOMMENDATION_SUCCESS;
  payload: {
    recommendation: {
      en: string;
      de: string;
    };
  };
}

interface getAIRecommendationFail {
  type: ActionType.GET_AI_RECOMMENDATION_FAIL;
  payload: boolean;
}
// GET AI RECOMMENDATION

// GET BOOKING
interface getBookingRequest {
  type: ActionType.GET_BOOKING_REQ;
}

interface getBookingSuccess {
  type: ActionType.GET_BOOKING_SUCCESS;
  payload: {
    recommendation: {
      en: string;
      de: string;
    };
  };
}

interface getBookingFail {
  type: ActionType.GET_BOOKING_FAIL;
  payload: boolean;
}
// GET BOOKING

interface registerPayerRequest {
  type: ActionType.REGISTER_PAYER_REQUEST;
}

interface registerPayerSuccess {
  type: ActionType.REGISTER_PAYER_SUCCESS;
  payload: {
    payer_id: string;
  };
}
interface registerPayerFail {
  type: ActionType.REGISTER_PAYER_FAIL;
  payload: boolean;
}

// confirm booking
interface confirmBookingRequest {
  type: ActionType.CONFIRM_BOOKING_REQUEST;
}
interface confirmBookingSuccess {
  type: ActionType.CONFIRM_BOOKING_SUCCESS;
  payload: boolean;
}
interface confirmBookingFail {
  type: ActionType.CONFIRM_BOOKING_FAIL;
  payload: boolean;
}
// confirm booking

// GET PHONE NUMBER
interface getPhoneNumber {
  type: ActionType.GET_PHONE_NUMBER;
  payload: string;
}
// END OF GET PHONE NUMBER

// GET FIRST NAME
interface getFirstName {
  type: ActionType.GET_FIRST_NAME;
  payload: string | undefined;
}
// END GET FIRST NAME

// GET LAST NAME
interface getLastName {
  type: ActionType.GET_LAST_NAME;
  payload: string | undefined;
}
// END GET LAST NAME

// GET_STREET
interface getFullAddress {
  type: ActionType.GET_FULL_ADDRESS;
  payload: {
    postalCode: string;
    streetAndNo: string | number;
    country: string;
    city: string;
  };
}
// END GET_STREET

// GET ENAIL
interface getEmail {
  type: ActionType.GET_EMAIL;
  payload: string | undefined;
}
// END GET EMAIL

// EXP_START_TIME
interface getExpStartTime {
  type: ActionType.EXP_START_TIME;
  payload: string;
}
// END GET_ENGLISH_LANG

export type ActionProps =
  // LANDING PAGE *****
  | getLoginTokenRequest
  | getLoginTokenSuccess
  | getLoginTokenFail
  | getRoleLoginTokenRequest
  | getRoleLoginTokenSuccess
  | getRoleLoginTokenFail
  | globalFactsRequest
  | globalFactsSuccess
  | globalFactsFail
  | getPackagesRequest
  | getPackagesSuccess
  | getInterestsSuccess
  | getPackagesFail
  | registerPayerRequest
  | registerPayerSuccess
  | registerPayerFail
  | confirmBookingRequest
  | confirmBookingSuccess
  | confirmBookingFail
  | getAIRecommendationRequest
  | getAIRecommendationSuccess
  | getAIRecommendationFail
  | getBookingRequest
  | getBookingSuccess
  | getBookingFail
  // SUMMARY PAGE *****
  | getFirstName
  | getLastName
  | getFullAddress
  | getEmail
  | getPhoneNumber
  | createBookingRequest
  | createBookingSuccess
  | createBookingFail
  // END SUMMARY PAGE *****
  // | sizeOfShareNowCar
  | updatepackagesRequest
  | updatepackagesSuccess
  | updatepackagesFail
  | getExpStartTime
  | getPromotionsRequest
  | getPromotionsSuccess
  | getPromotionsFail
  | getPackagesLandingState;
// END LANDING PAGE *****
