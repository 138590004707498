import styled from "styled-components";

type Props = {
  primarySNColor?: boolean;
  title?: string;
};

const ButtonLoading: React.FC<Props> = ({ primarySNColor, title }) => {
  return (
    <Wrapper className="container">
      {title && <h5>{title}</h5>}

      <div
        className={primarySNColor ? "loading borderBlue" : "loading borderGray"}
      ></div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  h5 {
    color: var(--clr-white);
  }

  .borderGray {
    border: 3px solid var(--clr-secondary-gry);
  }

  .borderBlue {
    border: 3px solid var(--clr-primary-1);
  }

  .loading {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--radius-50);
    border-top-color: white;
    display: inline-block;
    animation: spinner 0.6s linear infinite;
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }
`;

export default ButtonLoading;
